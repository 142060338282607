import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useHistory } from 'react-router'

import { useContextApi } from '../../context/Provider'
import { getInsurerImage } from '../../utilities/getInsurerImage'
import {
	PageContentAndNavbarWrapper,
	SecondaryNavbar,
	theme,
	Text,
	Space,
	Divider,
	Button,
} from 'verak-ui'
import {
	MainWrapper,
	navContent,
	OnDesktop,
	OnMobile,
	mobileLeftNav1,
	StyledText,
} from '../../components/SharedComponents'
import { primaryHeader } from '../../components/SharedStyles'
import { getFormattedAmount } from '../../utilities/getFormattedAmount'
import calculateTotalPremium from '../../utilities/calculateTotalPremium'
import { useEffect, useState } from 'react'
import { useFetch } from '../../api/apihooks'

const PaymentPending = () => {
	const [state, dispatch] = useContextApi()
	const currentRfqItem = state?.currentRfqItem
	const quote = currentRfqItem?.selectedQuote || {}

	const totalPremium = quote?.Amount

	const history = useHistory()

	const changeSelectedQuote = () => history.push('/purchase-policy/compare-quotes')

	const continueToPayment = () => {
		if (!!currentRfqItem?.selfDeclaration) {
			history.push('/purchase-policy/payment-methods')
		} else {
			history.push('/purchase-policy/customer-info')
		}
	}

	return (
		<PageContentAndNavbarWrapper className="d-flex flex-column align-items-center">
			<OnDesktop>
				<SecondaryNavbar
					leftContent={navContent(state?.currentRfqItem, 'Payment Pending')}
					navbarNumber={1}
					rightContent=""
					showGoBack={false}
					headerBg={theme.colors.secondary2}
				/>
			</OnDesktop>
			<OnMobile>
				<SecondaryNavbar
					showGoBack={false}
					headerBg={theme.colors.white}
					navbarNumber={1}
					leftContent={mobileLeftNav1('Payment Pending')}
				/>
			</OnMobile>
			<MainWrapper>
				<Space mobileY={4} />
				<StyledText style={{ ...primaryHeader, color: `${theme.colors.red}` }}>
					Payment Pending!
				</StyledText>
				<Space y={1} mobileY={2} />
				<StyledContainer>
					<Text style={textStyle}>Chosen policy</Text>
					<Divider top={0.625} bottom={0.625} />
					<StyledImg src={getInsurerImage(quote?.Insurer)} alt={quote?.Insurer} />
					<Divider top={0.625} bottom={0.625} />
					<Text style={textStyle}>Premium</Text>
					<StyledText style={premiumTextStyle}>
						₹ {totalPremium ? getFormattedAmount(totalPremium) : ''}
					</StyledText>
				</StyledContainer>

				<OnMobile>
					<Space y={1.5} />
					<Button
						onClick={changeSelectedQuote}
						label="Change the selected quote"
						style={{ ...buttonStyle, margin: '0 auto' }}
						secondary
					/>
				</OnMobile>

				<Space y={4.625} />
				<Divider />
				<Space y={1} />

				<div className="full-flex-container">
					<div className="flex-container">
						<Link to="/" style={{ textDecoration: 'none' }}>
							<Button
								label="Home"
								style={{ width: '120px', height: '48px' }}
								icon="leftArrow"
								secondary
							/>
						</Link>
						<Space x={0.625} />
						<OnDesktop>
							<Button
								onClick={changeSelectedQuote}
								label="Change the selected quote"
								style={buttonStyle}
								secondary
							/>
						</OnDesktop>
					</div>
					<Button
						onClick={continueToPayment}
						label="Continue to payment"
						style={{ ...buttonStyle, fontSize: '13px' }}
						icon="rightArrow"
						iconAlign="right"
					/>
				</div>
			</MainWrapper>
		</PageContentAndNavbarWrapper>
	)
}

export default PaymentPending

const textStyle = {
	fontSize: '18px',
	fontWeight: 700,
	color: `${theme.colors.secondary}`,
}

const premiumTextStyle = {
	fontSize: '36px',
	fontWeight: 700,
	color: `${theme.colors.primary}`,
	paddingTop: '20px',
}

const buttonStyle = {
	width: '240px',
	height: '48px',
}

const StyledContainer = styled.div`
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
	border-radius: 8px;
	padding: 1rem 0.625rem;
	width: min(312px, 100%);
	margin: 0 auto;
`
const StyledImg = styled.img`
	@media (max-width: 768px) {
		width: 150px;
		height: 75px;
	}
`
