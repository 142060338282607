import { Spinner } from 'react-bootstrap'
import styled, { keyframes } from 'styled-components'
import { Text, Icon, theme } from 'verak-ui'
import { useEffect, useState, createContext, useContext } from 'react'

export const LoadingErrorContext = createContext()

const initialLoadingState = {
	status: false,
	loadingText: '',
}

const initialErrorState = {
	status: false,
	errorMessage: '',
	errorStatusCode: '',
}

export function LoadingErrorProvider({ children, ...props }) {
	const [loadingState, setLoadingState] = useState(initialLoadingState)

	const [errorState, setErrorState] = useState(initialErrorState)

	const resetError = () => setErrorState(initialErrorState)

	const value = {
		loadingState,
		setLoadingState,
		errorState,
		setErrorState,
		resetError,
	}

	return (
		<LoadingErrorContext.Provider {...props} value={value}>
			{children}
		</LoadingErrorContext.Provider>
	)
}

export const useLoadingErrorContext = () => {
	const context = useContext(LoadingErrorContext)
	if (!context) {
		throw new Error('useLoadingError must be used within LoadingErrorProvider')
	}
	return context
}

const LoadingErrorContainer = () => {
	const { loadingState, setLoadingState, errorState, resetError } = useLoadingErrorContext()

	useEffect(() => {
		if (loadingState.status) {
			document.body.style.overflow = 'hidden'
		} else {
			document.body.style.overflow = 'auto'
		}

		return () => (document.body.style.overflow = 'auto')
	}, [loadingState.status])

	let loaderContent = null

	if (loadingState.status) {
		loaderContent = (
			<LoaderContainer>
				<Spinner className="mt-2" animation="border" variant="secondary" />

				<Text
					className="mt-3 text-center"
					color={theme.colors.gray2}
					fontSize="16px"
					style={{ maxWidth: '300px' }}
				>
					{loadingState?.loadingText ?? 'Loading'}
				</Text>
			</LoaderContainer>
		)
	} else if (errorState?.status) {
		loaderContent = (
			<LoaderContainer>
				<Icon name="warningTriangleSm" color={theme.colors.red} size="50px" />

				<Text className="mt-2" color={theme.colors.red} fontWeight="700" fontSize="18px">
					{errorState?.errorMessage ?? 'An error has occurred'}
				</Text>

				{errorState?.errorStatusCode && (
					<Text className="mt-1 mb-2" color={theme.colors.gray2} fontSize="16px">
						ERROR CODE: {errorState.errorStatusCode || 500}
					</Text>
				)}

				<Text className="my-1 text-center" color={theme.colors.gray3} style={{ maxWidth: '300px' }}>
					If this issue is recurring, please get in touch with support and mention the error code.
				</Text>

				<OkButton
					className="mt-2"
					color={theme.colors.primary}
					fontSize="14px"
					onClick={() => resetError()}
				>
					OK
				</OkButton>
			</LoaderContainer>
		)
	}

	if (loaderContent) {
		return <OverlayContainer>{loaderContent}</OverlayContainer>
	} else {
		return null
	}
}

// animations
const fadeIn = keyframes`
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
`

const OverlayContainer = styled.div`
	background: rgba(0, 0, 0, 0.4);
	position: fixed;
	z-index: 1100;
	width: 100%;
	height: 100%;
	top: 0px;
	/* bottom: 0; */
	left: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	overflow: none;
	animation: ${fadeIn} 0.3s ease-in-out;
`

const LoaderContainer = styled.div`
	margin: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: #fff;
	padding: 1rem;
	${'' /* width: min(200px, 100%); */}
	border-radius: 8px;
`

const OkButton = styled(Text)`
	padding: 3px 5px;
	border-radius: 4px;
	cursor: pointer;
	&:hover {
		background: #cfcfcf;
	}
`

export default LoadingErrorContainer
