let statusesContainingQuotes = [
	'AGENT:QUOTES:PENDING',
	'OPS:RFQ_RESUBMISSION:PENDING',
	'AGENT:ALL_QUOTES:PENDING',
]
// although the set of if else if structure might look completely random its a hierarchy which should be followed
// since it moves from a high detailed tag like "Payment pending" to a low detailed 1 like "Quotes available now"
export const generateStatus = rfq => {
	let Status = rfq?.Status
	let ClientQuotesAvailable = rfq?.MetaStatus?.ClientQuotesAvailable

	if (ClientQuotesAvailable === false) {
		// TO confirm with Kunal: is this check sufficient for handling payment confirmation pending and further flows too
		return 'Awaiting quotes'
	} else if (
		statusesContainingQuotes.includes(Status) &&
		ClientQuotesAvailable &&
		rfq?.MetaStatus?.ClientSelectedQuote
	) {
		return 'Payment pending'
	} else if (
		statusesContainingQuotes.includes(Status) &&
		ClientQuotesAvailable &&
		rfq?.MetaStatus?.ClientNewQuotes
	) {
		return 'New quote(s) added'
	} else if (statusesContainingQuotes.includes(Status) && ClientQuotesAvailable) {
		return 'Quotes available now'
	} else if (Status === 'OPS:CLOSURE:PENDING') {
		return 'Purchase Successful - Policy Issued!'
	} else if (Status === 'OPS:POLICY:PENDING') {
		return 'Policy issuance pending'
	} else if (Status === 'AGENT:SETTLEMENT:FAILED') {
		return 'Purchase unsuccessful!!'
	} else if (Status === 'OPS:SETTLEMENT:PENDING') {
		return 'Purchase confirmation pending'
	}
}
