import styled from 'styled-components'
import { Icon, Space, Text, theme, Tooltip } from 'verak-ui'
import AppbarMenu from './AppbarMenu'
import { useHistory } from 'react-router'
import VerakLogo from '../assets/images/verak_logo.svg'

const AppbarContainer = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
	padding: 0px;
	background: ${theme.colors.white};
	width: 100%;
	box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
	${'' /* min-height: 64px; */}
`

const AppbarContent = styled.div`
	margin: 0 auto;
	padding: 10px 24px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	/* for react-tippy */
	.tippy-tooltip {
		padding: 0rem !important;
	}
`

const LogoWrapper = styled.div`
	display: flex;
	align-items: center;
`

const ProfileWrapper = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
	@media (max-width: 768px) {
		div[id='profile'] {
			display: none;
		}
	}
`
const ProfileName = styled(Text)`
	font-size: 14px;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: -0.02em;
	max-width: 10rem;
	overflow-x: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	@media (max-width: 768px) {
		display: none;
	}
`

const Divider = styled.div`
	width: 0px;
	height: 42px;
	margin: 0rem 0.625rem;
	border: 0.25px solid #ececec;
`

const LogoHeading = styled.div`
	color: ${theme.colors.primary};
	margin-left: 10px;
	font-size: 18px;
	font-weight: 700;
	cursor: pointer;
`

const profileImageStyle = {
	borderRadius: '50%',
}

const LogoSection = ({ handleClick }) => {
	return (
		<LogoWrapper onClick={handleClick}>
			<div className="d-flex align-items-start flex-column">
				<img height="30px" width="116px" src={VerakLogo} alt="Verak" />
				<Text color="#999999" fontSize="10px" mobileFontSize="10px">
					InsureFirst Insurance Brokers LLP
				</Text>
			</div>
		</LogoWrapper>
	)
}

const ProfileSection = ({ triggerLogout, user }) => {
	return (
		<ProfileWrapper>
			<Tooltip
				contentStyle={{ padding: '0' }}
				text=""
				html={<AppbarMenu triggerLogout={triggerLogout} />}
				position="bottom"
				theme="light"
				trigger="click"
			>
				<div className="d-flex align-items-center">
					<img src={user?.picture} style={profileImageStyle} alt="profile" width="32" height="32" />
					<Space x={0.5} />
					<ProfileName type="body2" color={theme.colors.gray2}>
						{user?.name}
					</ProfileName>
					<Space x={0.5} />
					<Icon name="downArrow" />
				</div>
			</Tooltip>
		</ProfileWrapper>
	)
}

const Appbar = ({ loggedIn, rightContent, triggerLogout, onHomeRedirect, user, ...props }) => {
	const history = useHistory()
	const profileSection = loggedIn ? (
		<ProfileSection triggerLogout={triggerLogout} user={user} />
	) : null

	return (
		<AppbarContainer>
			<AppbarContent>
				<LogoSection handleClick={onHomeRedirect} />
				<div className="d-flex justify-content-center">
					{rightContent}
					{profileSection}
				</div>
			</AppbarContent>
		</AppbarContainer>
	)
}

export default Appbar
