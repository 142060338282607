import { Text, Modal, theme, Button, Icon, Space, PhoneNoInput } from 'verak-ui'
import { Spinner } from 'react-bootstrap'
import { useState, createContext, useContext, useEffect } from 'react'
import { useFetch } from '../api/apihooks'
import { useContextApi } from '../context/Provider'

export const HelpModalContext = createContext()

const defaultHeader = (
	<div className="d-flex align-items-center">
		<Icon name="helpAltIcon" />
		<Space x={0.2} />
		<Text className="text-center" color={theme.colors.primary} fontSize="18px" fontWeight="700">
			Get help
		</Text>
	</div>
)

export function HelpModalProvider({ children, ...props }) {
	const [helpModal, setHelpModal] = useState(false)
	const showHelpModal = v => setHelpModal(v)

	const value = {
		helpModal,
		showHelpModal,
	}

	return (
		<HelpModalContext.Provider {...props} value={value}>
			{children}
		</HelpModalContext.Provider>
	)
}

export const useHelpModal = () => {
	const context = useContext(HelpModalContext)
	if (!context) {
		throw new Error('useHelpModal must be used within HelpModalProvider')
	}
	return context
}

const getButtonLabel = (text, icon, iconColor) => {
	return (
		<div className="w-100 d-flex align-items-center">
			<Icon size="24px" color={iconColor ?? theme.colors.secondary} name={icon} />
			<Space x={0.3} />
			<Text className="text-center" color={theme.colors.primary} fontSize="18px" fontWeight="700">
				{text}
			</Text>
		</div>
	)
}

const optionBtnProps = {
	bgColor: '#FCEBE3',
	textColor: theme.colors.primary,
	style: { height: '56px' },
	icon: 'rightArrow',
	iconColor: '#999999',
	iconAlign: 'right',
}

const HelpModalContainer = () => {
	const { helpModal, showHelpModal } = useHelpModal()

	// list of screens
	// option-list (list of options)
	// email-screen (email)
	const [activeScreen, setScreen] = useState('option-list')

	const setActiveScreen = screenName => {
		if (screenName === 'chatbot') {
			// open the chatbot
			document.querySelector('#ymDivBar')?.click()
			showHelpModal(false)
		} else {
			setScreen(screenName)
		}
	}

	const optionList = (
		<div className="d-flex flex-column">
			<Text color={theme.colors.primary} fontSize="18px" fontWeight="700">
				You can get help using any of these options
			</Text>
			<Space y={1} />
			<Button
				{...optionBtnProps}
				label={getButtonLabel('Chat with our agent on chatbot', 'agentFaceIcon')}
				onClick={() => setActiveScreen('chatbot')}
			/>
			<Space y={1} />
			<Button
				{...optionBtnProps}
				label={getButtonLabel('Email Us', 'email')}
				onClick={() => setActiveScreen('email-screen')}
			/>
		</div>
	)

	const emailScreenHeader = (
		<div className="d-flex align-items-center justify-content-between w-100">
			<Icon
				style={{ cursor: 'pointer' }}
				onClick={() => setScreen('option-list')}
				name="leftArrow"
			/>
			<div className="d-flex align-items-center">
				<Icon name="email" color={theme.colors.secondary} size="24px" />
				<Space x={0.3} />
				<Text color={theme.colors.primary} fontSize="18px" fontWeight="700">
					Email Us
				</Text>
			</div>
			<span></span>
		</div>
	)

	const emailScreenContent = (
		<div className="d-flex flex-column">
			<Text color={theme.colors.primary} fontSize="18px" fontWeight="700">
				Email us your queries at{' '}
				<a href="mailto:admin@verak.in" style={{ color: theme.colors.secondary }}>
					admin@verak.in
				</a>
			</Text>
			<Space y={5} />
		</div>
	)

	let activeSection, activeHeader
	if (activeScreen === 'option-list') {
		activeSection = optionList
		activeHeader = defaultHeader
	} else if (activeScreen === 'email-screen') {
		activeHeader = emailScreenHeader
		activeSection = emailScreenContent
	}

	return (
		<Modal
			title={activeHeader}
			closeButton={true}
			footer={<></>}
			show={helpModal}
			handleClose={() => {
				setActiveScreen('option-list')
				showHelpModal(false)
			}}
			id="help-modal"
		>
			{activeSection}
		</Modal>
	)
}

export default HelpModalContainer
