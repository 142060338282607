import bajaj from '../assets/insurers/bajajAllianz.svg'
import bharti from '../assets/insurers/BhartiAXA.svg'
import digit from '../assets/insurers/DigitInsurance.svg'
import icici from '../assets/insurers/ICICI.svg'
import liberty from '../assets/insurers/LibertyInsurance.svg'
import national from '../assets/insurers/NationalInsurance.svg'
import oriental from '../assets/insurers/OrientalInsurance.svg'
import reliance from '../assets/insurers/RelianceGeneralInsurance.svg'
import royalSundaram from '../assets/insurers/RoyalSundaram.svg'
import tata from '../assets/insurers/TataAIG.svg'
import unitedIndia from '../assets/insurers/UnitedIndia.svg'
import newIndia from '../assets/insurers/NewIndiaAssurance.svg'
import KotakGeneral from '../assets/insurers/KotakGeneralInsurance.svg'
import defaultInsurer from '../assets/insurers/default.svg'
import CholaMS from '../assets/insurers/CholaMSGeneralInsurance.svg'
import SBIGeneral from '../assets/insurers/SBIGeneral.svg'

export const getInsurerImage = insurer => {
	switch (insurer) {
		case 'Reliance General Insurance':
			return reliance
		case 'Liberty General Insurance':
			return liberty
		case 'Oriental Insurance':
			return oriental
		case 'Digit Insurance':
			return digit
		case 'ICICI Lombard General Insurance':
			return icici
		case 'Bajaj Allianz General Insurance':
			return bajaj
		case 'Royal Sundaram General Insurance':
			return royalSundaram
		case 'United India Insurance':
			return unitedIndia
		case 'Tata AIG General Insurance':
			return tata
		case 'National Insurance':
			return national
		case 'The New India Assurance Co. Ltd.':
			return newIndia
		case 'Kotak General Insurance':
			return KotakGeneral
		case 'Chola MS General Insurance':
			return CholaMS
		case 'SBI General Insurance':
			return SBIGeneral
		default:
			return defaultInsurer
	}
}
