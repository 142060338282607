import { Link, useHistory } from 'react-router-dom'
import { useEffect, useState } from 'react'

import { useContextApi } from '../../context/Provider'
import { useFetch } from '../../api/apihooks'
import {
	PageContentAndNavbarWrapper,
	theme,
	Text,
	Space,
	Divider,
	Button,
	SecondaryNavbar,
	OnMobile,
	Icon,
} from 'verak-ui'
import {
	MainWrapper,
	mobileLeftNav1,
	navContent,
	OnDesktop,
	StyledPrimaryBtn,
} from '../../components/SharedComponents'

import styled from 'styled-components'
import calculateTotalPremium from '../../utilities/calculateTotalPremium'
import { getFormattedAmount } from '../../utilities/getFormattedAmount'

import PurchaseSuccessfulGif from '../../assets/images/purchaseSuccessful.gif'
import { getPolicyTypeFullForm } from '../../utilities/getPolicyType'
import { getPolicyName } from '../../utilities/getPolicyName'

const PolicyIssued = () => {
	const history = useHistory()
	const [state, dispatch] = useContextApi()
	const currentRfqItem = state?.currentRfqItem

	let fetch = useFetch()
	const [policy, setPolicy] = useState('')
	const [paymentDetails, setPaymentDetails] = useState({
		Status: '',
		Link: '',
		TxId: '',
		Insurer: '',
	})

	const downloadPolicy = async () => {
		const result = await fetch.getPolicy(state?.currentRfqItem?.id)
		if (result) {
			setPolicy(result['Policy'])
		}
	}

	useEffect(() => {
		downloadPolicy()
	}, [])

	useEffect(() => {
		const fetchPaymentDetails = async () => {
			const result = await fetch.getPaymentStatus(currentRfqItem.id)
			if (!result) {
				return
			}
			setPaymentDetails(result)
		}
		fetchPaymentDetails()
	}, [])

	// lift up the chat icon a bit
	useEffect(() => {
		const chatIcon = document?.getElementById('ymDivBar') || null
		const width = window.innerWidth
		if (chatIcon && width < 768) {
			chatIcon.style.bottom = '60px'
		}

		return () => {
			if (chatIcon) {
				chatIcon.style.bottom = '20px'
			}
		}
	}, [])

	let totalPremiumInWords = ''
	const totalPremium = calculateTotalPremium(currentRfqItem?.selectedQuote || {})
	if (totalPremium) {
		totalPremiumInWords = getFormattedAmount(totalPremium)
	}

	const policyType = currentRfqItem?.policyName || '' // BLUS, BSUS
	const policyName = getPolicyName(currentRfqItem?.Tag) || '' // Vyapar suraksha

	const transactionDetails = (
		<div style={{ width: 'min(550px, 100%)' }}>
			<Text fontWeight="700" color={theme.colors.primary} className="mb-2">
				TRANSACTION DETAILS
			</Text>
			<TransactionDetailsContainer>
				<TxnDetailsLabel>PAYMENT STATUS: </TxnDetailsLabel>
				<PaymentStatusTag className="d-flex align-items-center" success={true}>
					<Icon color="white" name="tickCircle" style={{ marginRight: '8px' }} /> Purchase
					Successful!
				</PaymentStatusTag>

				<TxnDetailsLabel>PREMIUM AMOUNT: </TxnDetailsLabel>
				<TxnDetailsData>₹{totalPremiumInWords}</TxnDetailsData>

				{/* <TxnDetailsLabel>DATE OF TRANSACTION: </TxnDetailsLabel>
				<TxnDetailsData>{'DD / MM / YYYY'}</TxnDetailsData> */}

				{paymentDetails?.TxId && (
					<>
						<TxnDetailsLabel>TRANSACTION ID: </TxnDetailsLabel>
						<TxnDetailsData>{paymentDetails?.TxId || ''}</TxnDetailsData>
					</>
				)}

				{policyName && policyType && (
					<>
						<TxnDetailsLabel>TYPE OF POLICY: </TxnDetailsLabel>
						<div className="d-flex flex-column align-items-start">
							<TxnDetailsData>{policyName}</TxnDetailsData>
							<TxnDetailsData style={{ color: theme.colors.secondary }}>
								{`${getPolicyTypeFullForm(policyType)} (${policyType})`}
							</TxnDetailsData>
						</div>
					</>
				)}

				{paymentDetails?.Insurer && (
					<>
						<TxnDetailsLabel>INSURER DETAILS: </TxnDetailsLabel>
						<TxnDetailsData>{paymentDetails?.Insurer || ''}</TxnDetailsData>
					</>
				)}
			</TransactionDetailsContainer>
		</div>
	)

	const mobileRightNav = (
		<Button
			icon="helpAltIcon"
			iconColor={theme.colors.primary}
			iconSize="18px"
			iconStyles={{ marginRight: '.3rem' }}
			bgColor="white"
			style={{ opacity: '.5', padding: '0.5rem' }}
			textColor={theme.colors.primary}
			label="Help"
		/>
	)

	return (
		<PageContentAndNavbarWrapper className="d-flex flex-column align-items-center">
			<OnDesktop>
				<SecondaryNavbar
					leftContent={navContent(state?.currentRfqItem, 'Purchase')}
					navbarNumber={1}
					showGoBack={false}
					headerBg={theme.colors.secondary2}
				/>
			</OnDesktop>
			<OnMobile className="w-100">
				<SecondaryNavbar
					showGoBack={true}
					headerBg={theme.colors.white}
					navbarNumber={1}
					leftContent={mobileLeftNav1('Purchase')}
					rightContent={mobileRightNav}
				/>
			</OnMobile>
			<MainWrapper>
				<div className="d-flex flex-column align-items-center">
					<Text
						color={theme.colors.green}
						fontSize="24px"
						mobileFontSize="24px"
						fontWeight="700"
						className="mb-3"
					>
						Payment Successful
					</Text>
					<Text
						fontSize="20px"
						mobileFontSize="18px"
						fontWeight="700"
						className="mb-3"
						style={{ textAlign: 'center' }}
					>
						Please check your registered email id for policy bond
					</Text>

					<Space y={1} mobileY={1} />

					<div className="d-flex align-items-center justify-content-center w-100">
						<OnDesktop style={{ width: 'auto' }}>
							<GifView
								style={{ width: '320px', height: 'auto' }}
								src={PurchaseSuccessfulGif}
								alt="purchase successful"
							/>
						</OnDesktop>
						{transactionDetails}
					</div>

					<Space y={1} />
					<Text
						fontSize="18px"
						mobileFontSize="16px"
						fontWeight="700"
						color={theme.colors.secondary}
					>
						Alternatively you can download the policy from here
					</Text>
					<Space y={1} />
					<a href={policy} download target="_blank">
						<Button
							label="Download Policy Document"
							style={{
								width: '310px',
								height: '48px',
								margin: '0 auto',
								background: `${theme.colors.secondary4}`,
								fontWeight: 700,
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
								fontSize: '16px',
							}}
							textColor={theme.colors.primary}
							icon="downloadIcon"
							iconAlign="right"
							iconStyles={{ marginLeft: '0.5rem' }}
						/>
					</a>
					{/* <Space y={4.625} /> */}
					<Divider />
					<Space y={1} />
					<div className="d-flex justify-content-between w-100">
						<OnDesktop>
							<Space x={1} />
						</OnDesktop>
						<OnMobile>
							<Button
								secondary
								style={{ width: '80px' }}
								label="Back"
								icon="leftArrow"
								iconAlign="left"
								onClick={() => history.goBack()}
							/>
						</OnMobile>

						<OnMobile style={{ width: 'auto' }}>
							<Link to="/" style={{ textDecoration: 'none' }}>
								<StyledPrimaryBtn
									style={{ width: '120px' }}
									label="Proceed"
									icon="rightArrow"
									iconAlign="right"
								/>
							</Link>
						</OnMobile>
						<OnDesktop style={{ width: 'auto' }}>
							<Link to="/" style={{ textDecoration: 'none' }}>
								<StyledPrimaryBtn label="Continue to home" icon="rightArrow" iconAlign="right" />
							</Link>
						</OnDesktop>
					</div>
				</div>
			</MainWrapper>
		</PageContentAndNavbarWrapper>
	)
}

const TransactionDetailsContainer = styled.div`
	padding: 0.5rem;
	display: grid;
	grid-template-columns: 0.5fr 1fr;
	row-gap: 0.5rem;
	align-items: center;
	place-items: center start;
	border: 1px solid #c8d6db;
	border-radius: 6px;
	@media (max-width: 768px) {
		grid-template-columns: 1fr;
	}
`

const PaymentStatusTag = styled.div`
	padding: 5px 8px;
	color: ${theme.colors.white};
	background-color: ${theme.colors.green4};
	border-radius: 6px;
	font-size: 14px;
	/* width: clamp(100px, 100%, 350px); */
	width: max-content;
`

const TxnDetailsLabel = styled(Text)`
	font-size: 14px;
	font-weight: 700;
	color: ${theme.colors.primary};
	opacity: 0.4;
`

const TxnDetailsData = styled(Text)`
	font-size: 14px;
	font-weight: 700;
	color: ${theme.colors.primary};
`

const GifView = styled.img`
	height: 410px;
	width: 450px;
	@media (max-width: 768px) {
		height: 320px;
		width: 320px;
	}
`

export default PolicyIssued
