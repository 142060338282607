import { useContext, useRef } from 'react'
// import { postReq } from "./API";
import { Context } from '../context/Provider'
import { useLoadingErrorContext } from '../context/LoadingErrorProvider'

import { convertBase64ToBlob, toBase64 } from '../utilities/imageUploadUtilities'

const url = process.env.REACT_APP_SERVER_URL
// const auth0Audience = process.env.REACT_APP_AUTH0_AUDIENCE

export const useFetch = () => {
	const [state] = useContext(Context)
	const stateRef = useRef()
	stateRef.current = state

	const { setLoadingState, setErrorState } = useLoadingErrorContext()

	const handleResponse = (response, showErrorState = true) => {
		if (!response.ok && showErrorState) {
			setErrorState({
				status: true,
				errorMessage: 'Error: ' + (response.error ?? response.statusText),
				errorStatusCode: response.status,
			})
		} else {
		}
		setLoadingState({ status: false, loadingText: '' })
		return response.json()
	}

	const sendRequest = async (
		endPoint,
		type = 'POST',
		body,
		showLoader = true,
		showErrorState = true
	) => {
		const token = state?.user?.token || ''

		// reset the error state first
		setErrorState({
			status: false,
			errorMessage: '',
			errorStatusCode: '',
		})

		if (showLoader) {
			setLoadingState({ status: true, loadingText: 'Loading...' })
		}

		const result = await fetch(`${url}/${endPoint}`, {
			method: type,
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json',
			},
			body: body ? JSON.stringify(body) : null,
		}).then(res => handleResponse(res, showErrorState))

		return result
	}

	const getRfqList = async () => {
		const results = await sendRequest('client/rfqs', 'GET')
		return results
	}

	const getQuotes = async rfqId => {
		if (!rfqId) {
			return null
		}
		const result = await sendRequest(`client/rfqs/${rfqId}/quotes`, 'GET')
		return result
	}

	const triggerSelfDeclarationOTP = async (contactId, rfqId) => {
		if (contactId && rfqId) {
			const result = await sendRequest('client/triggerOtp/RFQ:SELF_DECLARATION', 'POST', {
				contactId,
				rfqId,
			})
			return result
		} else {
			return null
		}
	}

	const confirmSelfDeclarationOTP = async (contactId, otp) => {
		if (contactId && otp) {
			const result = await sendRequest('client/confirmOtp/RFQ:SELF_DECLARATION', 'POST', {
				contactId,
				otp,
			})
			return result
		} else {
			return null
		}
	}

	const getClientName = async () => {
		const result = await sendRequest(`client/contact-info`, 'GET')
		return result
	}

	const getClientDetails = async rfqId => {
		if (!rfqId) {
			return null
		}
		const result = await sendRequest(`client/rfqs/${rfqId}/customer-details`, 'GET')
		return result
	}

	const updateClientDetails = async (rfqId, data) => {
		if (!rfqId) {
			return null
		}
		const result = await sendRequest(`client/rfqs/${rfqId}/customer-details`, 'PUT', data)
		return result
	}

	const getHypothecationDataForRfq = async rfqId => {
		if (!rfqId) {
			return null
		}
		const result = await sendRequest(`client/rfqs/${rfqId}/hypothecation`, 'GET')
		return result
	}

	// get city and state from PIN code
	const getLocationsWithPIN = async PIN => {
		const data = await fetch(`https://api.postalpincode.in/pincode/${PIN}`, {
			method: 'GET',
		})
			.then(response => {
				if (!response.ok) {
					throw new Error("Can't fetch locations")
				} else {
					return response.json()
				}
			})
			.catch(error => {
				console.log(error)
			})

		return data
	}

	const attachPaymentsProof = async (rfqId, payload) => {
		if (!rfqId) {
			return null
		}
		const result = await sendRequest(`client/rfqs/${rfqId}/payment-proof`, 'POST', payload)
		return result
	}

	const getLoginOTP = async phone => {
		if (!phone) {
			return null
		}
		const result = await sendRequest('auth/contact/otp', 'POST', { phone })
		return result
	}

	const getAuthToken = async (phone, otp) => {
		const result = await sendRequest('auth/contact/token', 'POST', { phone, otp })
		return result
	}

	const getStoredAuthToken = () => {
		const token = sessionStorage.getItem('token')
		return token
	}

	//  get signed url to upload image,pdf
	const getSignedUrl = async (mimeType = 'image/jpeg') => {
		const result = await sendRequest(`client/uploadUrl?contentType=${mimeType}`, 'GET')
		return result
	}

	//  upload blob to the signed url
	const uploadBlobToSignedUrl = async (link, file) => {
		const base64String = await toBase64(file)
		const blobContent = await convertBase64ToBlob(base64String)

		// fetching()
		const result = await fetch(link, {
			method: 'PUT',
			body: blobContent,
		})

		return result
	}

	const getBankTransferDetails = async insurer => {
		const result = await sendRequest(`client/static/PAYMENT_DETAILS?insurer=${insurer}`, 'GET')
		return result
	}

	const getHardwareProducts = async () => {
		const result = await sendRequest(`client/static/HARDWARE_PRODUCTS`, 'GET')
		return result
	}

	const getPolicy = async rfqId => {
		const result = await sendRequest(`client/rfqs/${rfqId}/policy`, 'GET')
		return result
	}

	const requestCallback = async () => {
		const result = await sendRequest(`client/requestCallback`, 'POST', {}, false)
		return result
	}

	const publicRequestCallback = Phone => {
		setLoadingState({ status: true, loadingText: 'Loading...' })
		const result = fetch(`${url}/public/requestCallback`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ Phone }),
		}).then(handleResponse)
		return result
	}

	const generatePaymentLink = async rfqId => {
		if (!rfqId) return null
		const result = await sendRequest(`client/rfqs/${rfqId}/paymentLink`, 'POST', {
			Source: 'WEBSITE',
		})
		return result
	}

	const getPaymentStatus = async (rfqId, showLoader = false) => {
		if (!rfqId) return null
		const result = await sendRequest(
			`client/rfqs/${rfqId}/payment-details`,
			'GET',
			null,
			showLoader
		)
		return result
	}

	const getPaymentMandate = async (rfqId, Insurer) => {
		if (!rfqId) return null
		const result = await sendRequest(
			`client/static/PAYMENT_MANDATE?insurer=${Insurer}`,
			'GET',
			null,
			false, // showLoader
			false // showError
		)
		return result
	}

	const getQRCode = async rfqId => {
		if (!rfqId) return null
		const result = await sendRequest(`client/rfqs/${rfqId}/qrcode`, 'GET')
		return result
	}

	const purchaseJourneyPing = async (rfqId, step, insurer, mode) => {
		if (!rfqId) return null
		let toSend = {
			Step: step,
		}
		if (insurer) {
			toSend.InsurerName = insurer
		}

		if (mode) {
			toSend.PaymentMode = mode
		}
		const result = await sendRequest(`client/rfqs/${rfqId}/purchase-journey-ping`, 'POST', toSend)
		return result
	}

	return {
		sendRequest,
		getRfqList,
		getQuotes,
		getLoginOTP,
		getAuthToken,
		getStoredAuthToken,
		triggerSelfDeclarationOTP,
		confirmSelfDeclarationOTP,
		getClientDetails,
		getClientName,
		updateClientDetails,
		getHypothecationDataForRfq,
		getLocationsWithPIN,
		attachPaymentsProof,
		getSignedUrl,
		uploadBlobToSignedUrl,
		getBankTransferDetails,
		getPolicy,
		requestCallback,
		publicRequestCallback,
		generatePaymentLink,
		getPaymentStatus,
		getPaymentMandate,
		getQRCode,
		purchaseJourneyPing,
		getHardwareProducts,
	}
}
