export const generateTag = Tag => {
	switch (Tag) {
		case 'FIRE':
			return 'Vyapar Suraksha'
		case 'WC':
			return "Workmen's Compensation"
		case 'MC':
			return 'Marine Cargo - Single Transit'
		case 'LIABILITY':
			return 'Liablity'
		case 'FIRE_ALL_RISK':
			return 'Vyapar Suraksha'
		case 'SAFETY_PLAN':
			return 'Safety Plan'
		default:
			return Tag
	}
}
