import React from 'react'
import { Route, useHistory } from 'react-router-dom'
import { useContextApi } from '../context/Provider'

const ProtectedRoute = ({ component, ...args }) => {
	const history = useHistory()
	const [state] = useContextApi()

	const isAuthenticated = state?.user?.authenticated || false

	if (!isAuthenticated) {
		history.replace('/login')
		return null
	}

	if (state.rfqList.length == 0) {
		// client did browser reload
		// is already authenticated
		if (history.location.pathname !== '/') {
			history.replace('/')
			return null
		}
	}

	return <Route component={component} {...args} />
}

export default ProtectedRoute
