import { useHistory } from 'react-router'
import {
	PageContentAndNavbarWrapper,
	SecondaryNavbar,
	theme,
	Text,
	Space,
	Divider,
	Button,
} from 'verak-ui'
import { useContextApi } from '../../context/Provider'
import {
	MainWrapper,
	navContent,
	OnDesktop,
	OnMobile,
	mobileLeftNav1,
	StyledImg,
	StyledText,
} from '../../components/SharedComponents'
import { primaryHeader } from '../../components/SharedStyles'
import folder from '../../assets/folder.svg'

const PurchaseConfirmationPending = () => {
	const [state, dispatch] = useContextApi()

	const insurerName = state?.currentRfqItem?.selectedQuote?.Insurer

	// const desktopRightContent = (
	// 	<Button label="View Transaction History" primary style={{ height: '48px', width: '250px' }} />
	// )

	const history = useHistory()

	const goBack = () => history.goBack()
	const goToHome = () => history.push('/')

	return (
		<PageContentAndNavbarWrapper className="d-flex flex-column align-items-center">
			<OnDesktop>
				<SecondaryNavbar
					leftContent={navContent(state?.currentRfqItem, 'Purchase confirmation pending')}
					navbarNumber={1}
					// rightContent={desktopRightContent}
					showGoBack={false}
					headerBg={theme.colors.secondary2}
				/>
			</OnDesktop>
			<OnMobile>
				<Space y={1} />
				<SecondaryNavbar
					showGoBack={false}
					headerBg={theme.colors.white}
					navbarNumber={1}
					leftContent={mobileLeftNav1('Purchase confirmation pending')}
				/>
			</OnMobile>
			<MainWrapper className="d-flex flex-column align-items-center">
				<StyledImg src={folder} alt="payment-succesfull" />
				<Space y={1} />
				<StyledText style={primaryHeader}>Your payment has been made successfully!</StyledText>
				<Space y={1} />
				<Text color={theme.colors.primary} fontSize="20px" fontWeight="700">
					We will now follow up with {insurerName} to{' '}
					<span style={{ color: theme.colors.green }}>
						confirm your payment receipt and notify you within 72hrs
					</span>
				</Text>
				<Space y={1} />
				<Space y={4.625} />
				<Divider />
				<Space y={1} />
				<div className="d-flex w-100 justify-content-between align-items-center">
					<Button
						label="Back"
						icon="leftArrow"
						iconAlign="left"
						iconColor={theme.colors.primary}
						bgColor={theme.colors.secondary2}
						textColor={theme.colors.primary}
						onClick={goBack}
					/>
					<Button onClick={goToHome} label="Continue to home" icon="rightArrow" iconAlign="right" />
				</div>
			</MainWrapper>
		</PageContentAndNavbarWrapper>
	)
}

export default PurchaseConfirmationPending
