import { Link } from 'react-router-dom'

import { useContextApi } from '../../context/Provider'
import {
	PageContentAndNavbarWrapper,
	SecondaryNavbar,
	theme,
	Text,
	Space,
	Divider,
	Button,
} from 'verak-ui'
import {
	MainWrapper,
	navContent,
	OnDesktop,
	OnMobile,
	mobileLeftNav1,
	StyledImg,
	StyledPrimaryBtn,
	StyledText,
} from '../../components/SharedComponents'
import { primaryHeader, secondaryHeader } from '../../components/SharedStyles'

import folder from '../../assets/folder.svg'

const PolicyIssuancePending = () => {
	const [state, dispatch] = useContextApi()
	const quote = state?.currentRfqItem?.selectedQuote

	return (
		<PageContentAndNavbarWrapper className="d-flex flex-column align-items-center">
			<OnDesktop>
				<SecondaryNavbar
					leftContent={navContent(state?.currentRfqItem, 'Policy Issuance')}
					navbarNumber={1}
					showGoBack={false}
					headerBg={theme.colors.secondary2}
				/>
			</OnDesktop>
			<OnMobile>
				<Space y={1} />
				<SecondaryNavbar
					showGoBack={false}
					headerBg={theme.colors.white}
					navbarNumber={1}
					leftContent={mobileLeftNav1('Policy Issuance')}
				/>
			</OnMobile>
			<MainWrapper>
				<StyledImg src={folder} alt="policy-issuance-pending" />
				<Space y={1} />
				<StyledText style={primaryHeader}>
					We are waiting for the policy document to arrive from {quote?.Insurer}!
				</StyledText>
				<Space y={1} />
				<StyledText style={{ ...secondaryHeader, width: 'min(520px, 100%)', margin: '0 auto' }}>
					You will be sent an email along with the policy document as soon as we receive the same
				</StyledText>
				<Space y={4.625} />
				<Divider />
				<Space y={1} />
				<Link to="/" style={{ textDecoration: 'none' }}>
					<StyledPrimaryBtn label="Continue to home" icon="rightArrow" iconAlign="right" />
				</Link>
			</MainWrapper>
		</PageContentAndNavbarWrapper>
	)
}

export default PolicyIssuancePending
