import styled from 'styled-components'

export const OnDesktop = styled.div`
	width: 100%;
	@media (max-width: 768px) {
		display: none;
		width: 0;
	}
`
export const OnMobile = styled.div`
	width: 100%;
	@media (min-width: 768px) {
		display: none;
	}
`
