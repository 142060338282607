import { useContextApi } from '../../context/Provider'
import {
	PageContentAndNavbarWrapper,
	SecondaryNavbar,
	theme,
	Text,
	Space,
	Icon,
	Button,
} from 'verak-ui'
import styled from 'styled-components'
import { MainWrapper, PolicyTypeLabel } from '../../components/SharedComponents'
import { OnDesktop, OnMobile } from '../../components/HideViewports'
import { getPolicyName } from '../../utilities/getPolicyName'
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useFetch } from '../../api/apihooks'
import { ToastContainer, toast } from 'react-toastify'
import { getFormattedAmount } from '../../utilities/getFormattedAmount'
import 'react-toastify/dist/ReactToastify.css'
import calculateTotalPremium from '../../utilities/calculateTotalPremium'
import { getInsurerImage } from '../../utilities/getInsurerImage'
import RazorpayLogo from '../../assets/images/razorpay.png'
import VerifiedIconGreen from '../../assets/images/verifiedIconGreen.svg'

import BankTransfer from './BankTransfer'
import { getPolicyTypeFullForm } from '../../utilities/getPolicyType'

const getButtonLabel = (
	label,
	icon,
	iconSize,
	showRightArrow = true,
	fontSize = '18px',
	fontWeight = '700'
) => {
	return (
		<div className="w-100 d-flex justify-content-between align-items-center">
			<div className="d-flex align-items-center">
				<Icon size={iconSize ?? '24px'} name={icon} color={theme.colors.primary} className="me-2" />
				<Text color={theme.colors.primary} fontSize={fontSize} fontWeight={fontWeight}>
					{label}
				</Text>
			</div>
			{showRightArrow && <Icon name="rightArrow" color={theme.colors.primary} />}
		</div>
	)
}

const PaymentMethods = () => {
	const [state] = useContextApi()
	const history = useHistory()
	const fetch = useFetch()

	// Valid screens
	// 1. Payment Methods : payment-methods
	// 2. Bank transfer: bank-transfer
	// TODO: add the "online-payment" screen states here
	const [activeSection, setActiveSection] = useState('payment-methods')
	const [paymentLink, setPaymentLink] = useState('')
	const [wasPaymentFailed, setPaymentFailed] = useState(false)

	const currentRfqItem = state?.currentRfqItem
	const insurerName = currentRfqItem?.selectedQuote?.Insurer

	const totalPremiumAmount = calculateTotalPremium(currentRfqItem?.selectedQuote || {})
	const formattedPremiumAmount = getFormattedAmount(totalPremiumAmount || 0)

	const insurerImage = getInsurerImage(insurerName)
	const policyName = getPolicyName(currentRfqItem?.Tag)
	const transactionDate = new Date().toLocaleDateString('en-GB', {
		year: 'numeric',
		month: 'numeric',
		day: 'numeric',
	}) // returns date as DD/MM/YYYY

	const [paymentMandate, setPaymentMandate] = useState('')
	// fetch the payment-related data first
	useEffect(() => {
		const fetchPaymentDetails = async () => {
			const result = await fetch.getPaymentStatus(currentRfqItem?.id, true)
			if (result?.Link) {
				setPaymentLink(result.Link)
			}
			if (result?.Status === 'FAILED') {
				setPaymentFailed(true)
			}
		}

		fetchPaymentDetails()
	}, []) // eslint-disable-line

	useEffect(() => {
		const chatIcon = document?.getElementById('ymDivBar') || null
		const width = window.innerWidth
		if (chatIcon && width > 768) {
			chatIcon.style.bottom = '100px'
		}

		return () => {
			if (chatIcon) {
				chatIcon.style.bottom = '20px'
			}
		}
	}, [])

	useEffect(() => {
		const fetchPaymentMandate = async () => {
			const result = await fetch.getPaymentMandate(currentRfqItem?.id, insurerName)
			if (result?.mandatePdf) {
				setPaymentMandate(result.mandatePdf)
			}
		}
		if (!paymentMandate) fetchPaymentMandate()
	}, []) // eslint-disable-line

	const goBack = () => {
		if (activeSection !== 'payment-methods') {
			setActiveSection('payment-methods')
		} else {
			history.goBack()
		}
	}

	const redirectToPaymentGateway = async () => {
		let gatewayLink = `${paymentLink}`

		if (!gatewayLink) {
			// fetch payment gateway link
			const result = await fetch.generatePaymentLink(currentRfqItem?.id)
			// const result = { paymentLink: "https://google.com" }
			gatewayLink = result?.paymentLink || ''
		}

		if (gatewayLink) {
			fetch.purchaseJourneyPing(currentRfqItem?.id, 'PAYMENT_MODE', null, 'Online')
			window.open(gatewayLink, '_blank', 'noopener,noreferrer')

			// redirect the user to the online payment pending state
			history.push({
				pathname: '/purchase-policy/online-payment',
				state: { wasPaymentFailed },
			})
		}
	}

	const generateShareableLink = async () => {
		let gatewayLink = `${paymentLink}`

		if (!gatewayLink) {
			// fetch payment gateway link
			const result = await fetch.generatePaymentLink(currentRfqItem?.id)
			// const result = { paymentLink: "https://google.com" }
			gatewayLink = result?.paymentLink || ''
			setPaymentLink(gatewayLink)
		}

		if (gatewayLink) {
			await fetch.purchaseJourneyPing(currentRfqItem?.id, 'PAYMENT_MODE', null, 'Online')

			await navigator?.clipboard?.writeText(gatewayLink)
			toast.success('Payment link copied to clipboard')
		}
	}

	// button labels
	// online payment label with recommmended tag
	const onlinePaymentLabel = (
		<OnlinePaymentLabel>
			Online payment
			<div className="mx-2 d-flex align-items-center">
				<OnDesktop>
					<img src={VerifiedIconGreen} alt="online payment" />
				</OnDesktop>
				<OnMobile>
					<img style={{ width: '14px' }} src={VerifiedIconGreen} alt="online payment" />
				</OnMobile>

				<Text
					style={{ fontStyle: 'italic' }}
					color={theme.colors.green}
					fontSize="14px"
					mobileFontSize="12px"
					fontWeight="700"
				>
					Recommended
				</Text>
			</div>
		</OnlinePaymentLabel>
	)

	const onlinePayment = getButtonLabel(onlinePaymentLabel, 'globeSm', '24px')
	const generateLink = getButtonLabel('Copy payment link', 'copyIcon', '24px', false, '14px', '400')

	const policyType = currentRfqItem?.policyName || ''

	const desktopLeftNav = (
		<Text type="body1" color={theme.colors.gray7} fontWeight="700">
			<div className="d-flex align-items-center">
				{policyName}({currentRfqItem.policyIndex}) &nbsp;
				<PolicyTypeLabel>{policyType}</PolicyTypeLabel>
				<Icon name="rightArrow" color={theme.colors.primary} /> Purchase
			</div>
		</Text>
	)

	const mobileLeftNav = (
		<div className="d-flex justify-content-center w-100">
			<Text
				style={{ marginLeft: '2rem' }}
				color={theme.colors.primary}
				fontWeight="700"
				fontSize="18px"
			>
				Purchase
			</Text>
		</div>
	)

	const mobileRightNav = (
		<Button
			icon="helpAltIcon"
			iconColor={theme.colors.primary}
			iconSize="18px"
			iconStyles={{ marginRight: '.3rem' }}
			bgColor="white"
			style={{ opacity: '.5' }}
			textColor={theme.colors.primary}
			label="Help"
		/>
	)

	const bankTransfer = getButtonLabel('Bank transfer', 'bankIcon', '24px')
	const goBackToPaymentMethods = () => setActiveSection('payment-methods')

	let activeScreen
	if (activeSection === 'payment-methods') {
		const onBankTransferClick = () => {
			fetch.purchaseJourneyPing(currentRfqItem?.id, 'PAYMENT_MODE', null, 'Offline')
			setActiveSection('bank-transfer')
		}
		activeScreen = (
			<CustomWrapper>
				<OnMobile>
					<InfoCard className="m-auto" style={{ width: 'min(344px, 100%)', padding: '0.4rem' }}>
						<div className="d-flex justify-content-between">
							<img style={{ width: 'min(140px, 100%)' }} src={insurerImage} alt="insurer" />
							<div className="ms-2" style={{ flex: '1' }}>
								<Text
									fontSize="10px"
									mobileFontSize="10px"
									fontWeight="bold"
									color={theme.colors.primary}
									style={{ opacity: 0.4 }}
								>
									PAYING TO:
								</Text>
								<Text
									className="my-1"
									fontSize="12px"
									mobileFontSize="12px"
									fontWeight="700"
									color={theme.colors.primary}
								>
									{insurerName}
								</Text>

								<Text
									fontSize="10px"
									mobileFontSize="10px"
									fontWeight="bold"
									color={theme.colors.primary}
									style={{ opacity: 0.4 }}
								>
									PREMIUM AMOUNT:
								</Text>
								<Text
									className="mt-1"
									fontSize="12px"
									mobileFontSize="12px"
									fontWeight="700"
									color={theme.colors.primary}
								>
									₹ {formattedPremiumAmount}
								</Text>
							</div>
						</div>
						{paymentMandate && (
							<a href={paymentMandate} target="_blank" rel="noopener noreferrer" download>
								<Button
									style={{ height: '40px', fontSize: '12px' }}
									className="mt-3 w-100"
									label="Download Payment Mandate"
								/>
							</a>
						)}
					</InfoCard>
					<InfoCard
						className="mt-2 d-grid flex-column p-2 m-auto"
						style={{
							width: 'min(344px, 100%)',
							gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
						}}
					>
						<div>
							<Text
								fontSize="10px"
								mobileFontSize="10px"
								fontWeight="bold"
								color={theme.colors.primary}
								style={{ opacity: 0.4 }}
							>
								DATE OF TRANSACTION:
							</Text>

							<Text
								className="my-1"
								fontSize="12px"
								mobileFontSize="12px"
								fontWeight="700"
								color={theme.colors.primary}
							>
								{transactionDate}
							</Text>
						</div>
						<div>
							<Text
								fontSize="10px"
								mobileFontSize="10px"
								fontWeight="bold"
								color={theme.colors.primary}
								style={{ opacity: 0.4 }}
							>
								TYPE OF POLICY:
							</Text>
							<Text
								className="my-1"
								fontSize="12px"
								mobileFontSize="12px"
								fontWeight="700"
								color={theme.colors.primary}
							>
								{policyName}
							</Text>
							<Text
								className="my-1"
								fontSize="12px"
								mobileFontSize="12px"
								fontWeight="700"
								color={theme.colors.secondary}
							>
								{`${getPolicyTypeFullForm(policyType)} (${policyType})`}
							</Text>
						</div>
					</InfoCard>
				</OnMobile>
				<OnMobile>
					<MainHeader className="mb-3" color={theme.colors.gray7} fontSize="24px" fontWeight="700">
						Select a payment method:
					</MainHeader>
				</OnMobile>
				<div className="d-flex justify-content-evenly align-items-start">
					<PaymentMethodsContainer className="d-flex align-items-center flex-column">
						<OnDesktop>
							<MainHeader
								className="my-3"
								color={theme.colors.gray7}
								fontSize="24px"
								fontWeight="700"
							>
								Select a payment method:
							</MainHeader>
						</OnDesktop>
						<div style={{ width: 'min(515px, 100%)' }}>
							<CustomButton
								secondary
								label={onlinePayment}
								onClick={redirectToPaymentGateway}
								className="mb-1"
							/>
							<Text
								fontSize="14px"
								fontWeight="700"
								color={theme.colors.secondary}
								style={{ fontStyle: 'italic' }}
							>
								Debit/Credit Card, UPI, Wallet, Netbanking
							</Text>
						</div>

						<div style={{ width: 'min(515px, 100%)' }} className="mt-4">
							<Text fontSize="14px" fontWeight="700" color="#333333">
								Someone else is making the payment?
							</Text>
							<CustomButton
								bgColor="#E9EFFE"
								label={generateLink}
								onClick={generateShareableLink}
								className="py-3 my-1"
								style={{ height: '48px' }}
							/>
							<Text fontSize="14px" fontWeight="400" color="rgb(76, 76, 76, 0.8)">
								Share the copied link with the payee
							</Text>
						</div>

						<Text className="mt-4 d-flex justify-content-center align-items-center">
							Online payments supported by
							<img src={RazorpayLogo} alt="payments supported by razorpay" />
						</Text>

						<div
							style={{ width: 'min(515px, 100%)', border: '1px solid #E6E6E6' }}
							className="my-4"
						></div>

						<div style={{ width: 'min(515px, 100%)' }}>
							<CustomButton
								secondary
								label={bankTransfer}
								onClick={onBankTransferClick}
								className="mb-1"
							/>
							<Text
								fontSize="14px"
								fontWeight="700"
								color={theme.colors.secondary}
								style={{ fontStyle: 'italic' }}
							>
								UPI/QR Code, NEFT, RTGS, IMPS
							</Text>
						</div>
					</PaymentMethodsContainer>
					<OnDesktop className="w-auto">
						<Space y={5} />
						<div className="d-flex flex-column">
							<InfoCard className="d-flex flex-column">
								<img src={insurerImage} alt="insurerß" />
								<Text
									fontSize="14px"
									fontWeight="bold"
									color={theme.colors.primary}
									style={{ opacity: 0.4 }}
									className="my-1"
								>
									PAYING TO:
								</Text>
								<Text
									className="my-1"
									fontSize="14px"
									fontWeight="700"
									color={theme.colors.primary}
								>
									{insurerName}
								</Text>
								<Space y={0.5} />
								{paymentMandate && (
									<a href={paymentMandate} target="_blank" rel="noopener noreferrer" download>
										<Button
											style={{ height: '48px' }}
											className="my-1"
											label="Download Payment Mandate"
										/>
									</a>
								)}
							</InfoCard>

							<InfoCard className="mt-3 d-flex flex-column">
								<Text
									fontSize="14px"
									fontWeight="bold"
									color={theme.colors.primary}
									style={{ opacity: 0.4 }}
									className="my-1"
								>
									PREMIUM AMOUNT:
								</Text>
								<Text
									className="my-1"
									fontSize="14px"
									fontWeight="700"
									color={theme.colors.primary}
								>
									₹ {formattedPremiumAmount}
								</Text>
								<Space y={0.8} />
								<Text
									fontSize="14px"
									fontWeight="bold"
									color={theme.colors.primary}
									style={{ opacity: 0.4 }}
									className="my-1"
								>
									DATE OF TRANSACTION:
								</Text>
								<Text
									className="my-1"
									fontSize="14px"
									fontWeight="700"
									color={theme.colors.primary}
								>
									{transactionDate}
								</Text>
								<Space y={0.8} />
								<Text
									fontSize="14px"
									fontWeight="bold"
									color={theme.colors.primary}
									style={{ opacity: 0.4 }}
									className="my-1"
								>
									TYPE OF POLICY:
								</Text>
								<Text
									className="my-1"
									fontSize="14px"
									fontWeight="700"
									color={theme.colors.primary}
								>
									{policyName}
								</Text>
								<Text
									className="my-1"
									fontSize="14px"
									fontWeight="700"
									color={theme.colors.secondary}
								>
									{`${getPolicyTypeFullForm(policyType)} (${policyType})`}
								</Text>
							</InfoCard>
						</div>
					</OnDesktop>
				</div>

				<OnDesktop>
					<Space y={2} />
				</OnDesktop>
				<OnMobile className="mt-5">
					<Button secondary label="Back" onClick={goBack} />
				</OnMobile>
			</CustomWrapper>
		)
	} else if (activeSection === 'bank-transfer') {
		activeScreen = <BankTransfer goBack={goBackToPaymentMethods} />
	}

	return (
		<PageContentAndNavbarWrapper>
			<OnDesktop>
				<SecondaryNavbar
					leftContent={desktopLeftNav}
					// rightContent={mobileRightNav}
					navbarNumber={1}
					showGoBack={activeSection !== 'payment-methods'}
					onGoBack={goBack}
					headerBg={theme.colors.secondary2}
				/>
			</OnDesktop>
			<OnMobile>
				<SecondaryNavbar
					leftContent={mobileLeftNav}
					rightContent={mobileRightNav}
					navbarNumber={1}
					showGoBack={true}
					onGoBack={goBack}
					headerBg={theme.colors.white}
				/>
			</OnMobile>
			{activeScreen}
			<ToastContainer
				position="bottom-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</PageContentAndNavbarWrapper>
	)
}

export default PaymentMethods

const MainHeader = styled(Text)`
	@media (max-width: 768px) {
		width: min(350px, 100%);
		font-size: 18px !important;
		margin: 1.5rem auto !important;
	}
`

const PaymentMethodsContainer = styled.div`
	min-height: 500px;
	@media (max-width: 768px) {
		min-height: 375px;
	}
`

const CustomWrapper = styled(MainWrapper)`
	width: 90%;
	text-align: inherit !important;
	@media (max-width: 768px) {
		display: flex;
		align-items: center;
		flex-direction: column;
		background: white;
		width: 100%;
		height: 100%;
		margin-top: 0px;
		margin: 0;
	}
`

const CustomButton = styled(Button)`
	width: 515px;
	height: 72px;
	border-radius: 8px;
	background: ${p => p.bgColor || theme.colors.secondary3};
	opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1.5rem;
	@media (max-width: 768px) {
		width: 100%;
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
	}
`

const OnlinePaymentLabel = styled.div`
	display: flex;
	align-items: center;
	@media (max-width: 768px) {
		flex-direction: column;
	}
`

const InfoCard = styled.div`
	border: 2px solid #c8d6db;
	padding: 12px;
	border-radius: 8px;
`
