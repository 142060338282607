import { useEffect } from 'react'
import { useHistory } from 'react-router'

import { useContextApi } from '../../context/Provider'
import { generateStatus } from '../../utilities/generateStatus'
import { useFetch } from '../../api/apihooks'

import AwaitingQuotes from './AwaitingQuotes'
import PaymentPending from './PaymentPending'
import PolicyIssuancePending from './PolicyIssuancePending'
import PolicyIssued from './PolicyIssued'
import PurchaseConfirmationPending from './PurchaseConfirmationPending'
import PurchaseUnsuccessful from './PurchaseUnsuccessful'

const StatusHandler = () => {
	const [state, dispatch] = useContextApi()
	const rfqData = state?.currentRfqItem

	const history = useHistory()

	let pageContent = null
	const Status = generateStatus(rfqData)

	const { getQuotes } = useFetch()
	const setQuotes = async () => {
		// sets the quotes for this RFQ
		const result = await getQuotes(rfqData.id)
		if (result?.quotes) {
			const modifiedQuotes = result.quotes.map(quote => {
				return {
					...quote,
					Cover: result.cover,
					Duration: result.duration,
				}
			})

			await new Promise(resolve => {
				dispatch({
					type: 'SET_RFQ_QUOTES_AND_DATA',
					data: {
						...result,
						quotes: modifiedQuotes,
					},
				})
				resolve()
			})

			// sets the "pre selected" quote if present
			await new Promise(resolve => {
				dispatch({
					type: 'SET_SELECTED_QUOTE',
				})
				resolve()
			})
		}
	}

	useEffect(() => {
		setQuotes()
	}, [])

	switch (Status) {
		case 'Awaiting quotes':
			pageContent = <AwaitingQuotes />
			break
		case 'Payment pending':
			pageContent = <PaymentPending />
			break
		case 'Policy issuance pending':
			pageContent = <PolicyIssuancePending />
			break
		case 'Purchase Successful - Policy Issued!':
			pageContent = <PolicyIssued />
			break
		case 'Quotes available now':
		case 'New quote(s) added':
			history.push('/purchase-policy/compare-quotes')
			break
		case 'Purchase confirmation pending':
			pageContent = <PurchaseConfirmationPending />
			break
		case 'Purchase unsuccessful!!':
			pageContent = <PurchaseUnsuccessful />
			break
		default:
			pageContent = <AwaitingQuotes />
	}

	return pageContent
}

export default StatusHandler
