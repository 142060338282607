import {
	theme,
	Text,
	Space,
	Divider,
	Icon,
	Button,
	HiddenFileInput,
	FileUploaded,
	Input,
} from 'verak-ui'
import { useState, useEffect, useRef } from 'react'
import Swal from 'sweetalert2'
import styled from 'styled-components'
import { useContextApi } from '../../context/Provider'
import { MainWrapper } from '../../components/SharedComponents'
import { OnDesktop, OnMobile } from '../../components/HideViewports'
import { useHistory } from 'react-router-dom'
import { useFetch } from '../../api/apihooks'
import {
	imageDeleteConfirmOptions,
	parseLinkFromSignedUrl,
} from '../../utilities/imageUploadUtilities'
import { getFormattedAmount } from '../../utilities/getFormattedAmount'
import calculateTotalPremium from '../../utilities/calculateTotalPremium'

import UploadedImage from '../../assets/images/uploadedImage.png'
import { Col, Container, Row } from 'react-bootstrap'
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen'
import { ToastContainer, toast } from 'react-toastify'

const BankTransfer = ({ goBack }) => {
	const [state, dispatch] = useContextApi()
	const { isMobile } = useCheckMobileScreen()

	const history = useHistory()
	const fetch = useFetch()

	const currentRfqItem = state?.currentRfqItem
	const currentQuoteId = currentRfqItem?.selectedQuote?.id || ''
	const insurerName = currentRfqItem?.selectedQuote?.Insurer || ''

	const totalPremiumAmount = calculateTotalPremium(currentRfqItem?.selectedQuote || {})
	const formattedPremiumAmount = getFormattedAmount(totalPremiumAmount || 0)

	const [bankDetails, setBankDetails] = useState(null)
	const [bankConfirmationImg, setBankConfirmationImg] = useState('')
	const [bankTransferUTRNo, setBankTransferUTRNo] = useState('')
	const [qRImageLink, setqRImageLink] = useState('')
	const [upiLink, setUpiLink] = useState('')
	const [qRCodeDataURL, setqRCodeDataURL] = useState('')
	const bankTransferImgRef = useRef()

	const getBankDetails = async () => {
		const result = await fetch.getBankTransferDetails(insurerName)
		if (result) {
			setBankDetails(result)
		}
	}

	const fetchQRCode = async () => {
		const result = await fetch.getQRCode(currentRfqItem.id)
		if (result.url) {
			setqRImageLink(result.url)
			setUpiLink(result.uri)
			setqRCodeDataURL(result.dataUrl)
		}
	}

	useEffect(() => {
		getBankDetails()
		fetchQRCode()
	}, []) //eslint-disable-line

	// bank transfer methods
	const triggerBankTransferImgUpload = () => bankTransferImgRef?.current?.click()

	const handleFileUpload = async (files, imageType) => {
		const file = files[0]
		if (file) {
			const uploadUrl = await fetch.getSignedUrl()
			const uploadResult = await fetch.uploadBlobToSignedUrl(uploadUrl, file)
			const s3Link = parseLinkFromSignedUrl(uploadResult?.url)

			if (imageType === 'BANK_TRANSFER') {
				setBankConfirmationImg(s3Link)
			}
		}
	}

	const removeImage = (image, imageType) => {
		Swal.fire(imageDeleteConfirmOptions).then(result => {
			if (result.isConfirmed) {
				if (imageType === 'BANK_TRANSFER') {
					setBankConfirmationImg('')
				}
				Swal.fire('Your file has been deleted')
			}
		})
	}

	const handleBankTransferDataSubmit = async () => {
		const payload = {
			Type: 'BANK_TRANSFER',
			Image: bankConfirmationImg,
			ExternalRef: bankTransferUTRNo,
		}
		await fetch.attachPaymentsProof(currentRfqItem.id, payload)

		// change rfq status from AGENT:QUOTES:PENDING to OPS:POLICY:PENDING
		await new Promise(resolve => {
			dispatch({
				type: 'UPDATE_RFQ_STATUS_AFTER_PAYMENT',
				data: {
					toUpdateRfq: currentRfqItem,
				},
			})
			resolve()
		})

		history.replace('/')
	}

	let bankDetailsElements

	if (bankDetails) {
		const downloadQRCode = async () => {
			const link = document.createElement('a')
			link.href = qRCodeDataURL
			link.download = `${bankDetails['Insurer']}-QR.png`
			link.click()
		}

		const dataURItoBlob = dataURI => {
			// convert base64 to raw binary data held in a string
			// doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
			var byteString = atob(dataURI.split(',')[1])

			// separate out the mime component
			var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

			// write the bytes of the string to an ArrayBuffer
			var ab = new ArrayBuffer(byteString.length)
			var ia = new Uint8Array(ab)
			for (var i = 0; i < byteString.length; i++) {
				ia[i] = byteString.charCodeAt(i)
			}

			return new Blob([ab], { type: mimeString })
		}

		const shareQRCode = async () => {
			if (navigator.share) {
				const dataURL = qRCodeDataURL
				var blob = dataURItoBlob(dataURL)

				const file = new File([blob], 'QR.png', { type: 'image/png' })
				navigator
					.share({
						title: `Please make the payment for Rs. ${formattedPremiumAmount} to ${bankDetails['Insurer']} by scanning the QR code above `,
						text: `Please make the payment for Rs. ${formattedPremiumAmount} to ${bankDetails['Insurer']} by scanning the QR code above `,
						files: [file],
					})
					.then(() => {
						toast.success('Thanks for sharing!')
					})
					.catch(toast.error)
			} else {
				// fallback
				toast.error('Sharing not supported in this device')
			}
		}

		bankDetailsElements = (
			<Container fluid>
				<Row className="py-3 mt-4" style={{ border: '1px solid #C8D6DB', borderRadius: '8px' }}>
					<Col className="d-flex flex-column justify-content-end">
						<table>
							<tbody>
								<TableRow>
									<DetailName>PREMIUM TO BE PAID:</DetailName>
									<DetailValue>₹{formattedPremiumAmount}</DetailValue>
								</TableRow>
								<TableRow>
									<DetailName>TRANSFER/ONLINE WIRE NAME:</DetailName>
									<DetailValue>{bankDetails['Insurer']}</DetailValue>
								</TableRow>
								<TableRow>
									<DetailName>ACCOUNT NUMBER:</DetailName>
									<DetailValue>{bankDetails['AccountNo']}</DetailValue>
								</TableRow>
								<TableRow>
									<DetailName>BANK:</DetailName>
									<DetailValue>{`${bankDetails['BankName']}, ${bankDetails['BranchName']}`}</DetailValue>
								</TableRow>
								<TableRow>
									<DetailName>IFSC CODE:</DetailName>
									<DetailValue>{bankDetails['IfscCode']}</DetailValue>
								</TableRow>
							</tbody>
						</table>
						<OnDesktop>
							<div className="d-flex">
								<Button
									icon={'downloadIcon'}
									iconColor="white"
									iconStyles={{ marginRight: '8px' }}
									className="me-3 mt-3"
									label="Download QR"
									disabled={!qRImageLink}
									onClick={downloadQRCode}
								/>
							</div>
						</OnDesktop>
					</Col>
					<Col>
						<div className={`w-100 d-flex justify-content-center ${isMobile ? 'my-3' : ''}`}>
							<img src={qRImageLink} style={{ width: isMobile ? '350px' : '200px' }} />
						</div>
						<div className="">
							<OnMobile className="me-3">
								<StyledLink href={upiLink} role="button">
									<Icon name="upiIcon" className="me-2" />
									Pay via UPI
								</StyledLink>
								<ShareButton
									icon={'shareIcon'}
									iconColor={theme.colors.primary}
									iconStyles={{ marginRight: '8px' }}
									className="px-3 mt-3 w-100"
									label="Share Details"
									onClick={shareQRCode}
								/>
							</OnMobile>
						</div>
					</Col>
				</Row>
			</Container>
		)
	}
	return (
		<CustomWrapper className="text-left">
			<MethodHeader className="w-100" color={theme.colors.primary} fontSize="24px" fontWeight="700">
				Pay using Bank Transfer (UPI/QR Code/IMPS / NEFT / RTGS)
			</MethodHeader>
			{bankDetailsElements}
			<div className="w-100 d-flex align-items-start flex-column text-left">
				<Text
					style={{ opacity: '.4' }}
					color={theme.colors.primary}
					fontSize="14px"
					fontWeight="700"
					className="my-3"
				>
					HELP US CONFIRM YOUR PAYMENT WITH {insurerName?.toUpperCase()}
				</Text>
				<Text className="my-2" color={theme.colors.primary} fontSize="18px" fontWeight="400">
					Kindly upload an image of the payment confirmation (including transaction confirmation
					code) or enter the transaction confirmation code. We will use this to confirm the
					transaction with {insurerName}
				</Text>
				<Space y={0.5} />

				<PaymentConfirmationUploadSection>
					<Text color={theme.colors.gray2} fontSize="14px" fontWeight="400">
						Upload payment confirmation screenshot here
					</Text>
					<Space y={0.5} />
					<HiddenFileInput
						ref={bankTransferImgRef}
						onChange={e => handleFileUpload(e.target.files, 'BANK_TRANSFER')}
					/>
					<UploadedItemsContainer>
						<UploadButton
							onClick={triggerBankTransferImgUpload}
							bgColor={theme.colors.white}
							label={<Icon size="24px" color={theme.colors.secondary} name="addBg" />}
							disabled={bankConfirmationImg}
							applyDisableFilter={!bankConfirmationImg}
						/>
						{bankConfirmationImg && (
							<FileUploaded
								imageUrl={bankConfirmationImg}
								onDelete={() => removeImage(bankConfirmationImg, 'BANK_TRANSFER')}
								style={{ height: '48px' }}
								thumbnail={UploadedImage}
							/>
						)}
					</UploadedItemsContainer>

					<div
						className="d-flex justify-content-between align-items-center my-3"
						style={{ color: '#F78670' }}
					>
						<span style={{ border: '1px solid #F78670', width: '100%' }} />
						<span className="px-3">OR</span>
						<span style={{ border: '1px solid #F78670', width: '100%' }} />
					</div>

					<Space y={1} />
					<Text color={theme.colors.gray2} fontSize="14px" fontWeight="400">
						UTR Number
					</Text>
					<Space y={0.5} />
					<CustomInput
						value={bankTransferUTRNo}
						onChange={e => setBankTransferUTRNo(e.target.value)}
						style={{ width: '100%' }}
						placeholder="Enter here"
					/>
				</PaymentConfirmationUploadSection>

				<OnDesktop className="mt-3">
					<Button
						disabled={!bankConfirmationImg && !bankTransferUTRNo}
						style={{ width: '373px', height: '48px' }}
						label="Submit"
						onClick={handleBankTransferDataSubmit}
					/>
				</OnDesktop>
				<OnMobile>
					<Divider className="my-4" />
					<div className="w-100 d-flex justify-content-between">
						<Button
							onClick={goBack}
							icon="leftArrow"
							bgColor={theme.colors.secondary2}
							textColor={theme.colors.primary}
							style={{ height: '40px', width: '110px' }}
							label="Back"
						/>
						<Button
							disabled={!bankConfirmationImg && !bankTransferUTRNo}
							onClick={handleBankTransferDataSubmit}
							style={{ height: '40px', marginLeft: '1rem', width: '110px' }}
							label="Submit"
							icon="rightArrow"
							iconAlign="right"
						/>
					</div>
				</OnMobile>
			</div>
		</CustomWrapper>
	)
}

export default BankTransfer

// styles
const CustomWrapper = styled(MainWrapper)`
	width: 90%;
	text-align: inherit !important;
	@media (max-width: 768px) {
		display: flex;
		align-items: center;
		flex-direction: column;
		background: white;
		width: 100%;
		height: 100%;
		margin-top: 0px;
		margin: 0;
	}
`

const MainHeader = styled(Text)`
	@media (min-width: 768px) {
		text-align: center !important;
	}
	@media (max-width: 768px) {
		text-align: left !important;
		font-size: 18px !important;
	}
`

const TableRow = styled.tr`
	@media (max-width: 768px) {
		display: flex;
		flex-direction: column;
	}
`

const DetailName = styled.td`
	text-align: left !important;
	margin: 0;
	padding: 0;
	padding-right: 0.5rem;
	padding-top: 0.2rem;
	color: ${theme.colors.primary};
	font-weight: 700;
	font-size: 14px;
	opacity: 0.4;
	@media (max-width: 768px) {
		padding-top: 0.8rem;
		padding-bottom: 0.4rem;
	}
`

const DetailValue = styled.td`
	text-align: left !important;
	margin: 0;
	padding: 0;
	color: ${theme.colors.primary};
	font-weight: 700;
	font-size: 14px;
`

const PaymentConfirmationUploadSection = styled.div`
	border: 2px solid #fcebe3;
	border-radius: 8px;
	padding: 1.5rem;
	@media (max-width: 768px) {
		border: none;
		padding: 0;
		width: 100%;
	}
`

const MethodHeader = styled(MainHeader)`
	text-align: left !important;
	@media (max-width: 768px) {
		text-align: left !important;
		font-size: 18px;
	}
`

const UploadedItemsContainer = styled.div`
	display: flex;
	align-items: center;
`

const UploadButton = styled(Button)`
	border: 2px dashed #8d8d8d;
	width: 320px;
	height: 48px;
	@media (max-width: 768px) {
		width: 72px;
	}
`

const CustomInput = styled(Input)`
	width: 430px;
	height: 48px;
	@media (max-width: 768px) {
		width: 100%;
	}
`

const ShareButton = styled(Button)`
	background-color: white;
	color: ${theme.colors.primary};
	border: solid 2px ${theme.colors.primary};
	&:hover {
		background-color: white;
	}
`

const StyledLink = styled.a`
	background-color: ${theme.colors.primary};
	color: white;
	width: 100%;
	padding: 8px 24px;
	margin-top: 16px;
	display: flex;
	border-radius: 8px;
	font-size: 14px;
	justify-content: center;
	&:hover {
		color: white;
	}
`
