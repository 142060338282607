const stateOps = [
	{
		name: 'Andaman and Nicobar Islands',
		value: 'Andaman and Nicobar Islands',
	},
	{
		name: 'Andhra Pradesh',
		value: 'Andhra Pradesh',
	},
	{
		name: 'Arunachal Pradesh',
		value: 'Arunachal Pradesh',
	},
	{
		name: 'Assam',
		value: 'Assam',
	},
	{
		name: 'Bihar',
		value: 'Bihar',
	},
	{
		name: 'Chandigarh',
		value: 'Chandigarh',
	},
	{
		name: 'Chhattisgarh',
		value: 'Chhattisgarh',
	},
	{
		name: 'Dadra and Nagar Haveli',
		value: 'Dadra and Nagar Haveli',
	},
	{
		name: 'Daman and Diu',
		value: 'Daman and Diu',
	},
	{
		name: 'Delhi',
		value: 'Delhi',
	},
	{
		name: 'Goa',
		value: 'Goa',
	},
	{
		name: 'Gujarat',
		value: 'Gujarat',
	},
	{
		name: 'Haryana',
		value: 'Haryana',
	},
	{
		name: 'Himachal Pradesh',
		value: 'Himachal Pradesh',
	},
	{
		name: 'Jammu and Kashmir',
		value: 'Jammu and Kashmir',
	},
	{
		name: 'Jharkhand',
		value: 'Jharkhand',
	},
	{
		name: 'Karnataka',
		value: 'Karnataka',
	},
	{
		name: 'Kerala',
		value: 'Kerala',
	},
	{
		name: 'Ladakh',
		value: 'Ladakh',
	},
	{
		name: 'Lakshadweep',
		value: 'Lakshadweep',
	},
	{
		name: 'Madhya Pradesh',
		value: 'Madhya Pradesh',
	},
	{
		name: 'Maharashtra',
		value: 'Maharashtra',
	},
	{
		name: 'Manipur',
		value: 'Manipur',
	},
	{
		name: 'Meghalaya',
		value: 'Meghalaya',
	},
	{
		name: 'Mizoram',
		value: 'Mizoram',
	},
	{
		name: 'Nagaland',
		value: 'Nagaland',
	},
	{
		name: 'Odisha',
		value: 'Odisha',
	},
	{
		name: 'Puducherry',
		value: 'Puducherry',
	},
	{
		name: 'Punjab',
		value: 'Punjab',
	},
	{
		name: 'Rajasthan',
		value: 'Rajasthan',
	},
	{
		name: 'Sikkim',
		value: 'Sikkim',
	},
	{
		name: 'Tamil Nadu',
		value: 'Tamil Nadu',
	},
	{
		name: 'Telangana',
		value: 'Telangana',
	},
	{
		name: 'Tripura',
		value: 'Tripura',
	},
	{
		name: 'Uttar Pradesh',
		value: 'Uttar Pradesh',
	},
	{
		name: 'Uttarakhand',
		value: 'Uttarakhand',
	},
	{
		name: 'West Bengal',
		value: 'West Bengal',
	},
]

const defaultHypothecationParty = {
	partyName: '',
	partyType: '',
}

const salutationsList = ['Mr.', 'Mrs.', 'Ms.', 'Dr.', 'Prof.', 'Shri.', 'Smt.']

export { stateOps, defaultHypothecationParty, salutationsList }
