import { theme } from 'verak-ui'

export const primaryHeader = {
	fontSize: '24px',
	color: `${theme.colors.primary}`,
	fontWeight: 700,
}

export const secondaryHeader = {
	fontSize: '24px',
	color: `${theme.colors.secondary}`,
	fontWeight: 700,
}
