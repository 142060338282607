import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'

import 'normalize.css' // css normalization across browsers
import 'bootstrap/dist/css/bootstrap.min.css'

import { Provider } from './context/Provider'
import { SidepanelProvider } from './context/SidepanelProvider'
import { BrowserRouter as Router } from 'react-router-dom'

ReactDOM.render(
	<React.StrictMode>
		<Router>
			<Provider>
				<SidepanelProvider>
					<App />
				</SidepanelProvider>
			</Provider>
		</Router>
	</React.StrictMode>,
	document.getElementById('root')
)
