import roundOffNum from './roundOffNum'

const calculateTotalPremium = quoteItem => {
	// total premium amount changes if premium breakdown is available
	let totalPremium = roundOffNum(quoteItem?.TotalPremium) || 0
	const hasPremiumBreakdown = quoteItem?.HasPremiumBreakdown

	let totalAddonPremiums = 0
	if (hasPremiumBreakdown) {
		totalAddonPremiums = quoteItem?.Addons?.filter(a => !a?.Rejected)?.reduce(
			(previous, current) => {
				return previous + (roundOffNum(current?.Amount) || 0)
			},
			0
		)
	}
	totalPremium = roundOffNum(totalPremium + totalAddonPremiums)

	return totalPremium
}

export default calculateTotalPremium
