import React from 'react'
import { Route, useHistory } from 'react-router-dom'
import { useContextApi } from '../context/Provider'

const PublicRoute = ({ component, ...args }) => {
	const history = useHistory()
	const [state] = useContextApi()

	const hasLocalStorageData = localStorage.getItem('token') && localStorage.getItem('user')
	const isAuthenticated = state?.user?.authenticated || hasLocalStorageData || false

	if (isAuthenticated) {
		history.replace('/')
		return null
	}

	return <Route component={component} {...args} />
}

export default PublicRoute
