import { useHistory, useLocation } from 'react-router'
import { useContextApi } from '../context/Provider'
import { useHelpModal } from '../context/HelpModalProvider'
import Appbar from './Appbar'
import { Button, theme } from 'verak-ui'

const AppbarWrapper = () => {
	const history = useHistory()
	const [state, dispatch] = useContextApi()
	const { showHelpModal } = useHelpModal()

	const { pathname } = useLocation()

	const showRightButton = pathname === '/login'

	const isAuthenticated = state?.user?.authenticated || false
	const userData = state?.user?.data || {}

	const triggerLogout = async () => {
		await new Promise(resolve => {
			dispatch({
				type: 'LOGOUT_USER',
			})
			resolve()
		})
		history.replace('/login')
	}
	const name = userData?.name
	const userName = name?.includes('. ') ? name?.split('. ')[1] : name

	const rightContent = showRightButton ? (
		<Button
			label="Help"
			icon="helpAltIcon"
			iconColor={theme.colors.white}
			iconStyles={{ marginRight: '.5rem' }}
			onClick={() => showHelpModal(true)}
		/>
	) : null

	const clearState = () => {
		history.replace('/')
		dispatch({
			type: 'CLEAR_SELECTED_RFQ_DATA',
		})
	}

	return (
		<Appbar
			loggedIn={isAuthenticated}
			triggerLogout={triggerLogout}
			onHomeRedirect={clearState}
			rightContent={rightContent}
			user={{
				picture: `https://ui-avatars.com/api/?name=${userName}`,
				name: name || '',
			}}
		/>
	)
}

export default AppbarWrapper
