// package imports
import React, { useState, useContext } from 'react'
import { Switch } from 'react-router-dom'
import { PageContainer as PageWrapper, SidePanel } from 'verak-ui'
import styled from 'styled-components'

// auth
import ProtectedRoute from './auth/protected-route'
import PublicRoute from './auth/public-route'
// screens
import Home from './screens/Home'
import Login from './screens/Login'
import StatusHandler from './screens/RFQStatuses/StatusHandler'
import CustomerInfo from './screens/CustomerInfo/CustomerInfo'
import SelfDeclaration from './screens/CustomerInfo/SelfDeclaration'
import ProceedToPayment from './screens/CustomerInfo/ProceedToPayment'
import PaymentMethods from './screens/CustomerInfo/PaymentMethods'
import OnlinePayment from './screens/CustomerInfo/OnlinePayment'
import CompareQuotes from './screens/RFQStatuses/QuotesAvailable/CompareQuotes'

// context
import { SidepanelContext } from './context/SidepanelProvider'
import LoadingErrorContainer, { LoadingErrorProvider } from './context/LoadingErrorProvider'
import HelpModalContainer, { HelpModalProvider } from './context/HelpModalProvider'

// dummy commit
// css imports
import './App.css'
import AppbarWrapper from './components/AppbarWrapper'

function App() {
	const [sidebarOpen, setSidebarOpen] = useState(true)
	const toggleSidebar = () => setSidebarOpen(p => !p)
	const { renderSidePanel, sidepanelData } = useContext(SidepanelContext)
	const { sidepanelTitle, extraContent } = sidepanelData

	const showSidePanel = renderSidePanel ? (
		<>
			{/* dummy element [side panel mask] */}
			<SidePanelMask open={sidebarOpen} />
			<SidePanel
				open={sidebarOpen}
				toggle={toggleSidebar}
				sidepanelTitle={sidepanelTitle}
				extraContent={extraContent}
				contentClassName="flex-fill d-flex"
			/>
		</>
	) : null

	return (
		<LoadingErrorProvider>
			<HelpModalProvider>
				<PageWrapper>
					<AppbarWrapper />
					<PageContentWrapper>
						{showSidePanel}
						<RightPanel>
							<Switch>
								<ProtectedRoute path="/" exact={true} component={Home} />
								<ProtectedRoute path="/rfq-status/:id" exact={true} component={StatusHandler} />
								<ProtectedRoute
									path="/purchase-policy/compare-quotes"
									exact={true}
									component={CompareQuotes}
								/>
								<ProtectedRoute
									path="/purchase-policy/customer-info"
									exact={true}
									component={CustomerInfo}
								/>
								<ProtectedRoute
									path="/purchase-policy/self-declaration"
									exact={true}
									component={SelfDeclaration}
								/>
								<ProtectedRoute
									path="/purchase-policy/proceed-to-payment"
									exact={true}
									component={ProceedToPayment}
								/>
								<ProtectedRoute
									path="/purchase-policy/payment-methods"
									exact={true}
									component={PaymentMethods}
								/>
								<ProtectedRoute
									path="/purchase-policy/online-payment"
									exact={true}
									component={OnlinePayment}
								/>
								<PublicRoute path="/login" exact={true} component={Login} />
							</Switch>
						</RightPanel>
					</PageContentWrapper>
					<LoadingErrorContainer />
					<HelpModalContainer />
				</PageWrapper>
			</HelpModalProvider>
		</LoadingErrorProvider>
	)
}
export default App

const PageContentWrapper = styled.div`
	height: calc(100% - 64px);
	/* width: min(1280px, 100%); */
	width: 100%;
	margin-top: 60px;
	display: flex;
	justify-content: center;
`

const RightPanel = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	/* justify-content: start; */
	align-items: center;
	overflow-y: auto;

	/* padding: 0 2rem; */
	@media (max-width: 768px) {
		padding: 0;
	}
`

// dummy element to mask the sidepanel
// width same as the sidepanel
const SidePanelMask = styled.div`
	/* min-width: 200px; */
	padding: 1rem;
	min-width: ${p => (p.open ? '200px' : '65px')};
	transition: all 0.3s ease;
	@media (max-width: 768px) {
		display: none;
	}
`
