import styled from 'styled-components'
import { theme } from 'verak-ui'
import BhartiAXA from '../../../assets/insurers/BhartiAXA.svg'
import DigitInsurance from '../../../assets/insurers/DigitInsurance.svg'
import LibertyGeneralInsurance from '../../../assets/insurers/LibertyInsurance.svg'
import OrientalInsurance from '../../../assets/insurers/OrientalInsurance.svg'
import RelianceGeneralInsurance from '../../../assets/insurers/RelianceGeneralInsurance.svg'
import BajajAllianz from '../../../assets/insurers/bajajAllianz.svg'
import ICICILombardGeneralInsurance from '../../../assets/insurers/ICICI.svg'
import RoyalSundaram from '../../../assets/insurers/RoyalSundaram.svg'
import TataAIG from '../../../assets/insurers/TataAIG.svg'
import UnitedIndia from '../../../assets/insurers/UnitedIndia.svg'
import NationalInsurance from '../../../assets/insurers/NationalInsurance.svg'
import NewIndia from '../../../assets/insurers/NewIndiaAssurance.svg'
import KotakGeneral from '../../../assets/insurers/KotakGeneralInsurance.svg'
import Chola from '../../../assets/insurers/CholaMSGeneralInsurance.svg'
import SBIGeneral from '../../../assets/insurers/SBIGeneral.svg'

const ActionBar = styled.div`
	background: ${theme.colors.secondary3};
	padding: 16px 24px;
	@media (max-width: 768px) {
		padding: 8px 16px;
	}
`

const TableContainer = styled.div`
	display: flex;
	align-items: flex-start;
	height: 100%;
	width: 100%;
	padding-top: 1rem;
`

const Sidebar = styled.div`
	width: 260px;
	height: auto;
	position: sticky;
	@media (max-width: 768px) {
		width: 150px;
	}
`

const QuotesContainer = styled.div`
	width: calc(100% - 260px);
	height: 100%;
	padding-left: 2rem;
	padding-right: 2rem;
	display: flex;
	overflow-x: auto;
	flex-shrink: 0;
	@media (max-width: 768px) {
		width: calc(100% - 150px);
	}
`

const QuoteCardWrapper = styled.div`
	height: 100%;
	width: 340px;
	flex-shrink: 0;
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
	border-radius: 8px;
	overflow: hidden;
	margin-right: 1rem;
	background: ${theme.colors.white};
	@media (max-width: 768px) {
		width: 230px;
	}
`

const QuoteImg = styled.img`
	height: 98%;
	display: flex;
	align-items: center;
	object-fit: contain;
`

const KeyCell = styled.div`
	background: #fafafa;
	padding: 16px 8px 16px 16px;
	color: ${theme.colors.primary};
	font-weight: 700;
	font-size: 14px;
	position: sticky;
	left: 0;
	border-top: 0.5px solid #02475e2b;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: end;
	z-index: 100;
	height: ${({ height }) => height};
	max-height: ${({ height }) => height};
	text-align: right;
`

const ValueCell = styled.div`
	background: #fff;
	/* padding: 16px 8px; */
	text-align: center;
	display: flex;
	justify-content: ${p => (p.centerAlign ? 'center' : 'left')};
	border-top: 0.5px solid #02475e2b;
	width: 100%;
	height: ${({ height }) => height};
	max-height: ${({ height }) => height};
`

const ImageContainer = styled.div`
	background: #fff;
	padding: 16px 8px;
`

const MenuItemContainer = styled.li`
	display: flex;
	align-items: center;
	padding: 0.75rem 0.375rem;
	border-bottom: ${props => (props?.isLast ? '0.5px solid #8d8d8d' : 'none')};
	list-style: none;
	background-color: ${({ disabled }) => (disabled ? '#e3e3e3' : '#fff')};
	opacity: ${({ disabled }) => (disabled ? '0.6' : '1')};
	font-size: 12px;
	font-weight: 400;
	line-height: 15px;
	letter-spacing: 0rem;
	color: #8d8d8d;
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

	&:hover {
		background: ${({ disabled }) => (disabled ? '' : ' #e9e9e9')};
	}
`

const MenuContainer = styled.ul`
	min-width: 200px;
	padding: 0;
`

const FeatureItem = styled.li`
	font-size: 14px;
	font-weight: 600px;
	color: rgba(0, 0, 0, 0.7);
	text-align: left;
`

// utils

const infoRowOrder = {
	Insurer: { label: 'INSURANCE PROVIDER', height: '130px' },
	TotalPremium: { label: 'TOTAL PREMIUM', subLabel: 'INCL. OF TAXES', height: '160px' },
	Cover: { label: 'COVER', height: '70px' },
	PolicyType: { label: 'POLICY TYPE', height: '70px' },
	// Duration: { label: 'DURATION', height: '70px' },
	// Expiry: { label: 'QUOTE EXPIRY IN', height: '60px' },
	// 'TAT': {label: 'TAT'},
	Conditions: {
		label: 'INSURER RESPONSE',
		subLabel: 'Sum Insured Breakup(Overall):',
	},
	Addons: {
		label: 'ADD-ONS OPTED FOR',
		moreInfo: '',
	},
	// SIForAddons: {
	// 	label: 'Sum Insured for add on',
	// 	moreInfo: '',
	// },
	// ClaimsSettlementRatio: {
	// 	label: 'CLAIMS SETTLEMENT RATIO',
	// 	moreInfo:
	// 		'It is the % of claims that an insurance provider settles in a year out of the total claims.',
	// 	height: '200px',
	// },
	// SolvencyRatio: {
	// 	label: 'SOLVENCY RATIO',
	// 	height: '200px',
	// 	moreInfo:
	// 		'It measures whether the company has enough cash to meet its dues and other financial commitments.',
	// },
	// EstablishedOn: { label: 'ESTABLISHED', height: '200px' },
}

const safetyPlanInfoRowOrder = {
	TotalPremium: { label: 'TOTAL PREMIUM', subLabel: 'INCL. OF TAXES', height: '145px' },
	CCTV_01: {
		label: 'CCTV CAMERA',
		height: '400px',
	},
	MEMORY_01: {
		label: 'MEMORY CARD',
		height: '400px',
	},
	HardwareUnits: {
		label: 'NUMBER OF CCTV',
		height: '60px',
	},
	Service: {
		label: 'SERVICE',
		height: '60px',
	},
	Insurer: { label: 'INSURANCE PROVIDER', height: '130px' },
	Cover: { label: 'COVER', height: '60px' },
	PolicyType: { label: 'TYPE OF POLICY', height: '70px' },
	Conditions: {
		label: 'INSURER RESPONSE',
		subLabel: 'Sum Insured Breakup(Overall):',
	},
	Addons: {
		label: 'ADD-ONS OPTED FOR',
		moreInfo: '',
	},
}

const insurerMetrics = {
	// Note: ClaimsSettlementRatio is "Fire" specific
	'Liberty General Insurance': {
		SolvencyRatio: 2.18,
		ClaimsSettlementRatio: 81.53,
		EstablishedOn: '2013',
	},
	'Reliance General Insurance': {
		SolvencyRatio: 1.52,
		ClaimsSettlementRatio: 76.17,
		EstablishedOn: '2000',
	},
	'Bajaj Allianz General Insurance': {
		SolvencyRatio: 2.54,
		ClaimsSettlementRatio: 88.83,
		EstablishedOn: '2001',
	},
	'Digit Insurance': { SolvencyRatio: 3.24, ClaimsSettlementRatio: 77.17, EstablishedOn: '2016' },
	'ICICI Lombard General Insurance': {
		SolvencyRatio: 2.17,
		ClaimsSettlementRatio: 80.4,
		EstablishedOn: '2001',
	},
	'Oriental Insurance': {
		SolvencyRatio: 0.92,
		ClaimsSettlementRatio: 91.51,
		EstablishedOn: '1947',
	},
	'Royal Sundaram General Insurance': {
		SolvencyRatio: 1.69,
		ClaimsSettlementRatio: 89.29,
		EstablishedOn: '2001',
	},
	'United India Insurance': {
		SolvencyRatio: 0.3,
		ClaimsSettlementRatio: 80.7,
		EstablishedOn: '1938',
	},
	'Tata AIG General Insurance': {
		SolvencyRatio: 1.84,
		ClaimsSettlementRatio: 84.52,
		EstablishedOn: '2001',
	},
	'National Insurance': {
		SolvencyRatio: 0.02,
		ClaimsSettlementRatio: 80.59,
		EstablishedOn: '1906',
	},
	'Kotak Mahindra General': {
		SolvencyRatio: 2.13,
		ClaimsSettlementRatio: 77.67,
		EstablishedOn: '2015',
	},
	'The New India Assurance Co. Ltd.': {
		SolvencyRatio: 2.11,
		ClaimsSettlementRatio: 89.87,
		EstablishedOn: '1919',
	},
	'SBI General Insurance': {
		SolvencyRatio: 2.27,
		ClaimsSettlementRatio: 78.0,
		EstablishedOn: '2009',
	},
}

const InsurerImagesMap = {
	'Oriental Insurance': OrientalInsurance,
	'Bharti AXA': BhartiAXA,
	'Digit Insurance': DigitInsurance,
	'Liberty General Insurance': LibertyGeneralInsurance,
	'Reliance General Insurance': RelianceGeneralInsurance,
	'SBI General Insurance': SBIGeneral,
	'Bajaj Allianz General Insurance': BajajAllianz,
	'ICICI Lombard General Insurance': ICICILombardGeneralInsurance,
	'Royal Sundaram General Insurance': RoyalSundaram,
	'United India Insurance': UnitedIndia,
	'Tata AIG General Insurance': TataAIG,
	'National Insurance': NationalInsurance,
	'The New India Assurance Co. Ltd.': NewIndia,
	'Kotak General Insurance': KotakGeneral,
	'Chola MS General Insurance': Chola,
}

const coverageValues = [
	{ value: 'D15', name: '15 days', months: 0.5 },
	{ value: 'M1', name: '1 month', months: 1 },
	{ value: 'M2', name: '2 months', months: 2 },
	{ value: 'M3', name: '3 months', months: 3 },
	{ value: 'M4', name: '4 months', months: 4 },
	{ value: 'M5', name: '5 months', months: 5 },
	{ value: 'M6', name: '6 months', months: 6 },
	{ value: 'M7', name: '7 months', months: 7 },
	{ value: 'M8', name: '8 months', months: 8 },
	{ value: 'M9', name: '9 months', months: 9 },
	{ value: 'M10', name: '10 months', months: 10 },
	{ value: 'M11', name: '11 months', months: 11 },
	{ value: 'M12', name: '12 months', months: 12 },
	// { value: 'Y2', name: '2 years', months: 24 },
	// { value: 'Y3', name: '3 years', months: 26 },
	// { value: 'Y4', name: '4 years', months: 48 },
	// { value: 'Y5', name: '5 years', months: 60 },
	// { value: 'Y6', name: '6 years', months: 72 },
	// { value: 'Y7', name: '7 years', months: 84 },
	// { value: 'Y8', name: '8 years', months: 96 },
	// { value: 'Y9', name: '9 years', months: 108 },
	// { value: 'Y10', name: '10 years', months: 120 },
]

export {
	// styles
	KeyCell,
	ValueCell,
	ImageContainer,
	ActionBar,
	QuoteImg,
	QuoteCardWrapper,
	QuotesContainer,
	TableContainer,
	Sidebar,
	MenuContainer,
	MenuItemContainer,
	FeatureItem,
	// utils
	coverageValues,
	infoRowOrder,
	insurerMetrics,
	InsurerImagesMap,
	safetyPlanInfoRowOrder,
}
