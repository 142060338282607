import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useHistory } from 'react-router'

import { useContextApi } from '../../context/Provider'
import { getInsurerImage } from '../../utilities/getInsurerImage'
import {
	PageContentAndNavbarWrapper,
	SecondaryNavbar,
	theme,
	Text,
	Space,
	Divider,
	Button,
} from 'verak-ui'
import {
	MainWrapper,
	navContent,
	OnDesktop,
	OnMobile,
	mobileLeftNav1,
	StyledText,
} from '../../components/SharedComponents'
import { primaryHeader } from '../../components/SharedStyles'
import { getFormattedAmount } from '../../utilities/getFormattedAmount'
import calculateTotalPremium from '../../utilities/calculateTotalPremium'

const PaymentPending = () => {
	const [state, dispatch] = useContextApi()
	const currentRfqItem = state?.currentRfqItem
	const quote = currentRfqItem?.selectedQuote || {}

	const totalPremium = calculateTotalPremium(quote)

	const history = useHistory()

	const continueToPayment = () => {
		history.push('/purchase-policy/payment-methods')
	}

	// const viewTransactionHistory = (
	// 	<Button label="View Transaction History" style={{ height: '48px', width: '250px' }} />
	// )

	return (
		<PageContentAndNavbarWrapper className="d-flex flex-column align-items-center">
			<OnDesktop>
				<SecondaryNavbar
					leftContent={navContent(state?.currentRfqItem, 'Payment Pending')}
					navbarNumber={1}
					// rightContent={viewTransactionHistory}
					showGoBack={false}
					headerBg={theme.colors.secondary2}
				/>
			</OnDesktop>
			<OnMobile>
				<SecondaryNavbar
					showGoBack={false}
					headerBg={theme.colors.white}
					navbarNumber={1}
					leftContent={mobileLeftNav1('Payment Pending')}
				/>
			</OnMobile>
			<MainWrapper>
				<Space mobileY={4} />
				<StyledText
					mobileFontSize="24px"
					style={{ ...primaryHeader, color: `${theme.colors.red}` }}
				>
					Oops! Looks like the payment is pending
				</StyledText>
				<Space y={0.5} />
				<Text color={theme.colors.primary} fontSize="18px" fontWeight="700">
					If the money was deducted from your account, you will receive a{' '}
					<span style={{ color: theme.colors.green }}>REFUND</span> soon!
				</Text>
				<Space y={1} mobileY={2} />
				<OnDesktop>
					<div className="d-flex justify-content-center">
						<Button
							onClick={continueToPayment}
							icon="renewPolicy"
							iconAlign="right"
							iconStyles={{ marginLeft: '.5rem' }}
							iconColor={theme.colors.green}
							bgColor="#d8f1c0"
							textColor={theme.colors.green}
							style={{
								fontSize: '20px',
								fontWeight: 700,
								padding: '16px 20px !important',
								height: '64px',
								border: '0.5px solid #4AA161',
							}}
							label="Please retry with the payment to complete your policy purchase"
						/>
					</div>
				</OnDesktop>
				<OnMobile>
					<div className="d-flex justify-content-center">
						<Button
							onClick={continueToPayment}
							icon="renewPolicy"
							iconAlign="right"
							iconColor={theme.colors.green}
							iconStyles={{ marginLeft: '0.5rem' }}
							bgColor="#d8f1c0"
							textColor={theme.colors.green}
							style={{
								fontSize: '14px',
								fontWeight: 700,
								padding: '16px 20px !important',
								height: '48px',
								border: '0.5px solid #4AA161',
							}}
							label="Retry payment, to purchase policy"
						/>
					</div>
				</OnMobile>
				<Space y={1} mobileY={2} />
				<StyledContainer>
					<Text style={textStyle}>Chosen policy</Text>
					<Divider top={0.625} bottom={0.625} />
					<StyledImg src={getInsurerImage(quote?.Insurer)} alt={quote?.Insurer} />
					<Divider top={0.625} bottom={0.625} />
					<Text style={textStyle}>Premium</Text>
					<StyledText style={premiumTextStyle}>
						₹ {totalPremium ? getFormattedAmount(totalPremium) : ''}
					</StyledText>
				</StyledContainer>

				<Space y={4.625} />
				<Divider />
				<Space y={1} />

				<div className="full-flex-container w-100">
					<div className="flex-container">
						<Link to="/" style={{ textDecoration: 'none' }}>
							<Button
								label="Home"
								style={{ width: '120px', height: '48px' }}
								icon="leftArrow"
								secondary
							/>
						</Link>
					</div>
					<OnDesktop style={{ width: 'auto' }}>
						<Button
							onClick={continueToPayment}
							label="Retry payment"
							bgColor="#D8F1C0"
							style={{
								...buttonStyle,
								color: theme.colors.green,
								border: '0.5px solid #4AA161',
								fontSize: '13px',
							}}
							icon="rightArrow"
							iconAlign="right"
							iconColor={theme.colors.green}
							iconStyles={{ marginLeft: '0.5rem' }}
						/>
					</OnDesktop>
					<OnMobile style={{ width: 'auto' }}>
						<Button
							onClick={continueToPayment}
							label="Retry payment"
							bgColor="#D8F1C0"
							style={{
								...buttonStyle,
								color: theme.colors.green,
								border: '0.5px solid #4AA161',
								fontSize: '13px',
							}}
							icon="renewPolicy"
							iconAlign="right"
							iconColor={theme.colors.green}
							iconStyles={{ marginLeft: '0.5rem' }}
						/>
					</OnMobile>
				</div>
			</MainWrapper>
		</PageContentAndNavbarWrapper>
	)
}

export default PaymentPending

const textStyle = {
	fontSize: '18px',
	fontWeight: 700,
	color: `${theme.colors.secondary}`,
}

const premiumTextStyle = {
	fontSize: '36px',
	fontWeight: 700,
	color: `${theme.colors.primary}`,
	paddingTop: '20px',
}

const buttonStyle = {
	width: '240px',
	height: '48px',
}

const StyledContainer = styled.div`
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
	border-radius: 8px;
	padding: 1rem 0.625rem;
	width: min(312px, 100%);
	margin: 0 auto;
`
const StyledImg = styled.img`
	@media (max-width: 768px) {
		width: 150px;
		height: 75px;
	}
`
