import { useRef } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Input } from 'verak-ui'

const SingleInput = ({ focus, ...rest }) => {
	const inputRef = useRef()

	useEffect(() => {
		if (focus) {
			inputRef.current.focus()
		}
	}, [focus])

	return <Input ref={inputRef} {...rest} />
}

const OTPInput = ({
	values,
	onValueChangeAtIndex,
	onAllValuesChange,
	containerStyle = {},
	inputStyle = {},
	...remainingProps
}) => {
	const length = values?.length || 0

	// set active input as the first input by default
	const [activeInput, setActiveInput] = useState(-1)

	const focusInput = index => {
		// to prevent the focus from going out of bounds
		// const selectedInput = Math.max(Math.min(index, length - 1), 0)
		if (index >= 0 && index < length) {
			setActiveInput(index)
		} else if (index >= length) {
			setActiveInput(length - 1)
		} else if (index < 0) {
			setActiveInput(0)
		}
	}

	const handleFocusChange = index => {
		focusInput(index)
	}

	const focusPreviousInput = () => {
		if (activeInput > 0) {
			focusInput(activeInput - 1)
		}
	}

	const foxusNextInput = () => {
		if (activeInput < length - 1) {
			focusInput(activeInput + 1)
		}
	}

	const handleOnKeyDown = e => {
		const pressedKey = e.key

		switch (pressedKey) {
			// delete an input
			case 'Backspace':
			case 'Delete':
				e.preventDefault()
				// if value present at current clear input
				if (values[activeInput]) {
					onValueChangeAtIndex(activeInput, '')
				} else {
					focusPreviousInput()
				}
				break
			case 'ArrowLeft':
				e.preventDefault()
				focusPreviousInput()
				break
			case 'ArrowRight':
				e.preventDefault()
				foxusNextInput()
				break
			default:
			// if these inputs are not digits, ignore them
			// if (!pressedKey.match(/[0-9]/)) {
			//     e.preventDefault()
			// }
		}
	}

	const handleOnChange = e => {
		let value = e.target.value
		value = value.slice(0, 1)
		if (value.match(/[0-9]/)) {
			onValueChangeAtIndex(activeInput, value)
			foxusNextInput()
		}
	}

	const handleOnPaste = e => {
		e.preventDefault()
		const pastedText = e.clipboardData
			.getData('text/plain')
			.trim()
			.replace(/([^0-9])*/gi, '')
			.slice(0, length - activeInput)
			.replace()
			.split('')

		if (pastedText.length > 0) {
			const newValues = [...values]
			pastedText.forEach((value, index) => {
				const newIndex = activeInput + index
				if (newIndex < length) {
					newValues[newIndex] = value
				}
			})
			focusInput(pastedText?.length - 1)
			onAllValuesChange(newValues)
		}
	}

	const handleOnBlur = () => {
		setActiveInput(-1)
	}

	const renderInputFields = values.map((value, index) => (
		<SingleInput
			key={`otp-input-${index}`}
			type="number"
			value={value}
			placeholder="-"
			maxLength="1"
			onChange={handleOnChange}
			focus={activeInput === index}
			onKeyDown={handleOnKeyDown}
			onFocus={() => handleFocusChange(index)}
			onPaste={handleOnPaste}
			onBlur={handleOnBlur}
			style={inputStyle}
			{...remainingProps}
		/>
	))

	return (
		<div className="d-flex align-items-center justify-content-center" style={containerStyle}>
			{renderInputFields}
		</div>
	)
}

export default OTPInput
