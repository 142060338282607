import { theme, Text, Icon, Space, Button } from 'verak-ui'
import {
	// styles
	ValueCell,
	ImageContainer,
	QuoteCardWrapper,
	QuoteImg,
	// utils
	coverageValues,
	infoRowOrder,
	insurerMetrics,
	InsurerImagesMap,
	safetyPlanInfoRowOrder,
	FeatureItem,
} from './quoteComparisonUtils'
import { convertAmountToWords } from '../../../utilities/convertAmountToWords'
import { getFormattedAmount } from '../../../utilities/getFormattedAmount'
import roundOffNum from '../../../utilities/roundOffNum'
import { getDiffYears } from '../../../utilities/getDateString'
import styled from 'styled-components'
import { useRef, useEffect } from 'react'
import { getPolicyTypeFullForm } from '../../../utilities/getPolicyType'
import { useContextApi } from '../../../context/Provider'

import burglary_theft from '../../../assets/addonIcons/burglary_theft.svg'
import burglary from '../../../assets/addonIcons/burglary.svg'
import cash_in_safe from '../../../assets/addonIcons/cash_in_safe.svg'
import cash_in_transit from '../../../assets/addonIcons/cash_in_transit.svg'
import cash_in_counter from '../../../assets/addonIcons/cash_in_counter.svg'
import electronic_equipments from '../../../assets/addonIcons/electronic_equipments.svg'
import fidelity_larceny from '../../../assets/addonIcons/fidelity_larceny.svg'
import flop from '../../../assets/addonIcons/flop.svg'
import mlop from '../../../assets/addonIcons/mlop.svg'
import mbd from '../../../assets/addonIcons/mbd.svg'
import neon_sign_board from '../../../assets/addonIcons/neon_sign_board.svg'
import noAddons from '../../../assets/addonIcons/noAddons.svg'
import plate_glass from '../../../assets/addonIcons/plate_glass.svg'
import { calculateBurglarySI } from '../../../utilities/calculateBurglarySI'

const NASymbol = () => {
	return (
		<Text className="mx-2" fontSize="18px" fontWeight="700" color={theme.colors.gray5}>
			NA
		</Text>
	)
}

const LeftText = styled(Text)`
	text-align: left;
`

const AddOnContainer = styled.div`
	/* padding-left: 42.5px; */
	padding: 25px 0;
`

const QuoteCard = ({
	quote,
	allQuotes,
	onBuyClick,
	onTermsClick,
	SumInsuredBreakdown,
	cover,
	onLoadComplete,
	heightInfo,
	userOptedAddons,
	onToggleSIBreakupModal,
	onToggleAddOnSIBreakup,
	isSafetyPlan,
}) => {
	const [state] = useContextApi()

	const insurerResponseRef = useRef()
	const addOnRef = useRef()
	const siBreakupRef = useRef()

	useEffect(() => {
		onLoadComplete({
			Conditions: insurerResponseRef?.current?.clientHeight || 0,
			Addons: addOnRef?.current?.clientHeight || 0,
			SIForAddons: siBreakupRef?.current?.clientHeight || 0,
		})
	}, [])

	const cctvProduct = quote?.HardwareProducts?.find(({ Key }) => Key === 'CCTV_01')

	const getInsurerImageContainer = value => {
		return (
			<div className="position-relative  d-flex justify-content-center">
				<QuoteImg src={InsurerImagesMap[value]} alt={value} />
			</div>
		)
	}
	const getPremiumContainer = quoteItem => {
		const totalPremium = quoteItem?.Amount
		let durationValue = coverageValues.find(item => item.value === quoteItem?.Duration)
		let durationString = durationValue?.name
		if (durationString === '12 months') {
			durationString = '1 Year'
		}

		if (isNaN(totalPremium)) {
			return (
				<Text className="mx-2" fontSize="36px" fontWeight="700" color={theme.colors.gray4}>
					- NA -
				</Text>
			)
		} else {
			const perDayPremium = Math.round(totalPremium / durationValue?.months / 30)
			return (
				<div className="p-2 w-100 d-flex flex-column align-items-center">
					<span style={{ fontSize: '16px' }} className="d-flex flex-column justify-content-center">
						<Text
							className="mx-2"
							fontSize="32px"
							mobileFontSize="20px"
							fontWeight="700"
							color={theme.colors.primary}
						>
							<span style={{ fontSize: '18px', marginRight: '0.5rem' }}>₹</span>
							{getFormattedAmount(totalPremium)} /{' '}
							<span style={{ fontSize: '20px', margin: '0.5rem' }}>{durationString}</span>
						</Text>
						<Text fontSize="16px" fontWeight="700" color="#4F76E8">
							Costs: ₹&nbsp;{getFormattedAmount(perDayPremium)} / per day (approx)
						</Text>
					</span>
					<Button
						onClick={onBuyClick}
						className="mt-2"
						style={{ height: '48px', width: '100%' }}
						label="Buy this policy"
						primary
					/>
				</div>
			)
		}
	}

	const getCoverContainer = Cover => {
		return (
			<div className="d-flex align-items-center">
				<Text fontSize="18px" fontWeight="700" color={theme.colors.primary}>
					{convertAmountToWords(`₹ ${Cover || 0}`)}
				</Text>
			</div>
		)
	}

	const getDurationContainer = duration => {
		let durationValue = coverageValues.find(item => item.value === duration)
		let durationString = durationValue?.name
		if (durationString === '12 months') {
			durationString = '1 Year'
		}
		return (
			<div className="d-flex align-items-center">
				<Text fontSize="18px" fontWeight="700" color={theme.colors.primary}>
					{durationString}
				</Text>
			</div>
		)
	}

	const getExpiryContainer = expiry => {
		if (Number(expiry)) {
			return (
				<span className="d-flex align-item-center">
					<Icon color={theme.colors.secondary4} name="deleteIcon" /> <Space x={1} />
					<Text fontSize="18px" fontWeight="700" color={theme.colors.primary}>
						{expiry} {expiry > 1 ? 'days' : 'day'}
					</Text>
				</span>
			)
		}
		return <NASymbol />
	}

	const getSIForAddons = addons => {
		if (!addons.length) {
			return (
				<div
					ref={siBreakupRef}
					style={{ height: heightInfo?.SIForAddons, minHeight: '70px' }}
				></div>
			)
		}
		let addonSumInsuredItems = [
			'Stock',
			'PlantMachinery',
			'Electronics',
			'FurnitureFixture',
			'OfficeEquipment',
			'ValuableContents',
			'Other',
		]
		// if add-on contain burglary or burglary&theft then displat si breakup
		const isBreakupValid = addons.findIndex(item => {
			return item.Name == 'Burglary' || item.Name == 'BURGLARY_THEFT'
		})

		if (isBreakupValid == -1) {
			addonSumInsuredItems = []
		}

		let allowedBreakdown = addonSumInsuredItems.filter(key => !!SumInsuredBreakdown[key])

		const addonsTotalSI = allowedBreakdown.reduce((acc, curr) => SumInsuredBreakdown[curr] + acc, 0)

		return (
			<AddOnContainer ref={siBreakupRef} style={{ height: heightInfo?.SIForAddons }}>
				<div key="totalSI" className="mb-1">
					<LeftText fontSize="14px" fontWeight="700" color="#4C4C4C">
						TOTAL SUM INSURED
					</LeftText>
					<LeftText fontSize="18px" fontWeight="700" color="#4C4C4C">
						{convertAmountToWords(`₹ ${roundOffNum(addonsTotalSI || 0)}`)}
					</LeftText>
				</div>
				{allowedBreakdown.map(key => {
					return (
						<div key={key} className="mb-1">
							<LeftText fontSize="14px" fontWeight="400" color={theme.colors.gray3}>
								{getSumInsuredDisplayName(key)}
							</LeftText>
							<LeftText fontSize="18px" fontWeight="700" color="#4C4C4C">
								{convertAmountToWords(`₹ ${roundOffNum(SumInsuredBreakdown[key] || 0)}`)}
							</LeftText>
						</div>
					)
				})}
			</AddOnContainer>
		)
	}

	const getAddonsContainer = addons => {
		if (addons?.length > 0) {
			const showAddons = addons?.length > 2 ? addons.slice(0, 2) : addons
			const allAddons = showAddons.map((addon, index) => {
				const isAddonRejected = addon?.Rejected === true
				if (isAddonRejected) return null

				const addonName = addon?.Name
				const addonLabel = getAddOnLabel(addon?.Name)

				let addonSumInsured = addon?.SumInsured
				if (addon?.Name === 'Burglary' || addon?.Name === 'BURGLARY_THEFT') {
					addonSumInsured = calculateBurglarySI(SumInsuredBreakdown)
				}

				return (
					<div key={index} className="my-2 d-flex flex-column align-items-start">
						<div className="d-flex align-items-start">
							<img src={addonStyleConfig[addonName]?.icon} alt={addonName} />
							<Text
								className="mx-1 text-left"
								color={addonStyleConfig[addonName]?.color}
								fontWeight="700"
								fontSize="16px"
							>
								{addonNames[addonLabel] || addonLabel?.toUpperCase()}
							</Text>
						</div>
						<Text
							className="d-flex mx-1 align-items-center"
							fontSize="18px"
							fontWeight="700"
							color={theme.colors.primary}
						>
							Insured - {convertAmountToWords(`₹ ${roundOffNum(addonSumInsured || 0)}`)}{' '}
						</Text>
						<Text className="mx-1" fontSize="16px" fontWeight="500" color={theme.colors.gray3}>
							Premium - {getFormattedAmount(`₹ ${roundOffNum(addon?.Amount || 0)}`)}*/year
						</Text>
					</div>
				)
			})

			const isOnlyBurglary =
				addons?.length === 1 &&
				(addons[0]?.Name === 'BURGLARY_THEFT' || addons[0]?.Name === 'Burglary')

			return (
				<div ref={addOnRef} style={{ height: heightInfo?.Addons }} className="w-100 p-4">
					<div className="d-flex flex-column align-items-start">{allAddons}</div>
					<div className="d-flex justify-content-between align-items-center mt-1">
						<Text color={theme.colors.gray3} fontSize="16px" fontWeight="500">
							{addons?.length > 2 &&
								`${addons?.length - 2} more Add-on${addons?.length - 2 > 1 ? 's' : ''}`}
						</Text>
						{(addons?.length > 2 || isOnlyBurglary) && (
							<div
								style={{ textAlign: 'right', cursor: 'pointer' }}
								onClick={() => onToggleAddOnSIBreakup(quote?.id)}
							>
								<Text fontSize={'14px'} color={'#83A1F8'}>
									{isOnlyBurglary ? 'See Breakup' : 'See All'}
								</Text>
							</div>
						)}
					</div>
				</div>
			)
		} else {
			return (
				<div
					ref={addOnRef}
					style={{ height: heightInfo?.Addons }}
					className="p-2 d-flex flex-column"
				>
					<div className="w-100 d-flex align-items-center">
						<img src={noAddons} alt="no addons" />
						<Text className="mx-1" fontSize="16px" fontWeight="700" color={theme.colors.red}>
							NO ADD ONS
						</Text>
					</div>
					<Text className="mt-1" fontSize="12px" fontWeight="500" color={theme.colors.gray3}>
						NOTE - Add ons can be opted later too
					</Text>
				</div>
			)
		}
	}

	const getConditionsContainer = quoteItem => {
		if (quoteItem?.Status === 'ACCEPTED' && Number(quote?.TotalPremium)) {
			let iconList = [{ name: 'tickCircle', color: theme.colors.green }]
			let textConfig = { text: 'Proposal accepted', color: theme.colors.gray3 }

			// if (quoteItem.Conditions?.length > 0 && !!quoteItem.ReducedSumInsured) {
			// 	textConfig = {
			// 		text: `
			// 			1. Total Sum Insured has been reduced <br/>
			// 			2. Special Conditions have been added
			// 		`,
			// 		color: '#2F80ED',
			// 		fontSize: '14px',
			// 	}
			// 	iconList = [
			// 		{ name: 'lockInSquare', color: '#2F80ED' },
			// 		{ name: 'chartDownIcon', color: '#2F80ED' },
			// 	]
			// } else if (quoteItem.Conditions?.length > 0) {
			// 	textConfig = {
			// 		text: `Proposal has been accepted with special conditions`,
			// 		color: theme.colors.orange,
			// 		fontSize: '14px',
			// 	}
			// 	iconList = [{ name: 'lockInSquare', color: theme.colors.orange }]
			// }

			if (!!quoteItem.ReducedSumInsured) {
				textConfig = { text: `Total Sum Insured has been reduced`, color: '#2F80ED' }
				iconList = [{ name: 'lockInSquare', color: '#2F80ED' }]
			}

			const iconMap = iconList.map((icon, index) => (
				<Icon
					style={{ marginRight: '.5rem' }}
					key={index}
					name={icon.name}
					color={icon.color}
					size={'30px'}
				/>
			))

			// let showConditionsButton
			// if (!!quoteItem?.Conditions) {
			// 	showConditionsButton = (
			// 		<Button
			// 			onClick={() => onTermsClick({ type: 'CONDITIONS', content: quoteItem.Conditions })}
			// 			label="See terms"
			// 			icon="rightArrow"
			// 			iconAlign="right"
			// 			bgColor={theme.colors.secondary4}
			// 			iconColor={theme.colors.primary}
			// 			textColor={theme.colors.primary}
			// 			className="w-100"
			// 			style={{ zIndex: '10' }}
			// 		/>
			// 	)
			// }

			const siBreakup = Object.keys(SumInsuredBreakdown)
			const siBreakupItems = siBreakup?.length > 2 ? siBreakup.slice(0, 2) : siBreakup
			return (
				<div
					className="p-4 w-100 d-flex flex-column align-items-start"
					ref={insurerResponseRef}
					style={{ height: heightInfo?.Conditions }}
				>
					<div className="d-flex align-items-center mb-3">
						<div className="d-flex align-items-center">{iconMap}</div>
						<Text fontSize="14px" fontWeight="700" color={textConfig.color}>
							{textConfig.text}
						</Text>
					</div>
					<Text fontSize="14px" fontWeight="400" color={theme.colors.gray3}>
						Total Sum Insured
					</Text>
					<Text fontSize="20px" fontWeight="800" color="#333333">
						{convertAmountToWords(`₹ ${quoteItem?.Cover}`)}
					</Text>

					<div className="mx-3">
						{siBreakupItems.map(key => {
							return (
								<div key={key} className="mb-1">
									<LeftText fontSize="14px" fontWeight="400" color={theme.colors.gray3}>
										{getSumInsuredDisplayName(key)}
									</LeftText>
									<LeftText fontSize="18px" fontWeight="700" color="#4C4C4C">
										{convertAmountToWords(`₹ ${roundOffNum(SumInsuredBreakdown[key] || 0)}`)}
									</LeftText>
								</div>
							)
						})}
					</div>
					<Text
						fontSize={'14px'}
						color={'#83A1F8'}
						style={{ cursor: 'pointer' }}
						className="mt-2 d-flex align-self-end"
						onClick={onToggleSIBreakupModal}
					>
						See All
					</Text>
				</div>
			)
		} else {
			return <div style={{ height: heightInfo?.Conditions, minHeight: '70px' }}></div>
		}
	}

	const getMetricsContainer = (quoteItem, keyName) => {
		// only return metric containers if there are more than 1 quotes to compare

		let allInsurers = allQuotes.map(item => item.Insurer)

		const allSolvencyRatio = allInsurers.map(item => insurerMetrics[item]?.SolvencyRatio || 0)
		const allClaimsRatio = allInsurers.map(item => insurerMetrics[item]?.ClaimsSettlementRatio || 0)

		const metricValue = insurerMetrics[quoteItem.Insurer]?.[keyName]

		const highestSolvencyRatio = Math.max(...allSolvencyRatio)
		const highestClaimsRatio = Math.max(...allClaimsRatio)

		let highestSolvencyLabel
		if (highestSolvencyRatio === metricValue && allQuotes.length > 1) {
			highestSolvencyLabel = (
				<div className="d-flex mt-2 align-items-center">
					<Icon name="tickCircle" />
					<Text className="mx-2" color={theme.colors.gray3} fontSize="14px" fontWeight="400">
						{allQuotes.length > 2 ? 'Highest' : 'Higher'} ratio
					</Text>
				</div>
			)
		}

		let highestClaimsLabel
		if (highestClaimsRatio === metricValue && allQuotes.length > 1) {
			highestClaimsLabel = (
				<div className="d-flex mt-2 align-items-center">
					<Icon name="tickCircle" />
					<Text className="mx-2" color={theme.colors.gray3} fontSize="14px" fontWeight="400">
						{allQuotes.length > 2 ? 'Highest' : 'Higher'} ratio
					</Text>
				</div>
			)
		}
		let greaterThan90Label
		if (metricValue > 90.0) {
			greaterThan90Label = (
				<div className="d-flex mt-2 align-items-center">
					<Icon name="tickCircle" />
					<Text className="mx-2" color={theme.colors.gray3} fontSize="14px" fontWeight="400">
						Greater than 90%
					</Text>
				</div>
			)
		}

		let content
		if (keyName === 'SolvencyRatio') {
			content = (
				<>
					<Text fontSize="18px" fontWeight="700" color={theme.colors.primary}>
						{metricValue || <NASymbol />}
					</Text>
					{highestSolvencyLabel}
				</>
			)
		} else if (keyName === 'ClaimsSettlementRatio') {
			content = (
				<>
					<Text fontSize="18px" fontWeight="700" color={theme.colors.primary}>
						{metricValue ? `(${metricValue}%)` : <NASymbol />}
					</Text>
					{highestClaimsLabel}
					{greaterThan90Label}
				</>
			)
		} else if (keyName === 'EstablishedOn') {
			const years = getDiffYears(metricValue)
			content = (
				<div className="d-flex flex-column align-items-center">
					<Text fontSize="20px" fontWeight="700" color={theme.colors.primary} className="me-2">
						{metricValue}
					</Text>
					<Text fontSize="14px" fontWeight="400" color={theme.colors.gray3}>
						{`${years} years ago`}
					</Text>
				</div>
			)
		}
		return (
			<div className="d-flex flex-column w-100 align-items-center justify-content-center ms-2 mt-2">
				{content}
			</div>
		)
	}

	const getPolicyType = () => {
		const policyType = state?.currentRfqItem?.policyName || ''
		return (
			<div className="d-flex flex-column align-items-center justify-content-center">
				<Text fontSize="20px" mobileFontSize="14px" fontWeight="700" color={theme.colors.primary}>
					{policyType}
				</Text>
				<Text
					fontSize="16px"
					mobileFontSize="12px"
					fontWeight="700"
					color="#999999"
				>{`(${getPolicyTypeFullForm(policyType)})`}</Text>
			</div>
		)
	}

	// safety plan methods
	// const getProductContainer = (quoteItem, keyName) => {
	// 	return (
	// 		<div class="d-flex flex-column align-items-center">
	// 			<img src={quote?.Product?.Image} width="90px" alt="cctv" />
	// 			<Text color={theme.colors.primary} fontSize="18px" fontWeight="700">
	// 				{quote.Product.Manufacturer} - {quote.Product.Model}
	// 			</Text>
	// 		</div>
	// 	)
	// }

	const getProductContainer = (quoteItem, keyName) => {
		const hardwareProduct = quoteItem?.Products?.find(p => p.Key === keyName)
		return (
			<div class="d-flex flex-column align-items-center">
				<img src={hardwareProduct?.Image} width="90px" alt={keyName} />
				<Text color={theme.colors.primary} fontSize="18px" fontWeight="700">
					{hardwareProduct?.Manufacturer} - {hardwareProduct?.Model}
				</Text>
				<div className="my-2 d-flex align-items-center justify-content-center">
					<div style={{ height: '1px', width: '40px', background: 'rgba(0,0,0,0.4)' }}></div>
					<Text className="mx-1" fontSize="14px" fontWeight="600" color="rgba(0,0,0,0.4)">
						PRODUCT DESCRIPTION
					</Text>
					<div style={{ height: '1px', width: '30px', background: 'rgba(0,0,0,0.4)' }}></div>
				</div>
				<ul>
					{hardwareProduct?.Features.map((feature, idx) => (
						<FeatureItem key={idx}>{feature}</FeatureItem>
					))}
				</ul>
			</div>
		)
	}

	const getServiceContainer = () => {
		return (
			<Text
				className="d-flex align-items-center"
				color={theme.colors.primary}
				fontSize="18px"
				fontWeight="700"
			>
				<span style={{ color: '#4AA161' }}>FREE!</span>&nbsp; Installation and Service
			</Text>
		)
	}

	const getHardwareUnitsContainer = () => {
		return (
			<Text
				className="d-flex align-items-center"
				color={theme.colors.primary}
				fontWeight="700"
				fontSize="18px"
			>
				{cctvProduct?.Units}
			</Text>
		)
	}

	const getDynamicContent = (keyName, quoteItem) => {
		const value = quoteItem[keyName]
		switch (keyName) {
			case 'Insurer':
				return getInsurerImageContainer(value)
			case 'TotalPremium':
				return getPremiumContainer(quoteItem)
			case 'Cover':
				return getCoverContainer(value)
			case 'PolicyType':
				return getPolicyType()
			case 'Duration':
				return getDurationContainer(value)
			case 'Expiry':
				return getExpiryContainer(value)
			case 'Addons':
				return getAddonsContainer(value)
			case 'Conditions':
				return getConditionsContainer(quoteItem)
			case 'SolvencyRatio':
			case 'EstablishedOn':
			case 'ClaimsSettlementRatio':
				return getMetricsContainer(quoteItem, keyName)
			case 'Service':
				return getServiceContainer()
			case 'CCTV_01':
			case 'MEMORY_01':
				return getProductContainer(quoteItem, keyName)
			case 'HardwareUnits':
				return getHardwareUnitsContainer(quoteItem)
			default:
				return value?.toString()
		}
	}

	const infoRowOrderMap = isSafetyPlan ? safetyPlanInfoRowOrder : infoRowOrder

	const quoteItems = Object.entries(infoRowOrderMap).map((item, index) => {
		let itemKey = item[0]
		let cellHeight = item[1].height

		if (itemKey === 'Insurer') {
			return (
				<ImageContainer style={{ height: cellHeight }}>
					{getDynamicContent(itemKey, quote)}
				</ImageContainer>
			)
		} else {
			const disableCenterAlign = !['Conditions'].includes(itemKey)

			return (
				<ValueCell key={index} height={cellHeight} centerAlign={disableCenterAlign}>
					{getDynamicContent(itemKey, quote)}
				</ValueCell>
			)
		}
	})

	return <QuoteCardWrapper key={quote?.Insurer?.name}>{quoteItems}</QuoteCardWrapper>
}

export const getSumInsuredDisplayName = title => {
	switch (title) {
		case 'BuildingValue':
			return 'Building'
		case 'BasementValue':
			return 'Basement'
		case 'PlinthFoundation':
			return 'Plinth & Foundation'
		case 'BoundaryWalls':
			return 'Boundary Walls, etc.'
		case 'PlantMachinery':
			return 'Plant & Machinery'
		case 'FurnitureFixture':
			return 'Furniture & Fixture'
		case 'Electronics':
			return 'Electronic Installations'
		case 'OfficeEquipment':
			return 'Office Equipment (including IT systems)'
		case 'ValuableContents':
			return 'Valuable contents'
		case 'Other':
			return 'Other contents'
		case 'MaxValSingleItem':
			return 'Maximum value of a single content item'
		case 'Stock':
			return 'Stock'
		default:
			return title
	}
}

export const getAddOnLabel = value => {
	const mapping = {
		BURGLARY_THEFT: 'Burglary & Theft',
		CIS: 'Cash in Safe',
		CIC: 'Cash in Counter',
		CIT: 'Cash in Transit',
	}

	if (value && mapping[value]) {
		return mapping[value]
	}

	return value
}

export const addonStyleConfig = {
	Burglary: { icon: burglary, color: '#DDAB3A' },
	BURGLARY_THEFT: { icon: burglary_theft, color: '#4AA161' },
	CIS: { icon: cash_in_safe, color: '#2854E7' },
	CIT: { icon: cash_in_transit, color: '#4AA161' },
	CIC: { icon: cash_in_counter, color: '#4AA161' },
	'Plate Glass': { icon: plate_glass, color: '#D22D6F' },
	'Neon glass / sign board': { icon: neon_sign_board, color: '#02475E' },
	'Machinery breakdown & Damage': { icon: mbd, color: '#4498B5' },
	'Fire loss of profit': { icon: flop, color: '#9A5A41' },
	'Machinery loss of profit': { icon: mlop, color: '#6467B0' },
	'Electronic Equipment Insurance': { icon: electronic_equipments, color: '#7514F5' },
	'Fidelity & Larceny': { icon: fidelity_larceny, color: '#F78670' },
}

export const addonNames = {
	Burglary: 'Burglary',
	BURGLARY_THEFT: 'Burglary & Theft',
	CIS: 'Cash in Safe',
	CIT: 'Cash in Transit',
	CIC: 'Cash in Counter',
}

export default QuoteCard
