import { useState } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { Button, Divider, Input, PageContentAndNavbarWrapper, Space, Text, theme } from 'verak-ui'
import { useFetch } from '../api/apihooks'
import { useContextApi } from '../context/Provider'

import Verak from '../assets/Verak.svg'
import OTPInput from '../components/OTPInput'

const defaultOtpValues = ['', '', '', '', '', '']

const otpInputStyles = {
	width: '100%',
	height: '48px',
	textAlign: 'center',
	borderRadius: '0',
	border: '0',
	borderRight: '0.5px solid #C8D6DB',
	background: '#FBFBFB',
}

const Login = () => {
	const [state, dispatch] = useContextApi()
	const { getLoginOTP, getAuthToken } = useFetch()
	const history = useHistory()

	const [phoneNo, setPhoneNo] = useState('')
	// const [otp, setOtp] = useState('')
	const [error, setError] = useState('')
	const [otpSent, setOtpSent] = useState(false)
	const [intervalHandler, setIntervalHandler] = useState(null)
	const [timeLeft, setTimeLeft] = useState(120)

	const [otpValues, setOtpValues] = useState(defaultOtpValues)

	const handleOtpValueChangeAtIndex = (index, value) => {
		let newValues = [...otpValues]
		newValues[index] = value
		setOtpValues(newValues)
	}

	const handleAllOtpValuesChange = values => {
		setOtpValues(values)
	}

	const otp = otpValues?.join('')?.trim()

	const sendOtp = async () => {
		const phoneWithCode = `+91${phoneNo}`
		const result = await getLoginOTP(phoneWithCode)
		if (result.error) {
			setError(result.error)
		} else {
			if (error) setError('')
			const handler = setInterval(() => {
				setTimeLeft(value => {
					return value ? value - 1 : 0
				})
			}, 1000)
			setIntervalHandler(handler)
		}
		setOtpSent(true)
	}

	const resendOtp = async () => {
		// resend otp only after time (2 min) is finished
		if (timeLeft === 0) {
			setError('')
			await new Promise((resolve, reject) => {
				setOtpValues(defaultOtpValues)
				setTimeLeft(120) // reset time to resend OTP
				clearInterval(intervalHandler)
				setIntervalHandler(null)
				resolve()
			})
			sendOtp()
		}
	}

	const handlePhoneNoChange = value => {
		if (value?.length <= 10) {
			setPhoneNo(value)
		}
	}

	const handleOtpLogin = async () => {
		const phoneWithCode = `+91${phoneNo}`
		const result = await getAuthToken(phoneWithCode, otp)

		if (result.error) {
			setError(result.error)
		} else {
			sessionStorage.setItem('token', result.token)
			sessionStorage.setItem('user', JSON.stringify(result.data))
			await new Promise(resolve => {
				dispatch({
					type: 'LOGIN_USER',
					data: {
						token: result.token,
						data: result.data,
					},
				})
				resolve()
			})

			history.replace('/')
		}
	}

	const timer = new Date(timeLeft * 1000).toISOString().substr(14, 5)
	let resendCodeTimer = `Resend code in ${timer}`
	if (timeLeft === 0) {
		resendCodeTimer = 'Resend code'
	}

	const validPhone = phoneNo?.length === 10
	const validOtp = otp?.length === 6

	return (
		<PageContentAndNavbarWrapper>
			<LoginWrapper>
				<LoginForm>
					<Space y={1} />
					<img src={Verak} alt="Verak" />
					<Space y={1.5} mobileY={1} />
					<Text
						fontSize="1.25rem"
						mobileFontSize="1.125rem"
						fontWeight="700"
						color={theme.colors.primary}
					>
						Welcome
					</Text>
					<Divider
						top="1.5"
						bottom="1.5"
						style={{ width: '100%', border: '0.5px solid #02475E' }}
					/>
					<Text
						fontSize="1.125rem"
						mobileFontSize="1rem"
						color={theme.colors.primary}
						style={{ textAlign: 'center' }}
					>
						Please enter your registered mobile number for OTP verification to continue
					</Text>
					<Space y={1} mobileY={1.5} />
					<MobileNumInputContainer>
						<span>+91</span>
						<CustomInput
							type="number"
							placeholder="Mobile Number"
							value={phoneNo}
							onChange={e => handlePhoneNoChange(e.target.value)}
							inputContainerStyle={{ width: '100%' }}
						/>
					</MobileNumInputContainer>

					<Space y={1} />
					<OtpContainer className="d-flex">
						<OTPInput
							onValueChangeAtIndex={handleOtpValueChangeAtIndex}
							onAllValuesChange={handleAllOtpValuesChange} // in case of paste event
							values={otpValues}
							inputStyle={otpInputStyles}
							containerStyle={{ paddingLeft: '0.25rem' }}
						/>
						<OtpResendButton
							label={otpSent ? resendCodeTimer : 'Send OTP'}
							bgColor="#FBFBFB"
							textColor={theme.colors.primary}
							onClick={otpSent ? resendOtp : sendOtp}
							disabled={!otpSent ? !validPhone : !validPhone || timeLeft > 0}
							applyDisableFilter={!otpSent ? !validPhone : !validPhone || timeLeft > 0}
						/>
					</OtpContainer>

					<Space y={1} />
					<Button
						label="Verify OTP & Continue"
						onClick={handleOtpLogin}
						disabled={phoneNo.length !== 10 || otp.length !== 6}
					/>
					<Space y={0.5} />
					{error && <Text color={theme.colors.red}>{error}</Text>}
					<Space y={2} />
				</LoginForm>
				<Space y={1.5} />
				<Text style={{ textAlign: 'center' }} color={theme.colors.gray2}>
					&copy; {new Date().getFullYear()} Verak by Insure First Insurance Brokers LLP
				</Text>
			</LoginWrapper>
		</PageContentAndNavbarWrapper>
	)
}

const MobileNumInputContainer = styled.div`
	border: 0.5px solid #c8d6db;
	background: #fbfbfb;
	height: 100%;
	border-radius: 8px;
	height: 50px;
	width: min(360px, 100%);
	display: flex;
	align-items: center;
	/* padding-right: 0.25rem; */
	span {
		background: #fbfbfb;
		padding: 0 1rem;
		color: ${theme.colors.gray3};
	}
`

const CustomInput = styled(Input)`
	border: 0 !important;
	border-left: 0.5px solid #c8d6db !important;
	border-right: 0.5px solid #c8d6db !important;
	border-radius: 0 8px 8px 0;
	background: #fbfbfb;
	width: 100%;
`

const LoginWrapper = styled.div`
	width: 100%;
	height: calc(100vh - 52px);
	margin-top: -8px;
	background: linear-gradient(90.05deg, #c8d6db 0.06%, #fcebe3 92.1%);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 2rem 0;
	@media (max-width: 768px) {
		padding-left: 1rem;
		padding-right: 1rem;
	}
`

const LoginForm = styled.div`
	width: min(500px, 100%);
	/* height: min(500px, 100%); */
	background: white;
	border-radius: 10px;
	padding: 2rem;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	@media (max-width: 500px) {
		padding: 1rem;
	}
`

const OtpContainer = styled.div`
	background: #fbfbfb;
	border: 0.5px solid #c8d6db;
	border-radius: 8px;
	width: min(360px, 100%);
`

const OtpResendButton = styled(Button)`
	width: 400px;
	height: 48px;
	border-radius: 0 8px 8px 0;
	font-size: 12px;

	@media (max-width: 380px) {
		width: 100%;
		font-size: 10px;
	}
`

export default Login
