import { useState, useMemo, useRef } from 'react'
import styled from 'styled-components'
import {
	theme,
	Text,
	Icon,
	Modal,
	Tooltip,
	Button,
	Space,
	Divider,
	PageContentAndNavbarWrapper,
	SecondaryNavbar,
} from 'verak-ui'
import {
	// styles
	KeyCell,
	ActionBar,
	MenuContainer,
	MenuItemContainer,
	TableContainer,
	QuotesContainer,
	Sidebar,
	// utils
	infoRowOrder,
	safetyPlanInfoRowOrder,
} from './quoteComparisonUtils'
import { convertAmountToWords } from '../../../utilities/convertAmountToWords'
import { MainWrapper, PolicyTypeLabel } from '../../../components/SharedComponents'
import { OnDesktop, OnMobile } from '../../../components/HideViewports'
import QuoteCard, {
	getSumInsuredDisplayName,
	getAddOnLabel,
	addonStyleConfig,
	addonNames,
} from './QuoteCard'
import { useFetch } from '../../../api/apihooks'
import { useContextApi } from '../../../context/Provider'
import { useHistory } from 'react-router-dom'
import { getPolicyName } from '../../../utilities/getPolicyName'
import { useHelpModal } from '../../../context/HelpModalProvider'
import { burglarySIItems, calculateBurglarySI } from '../../../utilities/calculateBurglarySI'
import roundOffNum from '../../../utilities/roundOffNum'
import { getFormattedAmount } from '../../../utilities/getFormattedAmount'
import { useEffect } from 'react'

// filter options
// const typesOfCompanyOptions = [{ name: 'All Companies', value: 'ALL_COMPANIES' }]

// const preferredCompanyOptions = [{ name: 'NIL', value: 'NIL' }]

const sortOptions = [
	{ name: 'Price (High to Low)', value: 'HIGH_TO_LOW' },
	{ name: 'Price (Low to High)', value: 'LOW_TO_HIGH' },
	{ name: 'A-Z Insurers', value: 'SORT_INSURERS' },
]

export default function QuotesComparisonComponent() {
	const [selectedSort, setSelectedSort] = useState('HIGH_TO_LOW')
	const history = useHistory()

	const [state, dispatch] = useContextApi()
	const { showHelpModal } = useHelpModal()

	const currentRfqItem = state?.currentRfqItem
	const quotes = currentRfqItem?.quotes || []

	const [showSingleQuoteNotice, setShowSingleQuoteNotice] = useState(false)

	// desktop navbar
	const desktopLeftNav = (
		<Text type="body1" color={theme.colors.gray7} fontWeight="700">
			<div className="d-flex align-items-center">
				{getPolicyName(currentRfqItem.Tag)} ({currentRfqItem.policyIndex}) &nbsp;
				<PolicyTypeLabel>{currentRfqItem?.policyName || ''}</PolicyTypeLabel>
				<Icon name="rightArrow" color={theme.colors.primary} /> Policy Quotes
			</div>
		</Text>
	)

	const desktopRightNav = (
		<div className="d-flex align-items-center">
			<Text type="body1" color={theme.colors.gray7} fontWeight="700">
				Sum Insured:
			</Text>
			<Space x={0.3} />
			<Text type="body1" color={theme.colors.gray7} fontWeight="700" style={{ opacity: '.7' }}>
				{convertAmountToWords(`₹ ${currentRfqItem?.cover || 0}`)}
			</Text>
			<Space x={0.5} />
			<Button
				icon="helpAltIcon"
				iconColor={theme.colors.primary}
				iconSize="18px"
				iconStyles={{ marginRight: '.3rem' }}
				bgColor="white"
				textColor={theme.colors.primary}
				label="Help"
				onClick={() => showHelpModal(true)}
			/>
			<Space x={0.5} />
		</div>
	)

	// mobile navbar
	const mobileLeftNav1 = (
		<div className="d-flex justify-content-center w-100">
			<Text color={theme.colors.primary} fontSize="18px" fontWeight="700">
				Policy Quote
			</Text>
		</div>
	)

	const mobileLeftNav2 = (
		<div className="d-flex align-items-center">
			<div className="d-flex flex-column">
				<Text color={theme.colors.secondary4} fontSize="10px">
					Sum Insured
				</Text>
				<Text color={theme.colors.white} fontSize="18px" fontWeight="700">
					{convertAmountToWords(`₹ ${currentRfqItem?.cover || 0}`)}
				</Text>
			</div>
			<Space x={1} />
			<PolicyTypeLabel>{currentRfqItem?.policyName || ''}</PolicyTypeLabel>
		</div>
	)

	const applyFilters = (quotesArr, filters) => {
		let quotes = []
		const { sortFilter } = filters

		let quotesWithPremium = quotesArr.filter(item => !!item.TotalPremium)
		let quotesWithoutPremium = quotesArr.filter(item => !item.TotalPremium)
		if (sortFilter === 'HIGH_TO_LOW') {
			let sortedQuotes = quotesWithPremium.sort((a, b) => b.TotalPremium - a.TotalPremium)
			quotes = [...sortedQuotes, ...quotesWithoutPremium]
		} else if (sortFilter === 'LOW_TO_HIGH') {
			let sortedQuotes = quotesWithPremium.sort((a, b) => a.TotalPremium - b.TotalPremium)
			quotes = [...sortedQuotes, ...quotesWithoutPremium]
		} else {
			quotes = quotesArr.sort((a, b) =>
				a.Insurer.toUpperCase() < b.Insurer.toUpperCase() ? -1 : 1
			)
		}
		// continue filters further
		return quotes
	}

	let quotesList = useMemo(
		() => applyFilters(quotes, { sortFilter: selectedSort }),
		[selectedSort, quotes]
	)

	let compareTableSection
	if (!showSingleQuoteNotice) {
		compareTableSection = (
			<>
				<ActionBar className="w-100 d-flex justify-content-between align-items-center">
					<div className="d-flex justify-content-between align-items-center">
						<FilterSelect
							selectedOption={selectedSort}
							onChange={selected => setSelectedSort(selected)}
							label="Sort by"
							options={sortOptions}
						/>
					</div>
				</ActionBar>

				<QuotesCompareTable quotes={quotesList} currentRfqItem={currentRfqItem} />
			</>
		)
	}

	const navigateBack = () => {
		history.push('/')
	}
	return (
		<PageContentAndNavbarWrapper
			style={{ background: '#f5f5f5' }}
			className="d-flex flex-column align-items-center"
			// style={{ height: '100%' }}
		>
			{/* main navbar */}
			<OnDesktop>
				<SecondaryNavbar
					headerBg={theme.colors.secondary2}
					navbarNumber={1}
					leftContent={desktopLeftNav}
					rightContent={desktopRightNav}
					onGoBack={navigateBack}
				/>
			</OnDesktop>
			<OnMobile>
				<SecondaryNavbar
					headerBg={theme.colors.white}
					navbarNumber={1}
					leftContent={mobileLeftNav1}
					// rightContent={mobileRightNav1}
				/>
				<SecondaryNavbar
					showGoBack={false}
					headerBg={theme.colors.primary}
					navbarNumber={2}
					leftContent={mobileLeftNav2}
				/>
			</OnMobile>
			{compareTableSection}
		</PageContentAndNavbarWrapper>
	)
}

const QuotesCompareTable = ({ quotes, currentRfqItem }) => {
	const initModalContent = { header: '', content: '' }
	const [showContentModal, setShowContentModal] = useState(false)
	const [modalContent, setModalContent] = useState(initModalContent)
	const [showSIBreakupModal, setShowSIBreakupModal] = useState(false)
	const [showAddonBreakupModal, setShowAddonBreakupModal] = useState(false)
	const [activeQuoteId, setActiveQuoteId] = useState('')

	const { sendRequest, getQuotes, purchaseJourneyPing, getHardwareProducts } = useFetch()
	const history = useHistory()
	const [state, dispatch] = useContextApi()

	const fetchHardwareProducts = async () => {
		const result = await getHardwareProducts()
		await new Promise(resolve => {
			dispatch({
				type: 'SET_HARDWARE_PRODUCTS',
				data: result,
			})
			resolve()
		})
	}

	useEffect(() => {
		if (state?.hardwareProducts.length === 0) {
			fetchHardwareProducts()
		}
	}, [])

	const rfqId = currentRfqItem.id

	const heightInfo = useRef(null)

	const selectQuote = async quoteId => {
		const selectQuoteResult = await sendRequest(`client/rfqs/${rfqId}/quotes/${quoteId}`, 'PUT')

		const quotes = await getQuotes(rfqId)
		await new Promise(resolve => {
			dispatch({ type: 'SET_RFQ_QUOTES_AND_DATA', data: quotes })
			dispatch({ type: 'SET_SELECTED_QUOTE' })
			resolve()
		})

		if (selectQuoteResult) {
			history.push('/purchase-policy/customer-info')
		}
	}

	const showInfoModal = ({ type, content }) => {
		let modalContent = {
			header: '',
			content: content,
		}
		switch (type) {
			case 'CONDITIONS':
				modalContent.header = 'Special conditions'
				break
			default:
				break
		}
		setShowContentModal(true)
		setModalContent(modalContent)
	}

	const isSafetyPlan = state?.currentRfqItem?.Tag === 'SAFETY_PLAN'
	const infoRowOrderMap = isSafetyPlan ? safetyPlanInfoRowOrder : infoRowOrder

	const sidebarContents = Object.entries(infoRowOrderMap).map((item, index) => {
		let { label, subLabel, height, moreInfo } = item[1]

		if (heightInfo?.current) {
			if (heightInfo?.current[item[0]]) {
				height = `${heightInfo?.current[item[0]]}px`
			}
		}
		return (
			<KeyCell key={item[0]} height={height}>
				<div className="d-flex justify-content-between">
					<div>
						{label}
						<Text color={theme.colors.gray3}>{subLabel}</Text>
					</div>
					{moreInfo && (
						<Tooltip html={moreInfo}>
							<Icon name="infoIcon" color={theme.colors.black} style={{ marginLeft: '0.5rem' }} />
						</Tooltip>
					)}
				</div>
			</KeyCell>
		)
	})

	const setHeightInfo = values => {
		if (heightInfo.current) {
			heightInfo.current = {
				Conditions:
					values.Conditions > heightInfo.current.Conditions
						? values.Conditions
						: heightInfo.current.Conditions,
				Addons:
					values.Addons > heightInfo.current.Addons ? values.Addons : heightInfo.current.Addons,
				SIForAddons:
					values.SIForAddons > heightInfo.current.SIForAddons
						? values.SIForAddons
						: heightInfo.current.SIForAddons,
			}
		} else {
			heightInfo.current = { ...values }
		}
	}

	const onToggleSIBreakupModal = () => setShowSIBreakupModal(p => !p)

	const onToggleAddOnSIBreakup = quoteId => {
		setShowAddonBreakupModal(!showAddonBreakupModal)
		setActiveQuoteId(showAddonBreakupModal ? '' : quoteId)
	}

	const onBuyClick = quote => {
		selectQuote(quote.id)
		purchaseJourneyPing(rfqId, 'QUOTE_SELECTED', quote.Insurer)
	}

	let allQuotes = []
	if (state?.hardwareProducts?.length > 0 && quotes.length > 0) {
		if (isSafetyPlan) {
			quotes = quotes.filter(q => q.Selected)
		}
		allQuotes = quotes.map(q => {
			if (state?.hardwareProducts?.length > 0) {
				q.Products = q?.HardwareProducts?.map(h => {
					const { Manufacturer, Model, Image, Features, Key } = state?.hardwareProducts?.find(
						hp => hp.Key === h.Key
					)
					return {
						Units: q.Units,
						Manufacturer,
						Model,
						Image,
						Features,
						Key,
					}
				})
			}
			return q
		})
	}

	const quoteCards =
		allQuotes?.map(quote => (
			<QuoteCard
				key={quote.id}
				quote={quote}
				allQuotes={quotes}
				SumInsuredBreakdown={currentRfqItem?.SumInsuredBreakdown}
				onTermsClick={showInfoModal}
				cover={currentRfqItem.cover}
				userOptedAddons={currentRfqItem?.PolicyAddons || []}
				onBuyClick={() => onBuyClick(quote)}
				onLoadComplete={setHeightInfo}
				heightInfo={heightInfo.current}
				onToggleSIBreakupModal={onToggleSIBreakupModal}
				onToggleAddOnSIBreakup={onToggleAddOnSIBreakup}
				isSafetyPlan={isSafetyPlan}
			/>
		)) || []

	if (allQuotes.length > 0) {
		return (
			<>
				<TableContainer className="mb-3">
					<Sidebar>{sidebarContents}</Sidebar>
					<QuotesContainer>{quoteCards}</QuotesContainer>
				</TableContainer>
				<InfoModal
					show={showContentModal}
					handleClose={() => {
						setShowContentModal(false)
						setModalContent(initModalContent)
					}}
					modalContent={modalContent}
				/>
				<SIBreakupModal
					show={showSIBreakupModal}
					handleClose={() => setShowSIBreakupModal(false)}
					sumInsuredBreakdown={currentRfqItem?.SumInsuredBreakdown}
					totalSumInsured={currentRfqItem?.cover}
					quoteItem={quotes[0]}
				/>
				<AddonBreakupModal
					show={showAddonBreakupModal}
					handleClose={() => setShowAddonBreakupModal(false)}
					quotes={quotes}
					activeQuoteId={activeQuoteId}
					sumInsuredBreakdown={currentRfqItem?.SumInsuredBreakdown}
				/>
			</>
		)
	} else {
		return null
	}
}

const SIBreakupModal = ({ show, handleClose, sumInsuredBreakdown, totalSumInsured, quoteItem }) => {
	let insurerResponseConfig = {
		text: { content: 'Proposal accepted', color: theme.colors.primary },
		icon: { name: 'tickCircle', color: theme.colors.green },
	}

	if (quoteItem?.Conditions || quoteItem?.ReducedSumInsured) {
		if (quoteItem.Conditions?.length > 0) {
			insurerResponseConfig = {
				text: {
					content: (
						<>
							Proposal has been <span style={{ color: '#75CC1F' }}>accepted</span> with{' '}
							<span style={{ color: '#F2994A' }}>special conditions</span>
						</>
					),
					color: '#8D8D8D',
				},
				icon: { name: 'squareTick', color: theme.colors.green },
			}
		} else if (quoteItem?.ReducedSumInsured) {
			insurerResponseConfig = {
				text: { content: `Total Sum Insured has been reduced`, color: '#2F80ED' },
				icon: { name: 'lockInSquare', color: '#2F80ED' },
			}
		}
	}

	const sumInsuredBreakupItems = Object.entries(sumInsuredBreakdown).map(([key, value]) => {
		return (
			<div className="col-md-6 mt-1 p-1" key={key}>
				<Text fontSize="14px" fontWeight="500" color={theme.colors.gray3}>
					{getSumInsuredDisplayName(key)}
				</Text>
				<Text fontSize="18px" fontWeight="600" color="#333">
					{convertAmountToWords(`₹ ${value}`)}
				</Text>
			</div>
		)
	})

	return (
		<Modal
			title={
				<Text fontSize="14px" fontWeight="700" color={theme.colors.primary}>
					TOTAL SUM INSURED BREAKUP
				</Text>
			}
			show={show}
			handleClose={handleClose}
			closeButton={true}
			footer={<></>}
		>
			<div className="p-2">
				<div className="d-flex align">
					<Icon
						name={insurerResponseConfig?.icon.name}
						color={insurerResponseConfig?.icon?.color}
					/>
					<Text
						className="mx-2"
						color={insurerResponseConfig?.text?.color}
						fontSize="14px"
						fontWeight="700"
					>
						{insurerResponseConfig?.text?.content}
					</Text>
				</div>
				<div className="mt-2">
					<Text fontSize="14px" fontWeight="500" color={theme.colors.gray3}>
						Total Sum Insured
					</Text>
					<Text fontSize="20px" fontWeight="800" color="#333333">
						{convertAmountToWords(`₹ ${totalSumInsured}`)}
					</Text>
				</div>
				<div className="row mx-3">{sumInsuredBreakupItems}</div>
			</div>
		</Modal>
	)
}

const AddonBreakupModal = ({ show, handleClose, sumInsuredBreakdown, quotes, activeQuoteId }) => {
	const currentQuote = quotes.find(quote => quote.id === activeQuoteId)
	const allAddons = currentQuote?.Addons.map((addon, index) => {
		const isAddonRejected = addon?.Rejected === true
		if (isAddonRejected) return null

		const addonName = addon?.Name
		const addonLabel = getAddOnLabel(addonName)
		const isLast = index === currentQuote?.Addons?.length - 1
		const isBurglary = addonName === 'Burglary' || addonName === 'BURGLARY_THEFT'

		let sumInsuredBreakupItems = []
		if (isBurglary) {
			Object.entries(sumInsuredBreakdown).forEach(([key, value]) => {
				if (!burglarySIItems.includes(key)) {
					return
				}
				sumInsuredBreakupItems.push(
					<div className="col-md-6 mt-1 p-1" key={key}>
						<Text fontSize="14px" fontWeight="500" color={theme.colors.gray3}>
							{getSumInsuredDisplayName(key)}
						</Text>
						<Text fontSize="18px" fontWeight="600" color="#333">
							{convertAmountToWords(`₹ ${value}`)}
						</Text>
					</div>
				)
			})
		}

		let addonSumInsured = addon?.SumInsured
		if (isBurglary) addonSumInsured = calculateBurglarySI(sumInsuredBreakdown)

		return (
			<div key={index}>
				<div className="my-2 d-flex flex-column align-items-start">
					<div className="d-flex align-items-start">
						<img src={addonStyleConfig[addonName]?.icon} alt={addonName} />
						<Text
							className="mx-1 text-left"
							color={addonStyleConfig[addonName]?.color}
							fontWeight="700"
							fontSize="16px"
						>
							{addonNames[addonLabel] || addonLabel?.toUpperCase()}
						</Text>
					</div>
					{addonSumInsured && (
						<Text
							className="d-flex mx-1 align-items-center"
							fontSize="20px"
							fontWeight="700"
							color={theme.colors.primary}
						>
							Insured - {convertAmountToWords(`₹ ${roundOffNum(addonSumInsured || 0)}`)}{' '}
							<Text className="mx-1" fontSize="12px" fontWeight="500" color="rgba(0,0,0,0.5)">
								(Included in total Sum Insured)
							</Text>
						</Text>
					)}
					<Text className="mx-1" fontSize="16px" fontWeight="500" color={theme.colors.gray3}>
						Premium - {getFormattedAmount(`₹ ${roundOffNum(addon?.Amount || 0)}`)}*/year
					</Text>
				</div>
				{isBurglary && <div className="row mx-3">{sumInsuredBreakupItems}</div>}
				{!isLast && <Divider />}
			</div>
		)
	})

	return (
		<Modal
			title={
				<Text fontSize="14px" fontWeight="700" color={theme.colors.primary}>
					ADD ON DETAILS
				</Text>
			}
			show={show}
			handleClose={handleClose}
			closeButton={true}
			footer={<></>}
		>
			<div className="p-2">{allAddons}</div>
		</Modal>
	)
}

const InfoModal = ({ show, handleClose, modalContent }) => {
	return (
		<Modal
			title={
				<Text type="h5" fontWeight="bold" color={theme.colors.primary}>
					{modalContent.header}
				</Text>
			}
			show={show}
			handleClose={handleClose}
			footer={<></>}
		>
			<div className="text-left">
				<Text fontSize="18px" fontWeight="400" color={theme.colors.gray3}>
					{modalContent.content}
				</Text>
			</div>
		</Modal>
	)
}

const FilterSelect = ({ label, options, selectedOption, onChange, spacing, disabled }) => {
	const optionsMap = options.map(item => {
		return (
			<MenuItemContainer onClick={() => onChange(item.value)} key={item.value}>
				{item.name}
			</MenuItemContainer>
		)
	})

	const selectedOptionString = options.find(item => item.value === selectedOption)?.name

	return (
		<div className={`d-flex flex-column ${spacing}`}>
			<Text fontSize="14px" fontWeight="400" color={theme.colors.gray3}>
				{label}
			</Text>
			<Tooltip
				trigger="click"
				contentStyle={{ padding: '0' }}
				theme="light"
				position="bottom"
				html={<MenuContainer>{optionsMap}</MenuContainer>}
				className="d-flex align-items-center cursor-pointer"
			>
				<Text
					style={{ cursor: 'pointer' }}
					className="d-flex align-items-center"
					fontSize="16px"
					fontWeight="400"
					color={theme.colors.black}
				>
					{selectedOptionString}
					{!disabled && (
						<Icon
							name="rightArrow"
							color={theme.colors.black}
							style={{ transform: 'rotate(90deg)' }}
						/>
					)}
				</Text>
			</Tooltip>
		</div>
	)
}

const RectangleIcon = styled.img`
	height: 200px;
	width: 200px;
	@media (max-width: 768px) {
		width: 120px;
		height: 120px;
	}
`

const CustomPageWrapper = styled(MainWrapper)`
	width: 90%;
	@media (max-width: 768px) {
		display: flex;
		align-items: center;
		flex-direction: column;
		background: white;
		width: 100%;
		height: 100%;
		margin-top: 0px;
	}
`
