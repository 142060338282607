import { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import {
	PageContentAndNavbarWrapper,
	theme,
	SecondaryNavbar,
	Button,
	Text,
	Icon,
	Divider,
	Space,
} from 'verak-ui'
import styled from 'styled-components'

import { OnDesktop, OnMobile } from '../../components/HideViewports'
import { MainWrapper, PolicyTypeLabel } from '../../components/SharedComponents'
import { useFetch } from '../../api/apihooks'
import { useContextApi } from '../../context/Provider'
import { getPolicyName } from '../../utilities/getPolicyName'

import PaymentRedirect from '../../assets/images/paymentRedirect.gif'
import SuccessGif from '../../assets/images/paymentSuccess.gif'
import FailureGif from '../../assets/images/paymentFailure.gif'
import calculateTotalPremium from '../../utilities/calculateTotalPremium'
import { getFormattedAmount } from '../../utilities/getFormattedAmount'
import TimeoutGif from '../../assets/images/paymentTimeout.gif'
import { getPolicyTypeFullForm } from '../../utilities/getPolicyType'

export default function OnlinePayment() {
	const location = useLocation()
	// valid states -
	// 1. loading status - loading
	// 1. payment success - success
	// 1. payment failed - failure
	const [activeScreen, setActiveScreen] = useState('loading')
	const [wasInFailedState, setInFailedState] = useState(() => {
		if (location?.state?.wasPaymentFailed === true) {
			return true
		}
		return false
	})
	const [paymentDetails, setPaymentDetails] = useState({
		Status: '',
		Link: '',
		TxId: '',
		Insurer: '',
	})

	const history = useHistory()
	const [state, dispatch] = useContextApi()
	const currentRfqItem = state?.currentRfqItem

	const goBack = () => history.goBack()
	const { getPaymentStatus } = useFetch()

	const [countDown, setCountDown] = useState(60 * 5)
	const [runTimer, setRunTimer] = useState(true)

	const checkPaymentStatus = async () => {
		const result = await getPaymentStatus(currentRfqItem.id)
		if (!result) {
			return
		}
		setPaymentDetails(result)
		if (result.Status == 'FAILED' || result.Status == 'PENDING_SETTLEMENT') {
			if (result.Status == 'FAILED' && !wasInFailedState) {
				setActiveScreen('failure')
				setInFailedState(true)
				setRunTimer(false)
			} else if (result.Status == 'PENDING_SETTLEMENT') {
				setActiveScreen('success')
				setRunTimer(false)
			}
		}
	}

	useEffect(() => {
		let timerId
		if (runTimer) {
			setCountDown(60 * 5)
			timerId = setInterval(() => {
				setCountDown(countDown => {
					if (countDown % 30 == 0) {
						checkPaymentStatus()
					}
					return countDown - 1
				})
			}, 1000)
		} else {
			clearInterval(timerId)
		}

		return () => clearInterval(timerId)
	}, [runTimer])

	useEffect(() => {
		if (countDown < 0 && runTimer) {
			setRunTimer(false)
			setCountDown(0)
			setActiveScreen('timeout')
		}
	}, [countDown])

	const retryPayment = () => {
		let paymentLink = paymentDetails?.Link || ''

		if (paymentLink) {
			window.open(paymentLink, '_blank', 'noopener,noreferrer')
			setActiveScreen('loading')
			setRunTimer(true)
		}
	}

	const proceedToHome = async () => {
		await new Promise(resolve => {
			dispatch({
				type: 'CLEAR_RFQS',
			})
			resolve()
		})
		history.replace('/')
	}

	const policyType = currentRfqItem?.policyName || '' // BLUS, BSUS

	const desktopLeftNav = (
		<Text type="body1" color={theme.colors.gray7} fontWeight="700">
			<div className="d-flex align-items-center">
				{getPolicyName(currentRfqItem.Tag)}({currentRfqItem.policyIndex}) &nbsp;
				<PolicyTypeLabel>{policyType}</PolicyTypeLabel>
				<Icon name="rightArrow" color={theme.colors.primary} /> Purchase
			</div>
		</Text>
	)

	const mobileLeftNav = (
		<div className="d-flex justify-content-center w-100">
			<Text
				style={{ marginLeft: '2rem' }}
				color={theme.colors.primary}
				fontWeight="700"
				fontSize="18px"
			>
				Purchase
			</Text>
		</div>
	)
	const mobileRightNav = (
		<Button
			icon="helpAltIcon"
			iconColor={theme.colors.primary}
			iconSize="18px"
			iconStyles={{ marginRight: '.3rem' }}
			bgColor="white"
			style={{ opacity: '.5' }}
			textColor={theme.colors.primary}
			label="Help"
		/>
	)

	const getPaymentStatusTag = () => {
		if (activeScreen === 'success') {
			return 'Payment Received, Insurer confirmation pending!'
		}
		return 'Payment Failed, please retry!'
	}

	const insurerName = currentRfqItem?.selectedQuote?.Insurer || ''

	let totalPremiumInWords = ''
	const totalPremium = calculateTotalPremium(currentRfqItem?.selectedQuote || {})
	if (totalPremium) {
		totalPremiumInWords = getFormattedAmount(totalPremium)
	}

	const transactionDetails = (
		<div style={{ width: 'min(550px, 100%)' }}>
			<Text fontWeight="700" color={theme.colors.primary} className="mb-2">
				TRANSACTION DETAILS
			</Text>
			<TransactionDetailsContainer>
				<TxnDetailsLabel>PAYMENT STATUS: </TxnDetailsLabel>
				<PaymentStatusTag success={activeScreen === 'success'}>
					{getPaymentStatusTag()}
				</PaymentStatusTag>

				<TxnDetailsLabel>PREMIUM AMOUNT: </TxnDetailsLabel>
				<TxnDetailsData>₹{totalPremiumInWords}</TxnDetailsData>

				{/* <TxnDetailsLabel>DATE OF TRANSACTION: </TxnDetailsLabel>
				<TxnDetailsData>{'DD / MM / YYYY'}</TxnDetailsData> */}

				<TxnDetailsLabel>TRANSACTION ID: </TxnDetailsLabel>
				<TxnDetailsData>{paymentDetails?.TxId || ''}</TxnDetailsData>

				{activeScreen === 'success' && (
					<>
						<TxnDetailsLabel>TYPE OF POLICY: </TxnDetailsLabel>
						<div>
							<TxnDetailsData>{getPolicyName(currentRfqItem?.Tag) || ''}</TxnDetailsData>
							<TxnDetailsData style={{ color: theme.colors.secondary }}>
								{`${getPolicyTypeFullForm(policyType)} (${policyType})`}
							</TxnDetailsData>
						</div>

						<TxnDetailsLabel>INSURER DETAILS: </TxnDetailsLabel>
						<TxnDetailsData>{paymentDetails?.Insurer || ''}</TxnDetailsData>
					</>
				)}
			</TransactionDetailsContainer>
		</div>
	)

	let activeSection
	if (activeScreen === 'success') {
		activeSection = (
			<div className="d-flex flex-column align-items-center">
				<Text
					color={theme.colors.green}
					fontSize="24px"
					mobileFontSize="24px"
					fontWeight="700"
					className="mb-3"
				>
					Payment Successful
				</Text>
				<Text
					fontSize="20px"
					mobileFontSize="18px"
					fontWeight="700"
					className="mb-3"
					style={{ textAlign: 'center' }}
				>
					We will now follow up with <b>{insurerName}</b> to{' '}
					<span style={{ color: theme.colors.green }}>
						confirm your payment receipt and notify you within 72hrs
					</span>
				</Text>

				<Space y={3} mobileY={1} />

				<div className="d-flex align-items-center justify-content-around w-100">
					{transactionDetails}
					<OnDesktop style={{ width: 'auto' }}>
						<GifView style={{ width: '320px', height: 'auto' }} src={SuccessGif} alt="success" />
					</OnDesktop>
				</div>

				<OnDesktop className="mt-2 w-100">
					<div className="d-flex w-100 justify-content-center">
						<Button
							style={{ width: '230px', height: '48px' }}
							label="Proceed"
							onClick={proceedToHome}
						/>
					</div>
				</OnDesktop>
				<OnMobile className="mt-4">
					<Divider className="my-4" />
					<div className="w-100 d-flex justify-content-between">
						<Button
							onClick={goBack}
							icon="leftArrow"
							bgColor={theme.colors.secondary2}
							textColor={theme.colors.primary}
							style={{ height: '40px', width: '110px' }}
							label="Back"
						/>
						<Button
							onClick={proceedToHome}
							style={{ height: '40px', marginLeft: '1rem', width: '130px' }}
							label="Proceed"
							icon="rightArrow"
							iconAlign="right"
						/>
					</div>
				</OnMobile>
			</div>
		)
	} else if (activeScreen === 'failure') {
		activeSection = (
			<div className="d-flex flex-column align-items-center">
				<Text
					color={theme.colors.red}
					fontSize="24px"
					mobileFontSize="24px"
					fontWeight="700"
					className="mb-3"
				>
					Payment Failed
				</Text>
				{/* <Text
					color={theme.colors.red}
					fontSize="20px"
					mobileFontSize="18px"
					fontWeight="700"
					className="mb-3 p-2"
					style={{ textAlign: 'center', border: '1px solid #B3B3B3', borderRadius: '6px' }}
				>
					{'- Failure reason -'}
				</Text> */}

				<Space y={3} mobileY={1} />

				<div className="d-flex align-items-center justify-content-around w-100">
					{transactionDetails}
					<OnDesktop style={{ width: 'auto' }}>
						<GifView
							style={{ width: '320px', height: 'auto' }}
							src={FailureGif}
							alt="payment failure"
						/>
					</OnDesktop>
				</div>
				<OnDesktop className="mt-2 w-100">
					<div className="d-flex w-100 justify-content-center align-items-center">
						<Button
							onClick={goBack}
							icon="leftArrow"
							bgColor={theme.colors.secondary2}
							textColor={theme.colors.primary}
							style={{ height: '48px', width: '108px' }}
							label="Back"
						/>
						<Space x={1} />
						<Button
							onClick={retryPayment}
							style={{ width: '230px', height: '48px', border: `1px solid #4AA161` }}
							textColor="#4AA161"
							bgColor={theme.colors.secondaryLightGreen}
							iconColor="#4AA161"
							iconStyles={{ marginLeft: '.3rem' }}
							label="Retry Payment"
							icon="renewPolicy"
							iconAlign="right"
							disabled={!paymentDetails?.Link}
							applyDisableFilter={!paymentDetails?.Link}
						/>
					</div>
				</OnDesktop>
				<OnMobile className="mt-4">
					<Divider className="my-4" />
					<div className="w-100 d-flex justify-content-between">
						<Button
							onClick={goBack}
							// icon="leftArrow"
							bgColor={theme.colors.secondary2}
							textColor={theme.colors.primary}
							style={{ height: '40px', width: '66px' }}
							label="Back"
						/>
						<Button
							onClick={retryPayment}
							textColor="#4AA161"
							bgColor={theme.colors.secondaryLightGreen}
							iconColor="#4AA161"
							iconStyles={{ marginLeft: '.3rem' }}
							style={{
								height: '40px',
								marginLeft: '1rem',
								width: '180px',
								border: `1px solid #4AA161`,
							}}
							label="Retry Payment"
							icon="renewPolicy"
							iconAlign="right"
							disabled={!paymentDetails?.Link}
							applyDisableFilter={!paymentDetails?.Link}
						/>
					</div>
				</OnMobile>
			</div>
		)
	} else if (activeScreen === 'timeout') {
		activeSection = (
			<div className="d-flex flex-column align-items-center">
				<Text
					color={theme.colors.red}
					fontSize="24px"
					mobileFontSize="24px"
					fontWeight="700"
					className="mb-3"
				>
					Oops! Screen Time Out
				</Text>
				<Text
					color={theme.colors.green}
					fontSize="20px"
					mobileFontSize="16px"
					fontWeight="700"
					className="mb-3 p-3"
					style={{
						textAlign: 'center',
						background: theme.colors.secondaryLightGreen,
						border: `1px solid #4AA161`,
						borderRadius: '8px',
					}}
				>
					Let's give this another shot! Retry payment
				</Text>

				<Space y={1} />

				<GifView
					style={{ width: '300px', height: 'auto' }}
					src={TimeoutGif}
					alt="payment request timeout"
				/>

				<Space y={1} />

				<OnDesktop className="mt-2 w-100">
					<div className="d-flex w-100 justify-content-center align-items-center">
						<Button
							onClick={goBack}
							icon="leftArrow"
							bgColor={theme.colors.secondary2}
							textColor={theme.colors.primary}
							style={{ height: '48px', width: '108px' }}
							label="Back"
						/>
						<Space x={1} />
						<Button
							onClick={retryPayment}
							style={{ width: '230px', height: '48px', border: `1px solid #4AA161` }}
							textColor="#4AA161"
							bgColor={theme.colors.secondaryLightGreen}
							iconColor="#4AA161"
							iconStyles={{ marginLeft: '.3rem' }}
							label="Retry Payment"
							icon="renewPolicy"
							iconAlign="right"
							disabled={!paymentDetails?.Link}
							applyDisableFilter={!paymentDetails?.Link}
						/>
					</div>
				</OnDesktop>
				<OnMobile className="mt-4">
					<Divider className="my-4" />
					<div className="w-100 d-flex justify-content-between">
						<Button
							onClick={goBack}
							// icon="leftArrow"
							bgColor={theme.colors.secondary2}
							textColor={theme.colors.primary}
							style={{ height: '40px', width: '66px' }}
							label="Back"
						/>
						<Button
							onClick={retryPayment}
							textColor="#4AA161"
							bgColor={theme.colors.secondaryLightGreen}
							iconColor="#4AA161"
							iconStyles={{ marginLeft: '.3rem' }}
							style={{
								height: '40px',
								marginLeft: '1rem',
								width: '180px',
								border: `1px solid #4AA161`,
							}}
							label="Retry Payment"
							icon="renewPolicy"
							iconAlign="right"
							disabled={!paymentDetails?.Link}
							applyDisableFilter={!paymentDetails?.Link}
						/>
					</div>
				</OnMobile>
			</div>
		)
	} else {
		const seconds = String(countDown % 60).padStart(2, 0)
		const minutes = String(Math.floor(countDown / 60)).padStart(2, 0)

		activeSection = (
			<div className="d-flex flex-column align-items-center">
				<OnDesktop>
					<Space y={3} />
				</OnDesktop>
				<GifView src={PaymentRedirect} alt="success" />
				<Text fontSize="24px" fontWeight="400">
					Screen timeout in : {minutes}:{seconds}
				</Text>
				<Text className="mt-1" fontSize="24px" fontWeight="400">
					Transaction in progress,{' '}
					<span style={{ color: theme.colors.red }}>please do not close this tab or exit</span>
				</Text>
				<OnDesktop>
					<Space y={5} />
				</OnDesktop>
			</div>
		)
	}

	return (
		<PageContentAndNavbarWrapper>
			<OnDesktop>
				<SecondaryNavbar
					leftContent={desktopLeftNav}
					// rightContent={mobileRightNav}
					navbarNumber={1}
					showGoBack={false}
					headerBg={theme.colors.secondary2}
				/>
			</OnDesktop>
			<OnMobile>
				<SecondaryNavbar
					leftContent={mobileLeftNav}
					// rightContent={mobileRightNav}
					navbarNumber={1}
					showGoBack={true}
					onGoBack={goBack}
					headerBg={theme.colors.white}
				/>
			</OnMobile>
			<CustomWrapper>{activeSection}</CustomWrapper>
		</PageContentAndNavbarWrapper>
	)
}

const CustomWrapper = styled(MainWrapper)`
	width: 90%;
	text-align: inherit !important;
	@media (max-width: 768px) {
		display: flex;
		align-items: center;
		flex-direction: column;
		background: white;
		width: 100%;
		height: 100%;
		margin-top: 0px;
		margin: 0;
	}
`

const GifView = styled.img`
	height: 410px;
	width: 450px;
	@media (max-width: 768px) {
		height: 320px;
		width: 320px;
	}
`

const TransactionDetailsContainer = styled.div`
	padding: 0.5rem;
	display: grid;
	grid-template-columns: 0.5fr 1fr;
	row-gap: 0.5rem;
	align-items: center;
	border: 1px solid #c8d6db;
	border-radius: 6px;
	@media (max-width: 768px) {
		grid-template-columns: 1fr;
	}
`

const PaymentStatusTag = styled.div`
	padding: 5px 8px;
	color: ${p => (p.success ? '#7E7422' : '#DC2F21')};
	background-color: ${p => (p.success ? '#FDF27C' : '#F5DBDB')};
	border: 1px solid ${p => (p.success ? '#C9BB3C' : '#E29896')};
	border-radius: 6px;
	font-size: 14px;
	/* width: clamp(100px, 100%, 350px); */
	width: max-content;
`

const TxnDetailsLabel = styled(Text)`
	font-size: 14px;
	font-weight: 700;
	color: ${theme.colors.primary};
	opacity: 0.4;
`

const TxnDetailsData = styled(Text)`
	font-size: 14px;
	font-weight: 700;
	color: ${theme.colors.primary};
`
