const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
const prependZero = num => (num < 10 ? `0${num}` : num)

// returns date string like
// 15 Aug 2021, 5:22 pm
export const getDateString = ISOString => {
	const date = new Date(ISOString)

	const hours = date.getHours() > 12 ? date.getHours() - 12 : date.getHours()

	const AMPM = date.getHours() >= 12 ? 'pm' : 'am'

	return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}, ${prependZero(
		hours
	)}:${prependZero(date.getMinutes())} ${AMPM}`
}

export const getDiffYears = date => {
	var ageDifMs = new Date(new Date().getFullYear(), 0, 1) - new Date(date, 0, 1)
	var ageDate = new Date(ageDifMs) // miliseconds from epoch
	return Math.abs(ageDate.getUTCFullYear() - 1970)
	return 3
}
