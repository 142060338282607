import {
	PageContentAndNavbarWrapper,
	SecondaryNavbar,
	theme,
	Text,
	Button,
	Checkbox,
	Icon,
	Space,
} from 'verak-ui'
import styled, { keyframes } from 'styled-components'
import { MainWrapper, PolicyTypeLabel } from '../../components/SharedComponents'
import { OnDesktop, OnMobile } from '../../components/HideViewports'
import confirmationLottie from '../../assets/images/confirmationLottie.svg'
import { useContextApi } from '../../context/Provider'
import { getPolicyName } from '../../utilities/getPolicyName'
import { useState } from 'react'
import { useHistory } from 'react-router'
import { getRMPhoneNumber } from '../../utilities/getRMPhoneNumber'
import { useHelpModal } from '../../context/HelpModalProvider'

export default function ProceedToPayment() {
	const [state] = useContextApi()
	const { showHelpModal } = useHelpModal()

	const [sendPhysicalCopy, setSendPhysicalCopy] = useState(false)

	const currentRfqItem = state?.currentRfqItem
	const policyName = getPolicyName(currentRfqItem?.Tag)

	const history = useHistory()

	const proceedToPayment = () => history.push('/purchase-policy/payment-methods')

	const navContent = (
		<Text type="body1" color={theme.colors.gray7} fontWeight="700">
			<div className="d-flex align-items-center">
				{policyName}({currentRfqItem?.policyIndex}) &nbsp;
				<PolicyTypeLabel>{currentRfqItem?.policyName}</PolicyTypeLabel>
				<Icon name="rightArrow" color={theme.colors.primary} /> Successful
			</div>
		</Text>
	)

	// mobile nav
	const mobileLeftNavContent = (
		<div className="w-100 d-flex justify-content-center">
			<Text
				fontSize="18px"
				style={{ marginLeft: '2rem' }}
				color={theme.colors.primary}
				fontWeight="700"
			>
				Successful
			</Text>
		</div>
	)

	const mobileRightNavContent = (
		<Button
			onClick={() => showHelpModal(true)}
			icon="helpAltIcon"
			iconColor={theme.colors.primary}
			iconSize="18px"
			iconStyles={{ marginRight: '.3rem' }}
			bgColor="white"
			style={{ opacity: '.5' }}
			textColor={theme.colors.primary}
			label="Help"
		/>
	)

	return (
		<PageContentAndNavbarWrapper>
			<OnDesktop>
				<SecondaryNavbar
					leftContent={navContent}
					navbarNumber={1}
					showGoBack={false}
					headerBg={theme.colors.secondary2}
				/>
			</OnDesktop>
			<OnMobile>
				<SecondaryNavbar
					leftContent={mobileLeftNavContent}
					rightContent={mobileRightNavContent}
					navbarNumber={1}
					showGoBack={true}
					headerBg={theme.colors.white}
				/>
			</OnMobile>
			<CustomWrapper className="d-flex flex-column align-items-center">
				<Text className="m-2" color={theme.colors.primary} fontSize="24px" fontWeight="700">
					Self declaration successful
				</Text>
				<ConfirmationSuccessImage className="mb-4" src={confirmationLottie} />
				<AnimatedDiv className="w-100 mt-3 d-flex flex-column align-items-center">
					{/* <CustomButton
						textColor={theme.colors.primary}
						bgColor={theme.colors.secondary2}
						style={{ width: '520px', height: '56px', fontSize: '18px', fontWeight: '700' }}
						label="Review details again"
					/> */}
					<Space y={1.5} />
					<CustomButton
						icon="leftArrows"
						iconColor={theme.colors.white}
						iconStyles={{ transform: 'rotate(180deg)', marginLeft: '.5rem' }}
						iconAlign="right"
						bgColor="linear-gradient(90deg, #00959C 0.1%, #02475E 100.1%);"
						style={{
							fontStyle: 'italic',
							width: '520px',
							height: '56px',
							fontSize: '18px',
							fontWeight: '800',
							letterSpacing: '.05em',
						}}
						label="PROCEED TO PAYMENT"
						onClick={proceedToPayment}
					/>
				</AnimatedDiv>
				<Space y={2} />
				{/* <div className="mb-4 d-flex align-items-center" style={{ textAlign: 'left' }}>
					<Checkbox value={sendPhysicalCopy} onChange={val => setSendPhysicalCopy(val)} />
					<Text
						className="ml-2 cursor-pointer"
						color={theme.colors.gray3}
						fontSize="14px"
						fontWeight="500"
						style={{ textAlign: 'left !important' }}
					>
						Send a physical copy of your policy to your registered address
					</Text>
				</div> */}
			</CustomWrapper>
		</PageContentAndNavbarWrapper>
	)
}

const lottieAnimation = keyframes`
from{
  transform: scale(1);
}
to{
  transform: scale(.5);
}
`

const fadeIn = keyframes`
from{
opacity: 0.3;
}
to{
  opacity: 1;
}
`

const ConfirmationSuccessImage = styled.img`
	animation-name: ${lottieAnimation};
	animation-duration: 1s;
	animation-iteration-count: 1;
	transform: scale(0.8);
`

const AnimatedDiv = styled.div`
	animation-name: ${fadeIn};
	animation-duration: 2s;
	animation-iteration-count: 1;
`

const CustomWrapper = styled(MainWrapper)`
	@media (max-width: 768px) {
		padding: 1.5rem !important;
		width: 100%;
		padding: 1rem;
		margin: 0;
		border-radius: 0;
	}
`

const CustomButton = styled(Button)`
	@media (max-width: 768px) {
		width: 95% !important;
		height: 40px !important;
		font-size: 14px !important;
	}
`
