import { useReducer, useMemo, createContext, useContext } from 'react'
import { reducer } from './Reducer'

const token = sessionStorage.getItem('token') || ''
const userData = sessionStorage.getItem('user') || '{}'
const authenticated = !!token

const initialState = {
	user: {
		authenticated: authenticated,
		token: token,
		data: JSON.parse(userData),
	},
	rfqList: [],
	currentRfqItem: {
		quotes: [],
		selectedQuote: null,
		data: null, // RFQ data goes here
	},
	customerInfo: {},
	hardwareProducts: [],
}

const initialContext = [{ ...initialState }, () => {}]

export const Context = createContext(initialContext)

export const useContextApi = () => {
	const context = useContext(Context)
	if (!context) {
		throw new Error('useContextApi must be used within AuthProvider')
	}
	return context
}

export function Provider({ children, ...props }) {
	const [state, dispatch] = useReducer(reducer, initialState)
	const value = useMemo(() => [state, dispatch], [state])

	return <Context.Provider value={value}>{children}</Context.Provider>
}
