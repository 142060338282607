const ADD_GROUP = 'ADD_GROUP'

export const reducer = (state, action) => {
	switch (action.type) {
		case 'ADD_RFQS':
			return {
				...state,
				rfqList: [...action.data],
			}
		case 'CLEAR_SELECTED_RFQ_DATA':
			return {
				...state,
				currentRfqItem: {
					quotes: [],
					selectedQuote: null,
					data: null,
				},
				customerInfo: {},
			}
		case 'CLEAR_RFQS':
			return {
				...state,
				rfqList: [],
			}
		case 'UPDATE_RFQ_STATUS_AFTER_PAYMENT':
			let updateRfqList = [...state.rfqList]
			const toUpdateRfq = action.data.toUpdateRfq

			const toUpdateIndex = updateRfqList.findIndex(item => {
				return item.id === toUpdateRfq.id
			})

			if (toUpdateIndex > -1) {
				updateRfqList[toUpdateIndex] = {
					...toUpdateRfq,
					Status: 'OPS:POLICY:PENDING',
				}
			}

			return {
				...state,
				rfqList: updateRfqList,
			}
		case 'SELECT_RFQ':
			return {
				...state,
				currentRfqItem: {
					...state.currentRfqItem,
					...action.data,
				},
			}
		case 'SET_RFQ_DATA':
			return {
				...state,
				currentRfqItem: {
					...state.currentRfqItem,
					data: action.data,
				},
			}
		case 'SET_RFQ_QUOTES_AND_DATA': {
			return {
				...state,
				currentRfqItem: {
					...state.currentRfqItem,
					...action.data,
				},
			}
		}
		case 'SET_SELECTED_QUOTE':
			const quotes = state?.currentRfqItem?.quotes
			let selectedQuote = null
			if (quotes?.length > 0) {
				selectedQuote = quotes?.find(quote => !!quote?.Selected)
			}

			return {
				...state,
				currentRfqItem: {
					...state.currentRfqItem,
					selectedQuote: selectedQuote,
				},
			}
		case 'SET_CUSTOMER_INFO':
			return {
				...state,
				customerInfo: {
					...state.customerInfo,
					...action.data,
				},
			}
		// USER REDUCER
		case 'LOGIN_USER':
			return {
				...state,
				user: {
					...state.user,
					authenticated: true,
					token: action.data?.token,
					data: action.data?.data,
				},
			}
		case 'SET_USER_INFO':
			return {
				...state,
				user: {
					...state.user,
					data: {
						...state.user.data,
						...action.data,
					},
				},
			}
		case 'SET_TOKEN':
			return {
				...state,
				user: {
					...state.user,
					token: action.data,
				},
			}
		case 'SET_USER_DATA':
			return {
				...state,
				user: {
					...state.user,
					data: action.data,
				},
			}
		case 'SET_HARDWARE_PRODUCTS': {
			return {
				...state,
				hardwareProducts: [...action.data],
			}
		}
		case 'LOGOUT_USER':
			sessionStorage.removeItem('token')
			sessionStorage.removeItem('user')
			return {
				...state,
				rfqList: [],
				currentRfqItem: {
					quotes: [],
					selectedQuote: null,
					data: null,
				},
				user: {
					...state.user,
					authenticated: false,
					token: '',
					data: {},
				},
				customerInfo: {},
			}
		default:
			return state
	}
}
