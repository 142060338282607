import styled from 'styled-components'
import { Icon } from 'verak-ui'

const appbarMenuItems = [
	{
		icon: 'logout',
		text: 'Logout',
		id: 'LOGOUT',
	},
]

const MenuItemContainer = styled.li`
	display: flex;
	align-items: center;
	padding: 0.75rem 0.375rem;
	border-bottom: ${props => (props.isLast ? '0.5px solid #8d8d8d' : 'none')};
	list-style: none;
	background-color: #fff;
	font-size: 12px;
	font-weight: 400;
	line-height: 15px;
	letter-spacing: 0rem;
	color: #8d8d8d;
	min-height: 40px;
	cursor: pointer;
`
const MenuContainer = styled.ul`
	min-width: 200px;
	padding: 0;
`

const MenuItem = ({ item, itemNo, onLogout }) => {
	const { icon, text, link, onClick } = item
	const isLink = Boolean(link)
	let handleClick = onClick ? onClick : () => {}
	const isLast = itemNo !== appbarMenuItems.length - 1

	if (text === 'Logout') {
		handleClick = onLogout
	}
	const renderItem = (
		<MenuItemContainer onClick={handleClick} isLast={isLast}>
			<Icon name={icon} style={{ marginRight: '0.25rem' }} />
			{text}
		</MenuItemContainer>
	)

	if (isLink) {
		return <a href={link}>{renderItem}</a>
	}
	return renderItem
}

const AppbarMenu = ({ triggerLogout, ...props }) => {
	return (
		<MenuContainer>
			{appbarMenuItems.map((el, key) => (
				<MenuItem key={el.id} item={el} itemNo={key} onLogout={triggerLogout} />
			))}
		</MenuContainer>
	)
}

export default AppbarMenu
