import styled from 'styled-components'
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router'

import { useFetch } from '../api/apihooks'
import { useContextApi } from '../context/Provider'
import { getDateString } from '../utilities/getDateString'
import { generateStatus } from '../utilities/generateStatus'
import { generateTag } from '../utilities/generateTag'

import {
	Button,
	SecondaryNavbar,
	theme,
	Text,
	PageContentAndNavbarWrapper,
	Icon,
	Space,
} from 'verak-ui'
import { OnDesktop, OnMobile } from '../components/HideViewports'
import { useHelpModal } from '../context/HelpModalProvider'
import { useLoadingErrorContext } from '../context/LoadingErrorProvider'
import { convertAmountToWords } from '../utilities/convertAmountToWords'
import { getPolicyTypeFullForm } from '../utilities/getPolicyType'

const navContent = (
	<Text
		color={theme.colors.gray7}
		style={{ fontSize: '18px', letterSpacing: '-0.03em', fontWeight: 700 }}
	>
		Home
	</Text>
)

const generateStatusStyles = Status => {
	let styles = {
		icon: 'tickCircle',
		background: 'yellow',
		textColor: 'white',
	}

	switch (Status) {
		case 'Quotes available now':
			return {
				icon: 'add',
				background: 'secondary',
				textColor: 'white',
			}
		case 'New quote(s) added':
			return {
				icon: 'yes',
				background: 'primary', // pending: 'facebook.hover' - make this into a new color too
				textColor: 'white',
			}
		case 'Awaiting quotes':
			return {
				icon: 'exchange',
				background: 'red',
				textColor: 'white',
			}
		case 'Payment pending':
			return {
				icon: 'moneyWithdrawal',
				background: 'secondaryLightRed',
				textColor: 'red',
			}
		case 'Purchase confirmation pending':
			return {
				icon: 'infoIcon',
				iconColor: 'white',
				background: '#03045E',
				textColor: 'white',
			}
		case 'Policy issuance pending':
			styles.icon = ''
			return {
				icon: 'documentError',
				background: 'yellow',
				textColor: 'white',
			}
		case 'Purchase unsuccessful!!':
			return {
				icon: 'moneyWithdrawal',
				background: 'secondaryLightRed',
				textColor: 'red',
			}
		case 'Purchase Successful - Policy Issued!':
			return {
				icon: 'tickCircle',
				background: 'green4',
				textColor: 'white',
				iconColor: 'white',
			}
		default:
			return {
				icon: 'tickCircle',
				background: 'yellow',
				textColor: 'white',
			}
	}
}

function getPolicyType(sumInsured) {
	if (sumInsured > 500000000) {
		return 'SFSP'
	} else if (sumInsured >= 50000000 && sumInsured <= 500000000) {
		// 5 cr and 50 cr
		return 'BLUS'
	}
	return 'BSUS'
}

const Home = () => {
	const { getRfqList, getClientDetails, getClientName } = useFetch()
	let history = useHistory()
	const [state, dispatch] = useContextApi()
	const { loadingState } = useLoadingErrorContext()
	const { showHelpModal } = useHelpModal()

	const storedCustomerInfo = state?.user?.data || {}
	const rfqList = state.rfqList
	const clientName = storedCustomerInfo?.name || ''

	const fetchRfqList = async () => {
		const result = await getRfqList()
		await new Promise(resolve => {
			dispatch({
				type: 'ADD_RFQS',
				data: result,
			})
			resolve()
		})
	}

	useEffect(() => {
		if (rfqList.length === 0) {
			fetchRfqList()
		}
		return () => {
			if (!localStorage?.getItem('firstTimeUser')) {
				localStorage.setItem('firstTimeUser', 'false')
			}
		}
	}, [])

	useEffect(() => {
		getClientName().then(response => {
			if (response.Name) {
				dispatch({
					type: 'SET_USER_INFO',
					data: {
						name: response.Name,
					},
				})
			}
		})
	}, [])

	let firstTimeUserString = ''
	if (localStorage.getItem('firstTimeUser') === 'false') {
		firstTimeUserString = 'back, '
	}

	const selectRfq = async (rfqData, policyIndex) => {
		// sets the active RFQ
		await new Promise(resolve => {
			dispatch({
				type: 'SELECT_RFQ',
				data: { ...rfqData, policyIndex: policyIndex + 1 },
			})
			resolve()
		})

		const customerInfo = await getClientDetails(rfqData.id)

		await new Promise(resolve => {
			dispatch({
				type: 'SET_CUSTOMER_INFO',
				data: customerInfo,
			})
			resolve()
		})

		history.push(`/rfq-status/${rfqData.id}`)
	}

	let renderRfqs = null
	if (rfqList.length > 0) {
		// segregate the RFQs into 'upcoming' and 'past purchases'
		let upcomingRfqs = [],
			pastRfqs = []

		// the rfqList is already sorted based on its updation time
		rfqList.forEach(rfq => {
			// only the latest RFQ with the tag is added to "upcoming"
			if (upcomingRfqs?.find(el => el.Tag === rfq.Tag)) {
				pastRfqs.push(rfq)
			} else {
				upcomingRfqs.push(rfq)
			}
		})

		renderRfqs = (
			<>
				<Text fontSize="14px" mobileFontSize="12px" color={theme.colors.secondary}>
					UPCOMING PURCHASES
				</Text>
				<Divider
					style={{
						border: `1px solid ${theme.colors.secondary}`,
						width: '100%',
						height: '1px',
						margin: '0 0 0.75rem 0',
					}}
				/>
				{upcomingRfqs.map((rfq, index) => (
					<RFQ
						key={rfq.id}
						Tag={rfq.Tag}
						updatedAt={rfq.updatedAt}
						rfqData={rfq}
						selectRfq={() => selectRfq(rfq, index)}
						clientName={clientName}
					/>
				))}

				{pastRfqs?.length > 0 && (
					<>
						<Text fontSize="14px" mobileFontSize="12px" color={theme.colors.primary}>
							PAST PURCHASE REQUESTS
						</Text>
						<Divider
							style={{
								border: `1px solid ${theme.colors.primary}`,
								width: '100%',
								height: '1px',
								margin: '0 0 0.75rem 0',
							}}
						/>
					</>
				)}
				{pastRfqs.map((rfq, index) => (
					<RFQ
						key={rfq.id}
						Tag={rfq.Tag}
						updatedAt={rfq.updatedAt}
						rfqData={rfq}
						selectRfq={() => selectRfq(rfq, index)}
						clientName={clientName}
					/>
				))}
			</>
		)
	} else if (!loadingState?.status && rfqList.length === 0) {
		renderRfqs = (
			<div className="mt-3 d-flex align-items-center flex-column">
				<Icon name="fireIconOutline" />
				<Text className="mt-2" color={theme.colors.primary} fontSize="18px" fontWeight="700">
					All Caught up here!
				</Text>
				<Text className="mt-2" color={theme.colors.gray3} fontSize="18px" fontWeight="700">
					You will be notified of any new updates
				</Text>
			</div>
		)
	}

	const rightNavContent = (
		<Button
			onClick={() => showHelpModal(true)}
			label="Help"
			icon="helpAltIcon"
			style={{ background: 'white' }}
			textColor={theme.colors.primary}
			iconStyles={{ marginRight: '0.5rem' }}
		/>
	)

	const mobileRightNavContent = (
		<Button
			onClick={() => showHelpModal(true)}
			label="Help"
			icon="helpAltIcon"
			style={{ background: 'white' }}
			textColor={theme.colors.primary}
			iconStyles={{ marginRight: '0.5rem' }}
			iconSize="18px"
		/>
	)

	return (
		<PageContentAndNavbarWrapper style={{ height: '100%' }}>
			<OnDesktop>
				<SecondaryNavbar
					leftContent={navContent}
					navbarNumber={-1}
					rightContent={rightNavContent}
					showGoBack={false}
					headerBg={theme.colors.secondary2}
				/>
			</OnDesktop>
			<OnMobile>
				<SecondaryNavbar
					leftContent={navContent}
					navbarNumber={-1}
					rightContent={mobileRightNavContent}
					showGoBack={false}
					headerBg={theme.colors.secondary2}
				/>
			</OnMobile>
			<Wrapper>
				<Text color={theme.colors.primary} style={{ fontSize: '24px', fontWeight: 700 }}>
					Welcome {firstTimeUserString} {clientName}
				</Text>
				<Text color={theme.colors.gray3} style={{ fontSize: '18px', fontWeight: 400 }}>
					Select an option to continue
				</Text>
				<Space y={2} />

				{renderRfqs}
			</Wrapper>
		</PageContentAndNavbarWrapper>
	)
}

export default Home

const RFQ = ({ Tag, updatedAt, rfqData, selectRfq, clientName }) => {
	let currentStatus = generateStatus(rfqData)
	let statusStyles = generateStatusStyles(currentStatus)

	const policyType = getPolicyType(rfqData?.SumInsured || 0)

	return (
		<RFQWrapper onClick={() => selectRfq(rfqData)}>
			<RFQDataWrapper>
				<div style={{ flex: '1' }}>
					<PolicyTypeContainer>
						<Text
							color={theme.colors.primary}
							fontSize="18px"
							mobileFontSize="14px"
							fontWeight="700"
						>
							{generateTag(Tag)}
						</Text>
						<OnDesktop style={{ width: 'auto' }}>
							<Divider style={{ height: '34px', margin: '0 0.75rem' }} />
						</OnDesktop>
						<OnMobile className="w-0">
							<Space y={0.25} />
						</OnMobile>
						<Text
							fontSize="16px"
							mobileFontSize="12px"
							fontWeight="700"
							color={theme.colors.secondary}
						>
							{getPolicyTypeFullForm(policyType)} ({policyType})
						</Text>
					</PolicyTypeContainer>

					<Space y={0.625} />
					<div className="flex-container">
						<div className="d-flex flex-column">
							<OnDesktop>
								<Text
									color={theme.colors.primary}
									fontSize="12px"
									fontWeight="700"
									style={{ opacity: 0.4 }}
								>
									SUM INSURED
								</Text>
							</OnDesktop>
							<Text
								color={theme.colors.primary}
								mobileFontSize="12px"
								fontSize="16px"
								fontWeight="700"
							>
								{convertAmountToWords(`₹ ${rfqData?.SumInsured || 0}`)}
							</Text>
						</div>
						<Divider style={{ height: '34px', margin: '0 0.75rem' }} />
						<div className="d-flex flex-column">
							<OnDesktop>
								<Text
									color={theme.colors.primary}
									fontSize="12px"
									fontWeight="700"
									style={{ opacity: 0.4 }}
								>
									{rfqData?.AccountType === 'INDIVIDUAL' ? 'NAME ON POLICY' : 'LEGAL ENTITY NAME'}
								</Text>
							</OnDesktop>
							<Text
								fontSize="16px"
								mobileFontSize="12px"
								color={theme.colors.primary}
								fontWeight="700"
							>
								{rfqData?.Account}
							</Text>
						</div>
						<Divider style={{ height: '34px', margin: '0 0.75rem' }} />
						<div className="d-flex flex-column">
							<OnDesktop>
								<Text
									color={theme.colors.primary}
									fontSize="12px"
									fontWeight="700"
									style={{ opacity: 0.4 }}
								>
									PROPOSER NAME
								</Text>
							</OnDesktop>
							<Text
								fontSize="16px"
								mobileFontSize="12px"
								color={theme.colors.primary}
								fontWeight="700"
							>
								{clientName}
							</Text>
						</div>
					</div>
				</div>
				<Space y={1} />
				<RightContainer>
					<StatusContainer status={currentStatus}>
						<Icon
							name={statusStyles?.icon}
							style={{ width: '16px', height: '16px' }}
							color={statusStyles?.iconColor}
						/>
						<Space x={0.25} />
						<Text fontSize="12px" mobileFontSize="12px" fontWeight="700">
							{currentStatus}
						</Text>
					</StatusContainer>
					<Space y={0.625} />
					<div className="d-flex flex-column">
						<Text
							color={theme.colors.primary}
							fontSize="12px"
							fontWeight="700"
							style={{ opacity: 0.4 }}
							mobileFontSize="12px"
						>
							LAST UPDATED
						</Text>
						<Text
							fontSize="16px"
							mobileFontSize="12px"
							color={theme.colors.primary}
							fontWeight="700"
						>
							{getDateString(updatedAt)}
						</Text>
					</div>
				</RightContainer>
			</RFQDataWrapper>
			<Icon name="rightArrow" color={theme.colors.gray7} />
		</RFQWrapper>
	)
}

const Wrapper = styled.div`
	margin: 3rem 5rem;
	@media (max-width: 768px) {
		margin: 1rem;
	}
`
const RFQWrapper = styled.div`
	width: 100%;
	padding: 1rem;
	margin-bottom: 1.5rem;
	background: #ffffff;
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
`
const RFQDataWrapper = styled.div`
	display: flex;
	align-items: center;
	flex: 1;

	@media (max-width: 768px) {
		flex-direction: column;
		align-items: flex-start;
	}
`

const PolicyTypeContainer = styled.div`
	display: flex;
	@media (max-width: 768px) {
		flex-direction: column;
	}
`

const Divider = styled.div`
	/* background: #c4c4c4; */
	height: 36px;
	width: 1px;
	margin: 0 1rem;
	border: 1px solid #c4c4c4;
`
const StatusContainer = styled.div`
	border-radius: 8px;
	padding: 0.625rem;
	background: ${({ status }) =>
		theme.colors[generateStatusStyles(status)?.background] ||
		generateStatusStyles(status)?.background ||
		''};
	color: ${({ status }) => generateStatusStyles(status)?.textColor};
	display: flex;
	align-items: center;
	height: 36px;
`

const RightContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	@media (max-width: 768px) {
		width: 100%;
		flex-direction: row-reverse;
		align-items: center;
		justify-content: space-between;
	}
`
