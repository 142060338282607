import { Link } from 'react-router-dom'

import { useContextApi } from '../../context/Provider'
import { PageContentAndNavbarWrapper, SecondaryNavbar, theme, Space, Divider } from 'verak-ui'
import {
	MainWrapper,
	navContent,
	OnDesktop,
	OnMobile,
	mobileLeftNav1,
	StyledImg,
	StyledPrimaryBtn,
	StyledText,
} from '../../components/SharedComponents'
import { primaryHeader, secondaryHeader } from '../../components/SharedStyles'

import awaitingQuotes from '../../assets/awaitingQuotes.svg'

const AwaitingQuotes = () => {
	const [state, dispatch] = useContextApi()

	return (
		<PageContentAndNavbarWrapper className="d-flex flex-column align-items-center">
			<OnDesktop>
				<SecondaryNavbar
					leftContent={navContent(state?.currentRfqItem, 'Awaiting Quotes')}
					navbarNumber={1}
					showGoBack={false}
					headerBg={theme.colors.secondary2}
				/>
			</OnDesktop>
			<OnMobile>
				<Space y={1} />
				<SecondaryNavbar
					showGoBack={false}
					headerBg={theme.colors.white}
					navbarNumber={1}
					leftContent={mobileLeftNav1('Awaiting Quotes')}
				/>
			</OnMobile>
			<MainWrapper>
				<StyledImg src={awaitingQuotes} alt="awaiting-quotes" />
				<Space y={1} />
				<StyledText style={primaryHeader}>We are waiting for quotes to arrive!</StyledText>
				<Space y={1} />
				<StyledText style={secondaryHeader}>
					You will be notified as soon as we have them
				</StyledText>
				<Space y={4.625} />
				<Divider />
				<Space y={1} />
				<Link to="/" style={{ textDecoration: 'none' }}>
					<StyledPrimaryBtn label="Continue to home" icon="rightArrow" iconAlign="right" />
				</Link>
			</MainWrapper>
		</PageContentAndNavbarWrapper>
	)
}

export default AwaitingQuotes
