import { createContext, useState, useEffect } from 'react'
import { useLocation } from 'react-router'
import { Divider, Space, Text, theme } from 'verak-ui'
import { useContextApi } from './Provider'
import { InsurerImagesMap } from '../screens/RFQStatuses/QuotesAvailable/quoteComparisonUtils'
import { convertAmountToWords } from '../utilities/convertAmountToWords'
import { coverageValues } from '../screens/RFQStatuses/QuotesAvailable/quoteComparisonUtils'
import calculateTotalPremium from '../utilities/calculateTotalPremium'
import { getFormattedAmount } from '../utilities/getFormattedAmount'
export const SidepanelContext = createContext()

const enabledSidePanelRoutes = [
	'/purchase-policy/customer-info',
	'/purchase-policy/proceed-to-payment',
	'/purchase-policy/payment-methods',
	'/purchase-policy/online-payment',
]
const checkRoute = (routesToshow, targetPathname) => {
	for (let route of routesToshow) {
		if (targetPathname.includes(route)) {
			return true
		}
	}
	return false
}

const sidePanelFooter = (
	<div>
		<Divider bottom={0.5} top={1} />
		<Text fontSize="8px" color={theme.colors.gray3}>
			Insurance is the subject matter of solicitation.
		</Text>
		<Space y={0.5} />
		<Text fontSize="8px" color={theme.colors.gray3}>
			<b style={{ fontSize: '9px' }}>Insure First Insurance Brokers LLP,</b>
			<br />
			IRDAI License No. 673,
			<br />
			Direct Broker (Life & General),
			<br />
			Valid upto : 27/03/2022
			<br />
			<br />
			Principal Officer: Aparajit Bhattacharya,
			<br />
			Email-:aparajit.b@insurefirst.co.in,
			<br />
			033 4063 0732
			<br />
		</Text>
		<Space y={0.5} />
	</div>
)

export function SidepanelProvider({ children, ...props }) {
	const [state] = useContextApi()
	const isAuthenticated = state?.user?.authenticated

	const [renderSidePanel, setRenderSidePanel] = useState(isAuthenticated)

	const rfqData = state?.currentRfqItem || {}
	const selectedQuote = state?.currentRfqItem?.selectedQuote || {}

	let totalPremiumInWords,
		coverAmountInWords,
		coverDuration = '-'
	if (selectedQuote?.TotalPremium) {
		const value = calculateTotalPremium(selectedQuote)
		totalPremiumInWords = `₹ ${getFormattedAmount(value)}`
	}
	if (rfqData?.cover) {
		coverAmountInWords = convertAmountToWords(`₹ ${rfqData?.cover}`)
	}

	if (rfqData?.duration) {
		const durationValue = rfqData?.duration
		if (durationValue === 'M12') {
			coverDuration = '1 Year'
		} else {
			const durationInMonths = coverageValues?.find(coverItem => coverItem.value === durationValue)
			coverDuration = durationInMonths?.name || '-'
		}
	}

	const selectedPolicyData = (
		<div className="d-flex flex-column">
			<img
				src={InsurerImagesMap?.[selectedQuote?.Insurer] || ''}
				style={{ width: 'min(200px, 100%)', height: 100 }}
				alt="selected insurer"
				className="mb-2"
			/>
			<div className="mb-3" style={{ width: 'min(200px, 100%)' }}>
				<div className="d-flex justify-content-between w-100">
					<Text type="body2" color={theme.colors.gray2}>
						Premium
					</Text>
					<Text type="body2" color={theme.colors.primary} fontWeight="700">
						{totalPremiumInWords}
					</Text>
				</div>
				<div className="d-flex justify-content-between w-100">
					<Text type="body2" color={theme.colors.gray2}>
						Cover
					</Text>
					<Text type="body2" color={theme.colors.primary} fontWeight="700">
						{coverAmountInWords}
					</Text>
				</div>
				<div className="d-flex justify-content-between w-100">
					<Text type="body2" color={theme.colors.gray2}>
						Period
					</Text>
					<Text type="body2" color={theme.colors.primary} fontWeight="700">
						{coverDuration}
					</Text>
				</div>
			</div>
			<div className="flex-fill"></div>
			{sidePanelFooter}
		</div>
	)

	const [sidepanelData, setSidepanelData] = useState({
		sidepanelTitle: 'SELECTED POLICY',
		extraContent: selectedPolicyData,
	})

	const { pathname } = useLocation()

	useEffect(() => {
		if (isAuthenticated) {
			setRenderSidePanel(checkRoute(enabledSidePanelRoutes, pathname))
		}
	}, [pathname]) // eslint-disable-line

	useEffect(() => {
		setSidepanelData({
			...sidepanelData,
			extraContent: selectedPolicyData,
		})
	}, [selectedQuote?.id])
	const values = {
		renderSidePanel,
		setRenderSidePanel,
		sidepanelData,
		setSidepanelData,
	}

	return (
		<SidepanelContext.Provider {...props} value={values}>
			{children}
		</SidepanelContext.Provider>
	)
}
