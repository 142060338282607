import {
	PageContentAndNavbarWrapper,
	SecondaryNavbar,
	theme,
	Text,
	Space,
	Divider,
	Button,
	Icon,
	MainContainer,
	Select,
	Input,
	SearchableSelect,
	HiddenFileInput,
	FileUploaded,
} from 'verak-ui'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { useState, useEffect, useRef } from 'react'

import { OnDesktop, OnMobile } from '../../components/HideViewports'
import { useContextApi } from '../../context/Provider'
import { getPolicyName } from '../../utilities/getPolicyName'
import { useFetch } from '../../api/apihooks'

import PlusIconOrange from '../../assets/plusIconOrange.svg'
import UploadedImage from '../../assets/images/uploadedImage.png'
import UploadedDoc from '../../assets/images/uploadedDoc.png'
import { InsurerImagesMap } from '../RFQStatuses/QuotesAvailable/quoteComparisonUtils'

import { stateOps, salutationsList } from './staticData'
import { convertAmountToWords } from '../../utilities/convertAmountToWords'
import {
	imageDeleteConfirmOptions,
	parseLinkFromSignedUrl,
} from '../../utilities/imageUploadUtilities'
import { coverageValues } from '../RFQStatuses/QuotesAvailable/quoteComparisonUtils'
import Swal from 'sweetalert2'
import { useHelpModal } from '../../context/HelpModalProvider'
import calculateTotalPremium from '../../utilities/calculateTotalPremium'
import { getFormattedAmount } from '../../utilities/getFormattedAmount'
import { PolicyTypeLabel } from '../../components/SharedComponents'

const CustomUploadButton = ({ onClick = () => {}, disabled = false }) => (
	<Button
		secondary
		size="stretch"
		iconStyles={{ marginRight: '0.25rem' }}
		label={<img src={PlusIconOrange} alt="Upload" />}
		onClick={onClick}
		style={{ backgroundColor: '#fff', border: '2px dashed #8D8D8D', width: '72px', height: '48px' }}
		disabled={disabled}
	/>
)

// TODO: add this edit btn functionality directly to verak-ui for Input, Select and Searchable Select comps.
const EditField = ({ onEditClick, showEditButton = false, editBtnStyle = {}, children }) => {
	if (showEditButton) {
		return (
			<div className="w-100 position-relative">
				{children}
				<Button
					className="position-absolute end-0 me-2 mb-1 p-0"
					onClick={onEditClick}
					icon="edit"
					bgColor="rgba(0,0,0,0.0)"
					style={{ zIndex: '1000', top: '2rem', ...editBtnStyle }}
				/>
			</div>
		)
	}
	return children
}

const validateEmail = email => {
	const re =
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	return re.test(String(email).toLowerCase())
}

function splitName(name) {
	if (name) {
		let [salutation, fullName] = name?.split('. ')

		// case where salutation is not present
		if (!fullName) {
			fullName = salutation
			salutation = ''
		} else {
			salutation += '.'
		}
		return { salutation, fullName }
	}
	return {}
}

// these insurers have certain conditions on PAN / GST requirements based on the premium amount
const insurersWithPANGSTConditions = [
	'Royal Sundaram General Insurance',
	'Reliance General Insurance',
	'Liberty General Insurance',
	'Oriental Insurance',
	'ICICI Lombard General Insurance',
	'The New India Assurance Co. Ltd.',
	'National Insurance',
	'Kotak General Insurance',
	'Chola MS General Insurance',
	'Digit Insurance',
	'United India Insurance',
	'SBI General Insurance',
]

function CustomerInfoForm({ customerData }) {
	const fetch = useFetch()
	const [state, dispatch] = useContextApi()
	const history = useHistory()

	const { Address, Verification, PersonalInfo, ContactInfo } = customerData

	/* Input data states */

	// to extract the salutation from the Name dynamically
	const alteredName = splitName(PersonalInfo?.Name)

	// Personal info
	const [salutation, setSalutation] = useState(alteredName?.salutation || '')
	const [fullName, setFullName] = useState(alteredName?.fullName || '')
	const [designation, setDesignation] = useState(PersonalInfo?.Designation || '')
	const [entityName, setEntityName] = useState(PersonalInfo?.LegalEntityName || '')

	// Contact info
	const [mobileNo, setMobileNo] = useState(ContactInfo?.Phone?.replace('+91', '') || '')
	const [landlineNo, setLandlineNo] = useState(ContactInfo?.Landline?.replace('+91', '') || '')
	const [email, setEmail] = useState(ContactInfo?.Email || '')

	// Address info
	const [addressFirstLine, setAddressFirstLine] = useState(Address?.Line1 || '')
	const [addressSecondLine, setAddressSecondLine] = useState(Address?.Line2 || '')
	const [PIN, setPIN] = useState(Address?.PIN || '')
	const [city, setCity] = useState(Address?.City || '')
	const [stateName, setStateName] = useState(Address?.State || '')

	// legal info
	const [PANNo, setPANNo] = useState(Verification?.PAN || '')
	const [GSTNo, setGSTNo] = useState(Verification?.GSTIN || '')
	const [aadharNo, setAadharNo] = useState(Verification?.Aadhar || '')
	const [PANPassword, setPANPassword] = useState(Verification?.Passwords?.PAN || '')
	const [GSTPassword, setGSTPassword] = useState(Verification?.Passwords?.GSTIN || '')

	// hypothecation parties
	const [hypothecationParties, setHypothecationParties] = useState([])

	// field editing states
	const [entityEditDisabled, setEntityEditDisabled] = useState(true)
	const [emailEditDisabled, setEmailEditDisabled] = useState(!!ContactInfo?.Email)
	// field editing states - based on prefilling done by PIN code API
	const [cityPrefilledByAPI, setCityPrefilledByAPI] = useState(false)
	const [statePrefilledByAPI, setStatePrefilledByAPI] = useState(false)

	// documents
	const PANDocument = Verification?.Images?.PAN || ''
	const GSTDocument = Verification?.Images?.GSTIN || ''
	const PANCardUploadRef = useRef()
	const GSTImageUploadRef = useRef()
	const [uploadedImages, setUploadedImages] = useState({
		PANCard: PANDocument ? [PANDocument] : [],
		GST: GSTDocument ? [GSTDocument] : [],
	})

	const selectedQuote = state?.currentRfqItem?.selectedQuote || {}
	const totalPremium = calculateTotalPremium(selectedQuote)

	// check if GST and PAN are mandatory based on premium and company
	// all 4 are mandatory by default
	let isGSTNumMandatory = true
	let isGSTCertMandatory = true
	let isPANNumMandatory = true
	let isPANCertMandatory = true
	const insurerName = selectedQuote?.Insurer || ''

	if (insurersWithPANGSTConditions.includes(insurerName)) {
		isGSTCertMandatory = false
		isGSTNumMandatory = false

		if (totalPremium < 50000) {
			isPANCertMandatory = false
			isPANNumMandatory = false
		} else if (totalPremium >= 50000 && totalPremium < 100000) {
			// PAN Number will remain mandatory
			isPANNumMandatory = true
			isPANCertMandatory = false
		} else {
			// else PAN Certificate & PAN Num will remain mandatory
			isPANNumMandatory = true
			isPANCertMandatory = true
		}
	}

	const allLegalFieldsMandatory =
		isGSTCertMandatory && isGSTCertMandatory && isPANNumMandatory && isPANCertMandatory

	const currentRfqId = state?.currentRfqItem?.id || ''

	useEffect(() => {
		const fetchHypothecation = async () => {
			if (currentRfqId) {
				const result = await fetch.getHypothecationDataForRfq(currentRfqId)
				const hypothecationData = result?.hypothecation || []
				if (hypothecationData?.length) {
					setHypothecationParties(hypothecationData)
				}
			}
		}
		fetchHypothecation()
	}, []) // eslint-disable-line

	// runs when PIN field goes out of focus
	const fetchCityAndState = async () => {
		const cityOrStateEmpty = !city || !state
		const pinValid = PIN && String(PIN).match(/^[1-9]{1}\d{5}$/)

		// make an API call only if the city or state is empty and if the PIN is valid
		if (pinValid && cityOrStateEmpty) {
			const response = await fetch.getLocationsWithPIN(PIN)

			const results = response?.[0] || []
			const locations = results?.PostOffice || []

			if (locations?.length) {
				// set the 'District' field as the city
				const { District: City, State } = locations?.[0] || {}
				if (City) {
					setCity(City)
					setCityPrefilledByAPI(true)
				}
				if (State) {
					setStateName(State)
					setStatePrefilledByAPI(true)
				}
			}
		}
	}

	const handleFileUpload = async (files, imageType) => {
		const file = files[0]

		const mimeTypes = {
			jpeg: 'image/jpeg',
			jpg: 'image/jpeg',
			png: 'image/png',
			pdf: 'application/pdf',
		}

		const extension = file?.name?.split('.').pop()
		if (file) {
			const uploadUrl = await fetch.getSignedUrl(mimeTypes[extension])
			const uploadResult = await fetch.uploadBlobToSignedUrl(uploadUrl, file)
			const s3Link = parseLinkFromSignedUrl(uploadResult?.url)

			const modifiedImages = { ...uploadedImages }
			modifiedImages[imageType] = [...modifiedImages[imageType], s3Link]

			// setIsLoading(false)
			setUploadedImages(modifiedImages)
		}
	}

	const removeImage = (image, imageType) => {
		Swal.fire(imageDeleteConfirmOptions).then(result => {
			if (result.isConfirmed) {
				const modifiedImages = { ...uploadedImages }
				modifiedImages[imageType] = modifiedImages[imageType].filter(img => img !== image)
				setUploadedImages(modifiedImages)

				Swal.fire('Your file has been deleted')
			}
		})
	}

	const triggerPANCardUpload = () => PANCardUploadRef?.current?.click()

	const triggerGSTUpload = () => GSTImageUploadRef?.current?.click()

	const handlePartyDataChange = (index, fieldName, value) => {
		const newHypothecationParties = [...hypothecationParties]
		newHypothecationParties[index][fieldName] = value
		setHypothecationParties(newHypothecationParties)
	}

	const handlePINChange = value => {
		value = value.substring(0, 6)
		setPIN(value)
	}

	const handleCityChange = value => {
		// accept alphabets, space and hyphen
		value = value.replace(/[^a-zA-Z- ]/gi, '')
		setCity(value)
	}

	const personalInfoFieldErrors = {
		salutationErr: !salutation,
		fullNameErr: !fullName,
		designationErr: !designation,
		entityNameErr: !entityName,
	}

	const contactInfoFieldErrors = {
		mobileNoErr: !(mobileNo && mobileNo.length === 10),
		landlineNoErr: !!landlineNo && landlineNo.length !== 10,
		emailErr: !(email && validateEmail(email)),
	}

	const addressInfoFieldErrors = {
		addressFirstLineErr: !addressFirstLine,
		PINErr: !(PIN && String(PIN).match(/^[1-9]{1}\d{5}$/)),
		cityErr: !city,
		stateErr: !stateName,
	}

	// TODO: add validation for PAN and aadhaar
	const isValidPANNum = PANNo.length === 10 && Boolean(PANNo.match(/^[A-Z]{5}\d{4}[A-Z]{1}$/))
	const isValidGSTNum = GSTNo.length === 15

	const legalInfoFieldErrors = {
		PANNoErr: isPANNumMandatory ? !(PANNo && isValidPANNum) : PANNo ? !isValidPANNum : false,
		GSTNoErr: isGSTNumMandatory ? !(GSTNo && isValidGSTNum) : GSTNo ? !isValidGSTNum : false,
		aadharNoErr: aadharNo ? aadharNo.length !== 12 : false,
		PANImageErr: isPANCertMandatory ? !uploadedImages?.PANCard?.length : false,
		GSTImageErr: isGSTCertMandatory ? !uploadedImages?.GST?.length : false,
	}

	const handleLegalInfoChange = (fieldName, value) => {
		if (fieldName === 'PANNo') {
			value = value.substring(0, 10)
			setPANNo(value)
		} else if (fieldName === 'GSTNo') {
			value = value.substring(0, 15)
			setGSTNo(value)
		} else if (fieldName === 'aadharNo') {
			value = value.substring(0, 12)
			setAadharNo(value)
		}
	}

	const handleContactNumberChange = (fieldName, value) => {
		if (fieldName === 'mobileNo') {
			value = String(value).substring(0, 10)
			setMobileNo(value)
		} else if (fieldName === 'landlineNo') {
			value = String(value).substring(0, 10)
			setLandlineNo(value)
		}
	}

	const checkIfProceedDisabled = () => {
		// personal info check
		const validPersonalInfo = salutation && fullName

		// contact info check
		const { mobileNoErr, landlineNoErr, emailErr } = contactInfoFieldErrors
		const validContactInfo = !mobileNoErr && !landlineNoErr && !emailErr

		// address info check
		const validAddressInfo =
			Boolean(addressFirstLine) &&
			!addressInfoFieldErrors.PINErr &&
			!addressInfoFieldErrors.cityErr &&
			!addressInfoFieldErrors.stateErr

		// legal info check
		const { PANNoErr, GSTNoErr, aadharNoErr, PANImageErr, GSTImageErr } = legalInfoFieldErrors
		const validLegalInfo = !PANNoErr && !GSTNoErr && !aadharNoErr && !PANImageErr && !GSTImageErr

		return !validPersonalInfo || !validContactInfo || !validAddressInfo || !validLegalInfo
	}

	const proceed = async () => {
		const addressFields = {
			Line1: addressFirstLine,
			Line2: addressSecondLine,
			PIN: PIN,
			City: city,
			State: stateName,
		}

		const verificationInfoFields = {
			Images: {
				PAN: uploadedImages?.PANCard?.[0],
				GSTIN: uploadedImages?.GST?.[0],
			},
			Passwords: {
				PAN: PANPassword,
				GSTIN: GSTPassword,
			},
		}
		if (PANNo) {
			verificationInfoFields.PAN = PANNo
		}
		if (GSTNo) {
			verificationInfoFields.GSTIN = GSTNo
		}

		const contactInfoFields = {
			Phone: `+91${mobileNo}`,
			Landline: landlineNo ? `+91${landlineNo}` : '',
			Email: email,
			Designation: designation,
		}

		let updatedFullName = ''
		if (salutation && fullName) {
			updatedFullName = `${salutation} ${fullName}`
		}
		if (aadharNo) {
			verificationInfoFields.Aadhar = aadharNo
		}

		const dataToSend = {
			account: {
				Address: addressFields,
				Verification: verificationInfoFields,
			},
			contact: contactInfoFields,
		}

		await fetch.updateClientDetails(state.currentRfqItem.id, dataToSend)

		// update the Context data here...
		const updatedContactInfo = {
			Phone: `+91${mobileNo}`,
			Landline: `+91${landlineNo}`,
			Email: email,
		}

		const updatedPersonalInfo = {
			Designation: designation,
			Name: updatedFullName,
		}

		const updatedCustomerInfo = {
			...customerData,
			Address: addressFields,
			ContactInfo: updatedContactInfo,
			PersonalInfo: updatedPersonalInfo,
			Verification: verificationInfoFields,
		}

		await new Promise(resolve => {
			dispatch({
				type: 'SET_CUSTOMER_INFO',
				data: updatedCustomerInfo,
			})
			resolve()
		})

		await fetch.purchaseJourneyPing(currentRfqId, 'CLIENT_DETAILS')
		history.push('/purchase-policy/self-declaration')
	}

	const isProceedDisabled = checkIfProceedDisabled()

	let proposerNameFields = (
		<CustomInput
			type="text"
			placeholder="Full Name"
			value={fullName}
			onChange={e => setFullName(e.target.value)}
			error={!!fullName && personalInfoFieldErrors.fullNameErr}
		/>
	)

	const prefilledMessage = (
		<Text type="body2" color={theme.colors.green}>
			Pre filled from PIN code
		</Text>
	)

	let displayStatePrefilledMessage = null
	if (statePrefilledByAPI) {
		displayStatePrefilledMessage = prefilledMessage
	}

	let displayCityPrefilledMessage = null
	if (cityPrefilledByAPI) {
		displayCityPrefilledMessage = prefilledMessage
	}

	return (
		<MainContainer
			header="Please enter your details below to close the quote"
			hasLeftBtn={true}
			rightOnClick={proceed}
			rightDisabled={isProceedDisabled}
		>
			<Text color={theme.colors.gray7} fontSize="1.5rem" mobileFontSize="0.875rem" fontWeight="700">
				Personal information
			</Text>
			<Space y={1} />
			<InputLabel>
				Name of the Proposer
				<CompulsoryStar />
			</InputLabel>
			<Space y={0.5} />
			<FlexInputGroup>
				<SalutationSelect
					placeholder="--Select--"
					value={salutation}
					onChange={setSalutation}
					state={personalInfoFieldErrors.salutationErr ? 'error' : 'default'}
				>
					{salutationsList.map(salutation => (
						<option key={salutation}>{salutation}</option>
					))}
				</SalutationSelect>
				{proposerNameFields}
			</FlexInputGroup>

			<GridInputGroup className="mt-3">
				<CustomInput
					className="w-100"
					type="text"
					placeholder="Enter Designation"
					label={
						<>
							Designation of the Proposer
							<CompulsoryStar />
						</>
					}
					value={designation}
					onChange={e => setDesignation(e.target.value)}
					error={!!designation && personalInfoFieldErrors.designationErr}
				/>
				<EditField
					showEditButton={entityEditDisabled}
					onEditClick={() => setEntityEditDisabled(false)}
				>
					<CustomInput
						className="w-100"
						type="text"
						placeholder="Enter Legal Entity"
						label={
							<>
								Legal Entity Name
								<CompulsoryStar />
							</>
						}
						value={entityName}
						onChange={e => setEntityName(e.target.value)}
						error={!!entityName && personalInfoFieldErrors.entityNameErr}
						disabled={entityEditDisabled}
					/>
				</EditField>
			</GridInputGroup>

			<Divider top={1} bottom={1} />

			<Text color={theme.colors.gray7} fontSize="1.5rem" mobileFontSize="0.875rem" fontWeight="700">
				Contact Information
			</Text>
			<GridInputGroup className="mt-3">
				<CustomInput
					className="w-100"
					type="number"
					placeholder="+91"
					label={
						<>
							Mobile Number
							<CompulsoryStar />
						</>
					}
					value={mobileNo}
					onChange={e => handleContactNumberChange('mobileNo', e.target.value)}
					error={!!mobileNo && contactInfoFieldErrors.mobileNoErr}
					disabled={true}
				/>
				<CustomInput
					className="w-100"
					type="number"
					placeholder="+91"
					label="Landline Number"
					value={landlineNo}
					onChange={e => handleContactNumberChange('landlineNo', e.target.value)}
					error={!!landlineNo && contactInfoFieldErrors.landlineNoErr}
				/>
				<EditField
					showEditButton={emailEditDisabled}
					onEditClick={() => setEmailEditDisabled(false)}
				>
					<CustomInput
						type="email"
						placeholder="eg: abc@xyz.com"
						label={
							<>
								Email Address
								<CompulsoryStar />
							</>
						}
						value={email}
						onChange={e => setEmail(e.target.value)}
						error={!!email && contactInfoFieldErrors.emailErr}
						errorFieldName="Email address"
						disabled={emailEditDisabled}
					/>
				</EditField>
			</GridInputGroup>

			<Text
				color={theme.colors.gray7}
				fontSize="1.5rem"
				mobileFontSize="0.875rem"
				fontWeight="700"
				className="mt-3"
			>
				Address
			</Text>
			<Space y={1} />
			<InputLabel>
				Registered Address of Legal Entity
				<CompulsoryStar />
			</InputLabel>
			<Space y={0.5} />
			<FlexInputGroup>
				<CustomInput
					className="w-100"
					type="text"
					placeholder="Address First Line"
					value={addressFirstLine}
					onChange={e => setAddressFirstLine(e.target.value)}
					error={!!addressFirstLine && addressInfoFieldErrors.addressFirstLineErr}
				/>
				<CustomInput
					className="w-100"
					type="text"
					placeholder="Address Second Line"
					value={addressSecondLine}
					onChange={e => setAddressSecondLine(e.target.value)}
				/>
			</FlexInputGroup>
			<GridInputGroup className="mt-3">
				<CustomInput
					type="number"
					placeholder="Enter Pincode Here"
					label={
						<>
							PIN Code
							<CompulsoryStar />
						</>
					}
					value={PIN}
					onChange={e => handlePINChange(e.target.value)}
					error={!!PIN && addressInfoFieldErrors.PINErr}
					errorFieldName="PIN Code"
					onBlur={fetchCityAndState}
				/>
			</GridInputGroup>
			<GridInputGroup className="mt-3">
				<div className="w-100">
					<EditField
						showEditButton={cityPrefilledByAPI}
						onEditClick={() => setCityPrefilledByAPI(false)}
					>
						<CustomInput
							type="text"
							placeholder="Your Residential City"
							label={
								<>
									City
									<CompulsoryStar />
								</>
							}
							value={city}
							onChange={e => handleCityChange(e.target.value)}
							disabled={cityPrefilledByAPI}
							error={!!city && addressInfoFieldErrors.cityErr}
						/>
					</EditField>
					{displayCityPrefilledMessage}
				</div>
				<SearchableSelectWrapper>
					<InputLabel>
						State <CompulsoryStar />
					</InputLabel>
					<EditField
						showEditButton={statePrefilledByAPI}
						onEditClick={() => setStatePrefilledByAPI(false)}
						editBtnStyle={{ top: '0.25rem' }}
					>
						<SearchableSelect
							search
							value={stateName}
							onChange={setStateName}
							options={stateOps}
							placeholder="Select State"
							disabled={statePrefilledByAPI}
							error={!!stateName && addressInfoFieldErrors.stateErr}
						>
							<option>Select State</option>
						</SearchableSelect>
					</EditField>
					{displayStatePrefilledMessage}
				</SearchableSelectWrapper>
			</GridInputGroup>

			<Divider top={1} bottom={1} />

			<Text color={theme.colors.gray7} fontSize="1.5rem" mobileFontSize="0.875rem" fontWeight="700">
				Legal Information
				{allLegalFieldsMandatory ? <CompulsoryStar /> : null}
			</Text>
			<GridInputGroup className="mt-3">
				<CustomInput
					className="w-100"
					type="text"
					placeholder="eg: AAA1929B91IS"
					label={
						<>
							Enter your PAN card number
							{isPANNumMandatory ? <CompulsoryStar /> : null}
						</>
					}
					value={PANNo}
					onChange={e => handleLegalInfoChange('PANNo', e.target.value)}
					error={!!PANNo && legalInfoFieldErrors.PANNoErr}
					errorFieldName="PAN card number"
					isAPIField={true}
				/>
				<div className="w-100">
					<InputLabel>
						Upload your PAN card front (image/pdf) here
						{isPANCertMandatory ? <CompulsoryStar /> : null}
					</InputLabel>
					<HiddenFileInput
						accept="image/png,image/jpeg,application/pdf"
						ref={PANCardUploadRef}
						onChange={e => handleFileUpload(e.target.files, 'PANCard')}
					/>
					<div className="d-flex flex-column">
						<div className="d-flex align-items-center">
							{uploadedImages?.PANCard?.map((uploadedItem, index) => {
								const thumbnail =
									uploadedItem?.split('.')?.pop() === 'pdf' ? UploadedDoc : UploadedImage
								return (
									<FileUploaded
										imageUrl={uploadedItem}
										key={`PANCard-image-${index}`}
										onDelete={() => removeImage(uploadedItem, 'PANCard')}
										style={{ height: '48px' }}
										thumbnail={thumbnail}
									/>
								)
							})}
							<CustomUploadButton
								onClick={triggerPANCardUpload}
								disabled={uploadedImages?.PANCard?.length > 0}
								applyDisableFilter={uploadedImages?.PANCard?.length > 0}
							/>
						</div>
						<Space y={1.5} />
						<CustomInput
							value={PANPassword}
							onChange={e => setPANPassword(e.target.value)}
							label="Enter password, if the uploaded file is password protected"
							placeholder="Enter here"
						/>
					</div>
				</div>
				<CustomInput
					className="w-100"
					type="text"
					placeholder="eg: 38208HYUBA1212"
					label={
						<>
							Enter GST number
							{isGSTNumMandatory ? <CompulsoryStar /> : null}
						</>
					}
					value={GSTNo}
					onChange={e => handleLegalInfoChange('GSTNo', e.target.value)}
					error={!!GSTNo && legalInfoFieldErrors.GSTNoErr}
					errorFieldName="GST number"
					isAPIField={true}
				/>
				<div className="w-100">
					<InputLabel>
						Upload your GST certificate (image/pdf) here
						{isGSTCertMandatory ? <CompulsoryStar /> : null}
					</InputLabel>
					<HiddenFileInput
						accept="image/png,image/jpeg,application/pdf"
						ref={GSTImageUploadRef}
						onChange={e => handleFileUpload(e.target.files, 'GST')}
					/>
					<div className="d-flex flex-column">
						<div className="d-flex align-items-center">
							{uploadedImages?.GST?.map((image, index) => (
								<FileUploaded
									imageUrl={image}
									key={`GST-image-${index}`}
									onDelete={() => removeImage(image, 'GST')}
									style={{ height: '48px' }}
									thumbnail={UploadedDoc}
								/>
							))}
							<CustomUploadButton
								onClick={triggerGSTUpload}
								disabled={uploadedImages?.GST?.length > 0}
								applyDisableFilter={uploadedImages?.GST?.length > 0}
							/>
						</div>
						<Space y={1.5} />
						<CustomInput
							value={GSTPassword}
							onChange={e => setGSTPassword(e.target.value)}
							label="Enter password, if the uploaded file is password protected"
							placeholder="Enter here"
						/>
					</div>
				</div>
				<CustomInput
					placeholder="eg: 393082109010"
					label="Please enter your Aadhar number"
					value={aadharNo}
					onChange={e => handleLegalInfoChange('aadharNo', e.target.value)}
					error={!!aadharNo && legalInfoFieldErrors.aadharNoErr}
					errorFieldName="Aadhar card number"
					isAPIField={true}
				/>
			</GridInputGroup>

			{hypothecationParties?.length > 0 ? (
				<>
					<Divider top={1} bottom={1} />
					<Text
						color={theme.colors.gray7}
						fontSize="1.5rem"
						mobileFontSize="0.875rem"
						fontWeight="700"
					>
						List of parties that have an insurable interest:
					</Text>
					{hypothecationParties.map((party, index) => (
						<div
							key={`hypothecation-party-${index}`}
							className="my-3 w-100 d-flex justify-content-between align-items-stretch"
						>
							<GridInputGroup>
								<CustomInput
									className="w-100"
									type="text"
									placeholder="Party name"
									value={party?.Name}
									disabled={true}
									onChange={e => handlePartyDataChange(index, 'Name', e.target.value)}
								/>
								<CustomInput
									className="w-100"
									type="text"
									placeholder="Financial Institution / Co-owner / Other"
									value={party?.Type}
									disabled={true}
									onChange={e => handlePartyDataChange(index, 'Type', e.target.value)}
								/>
							</GridInputGroup>
						</div>
					))}
				</>
			) : (
				<Space y={2} />
			)}
		</MainContainer>
	)
}

export default function CustomerInfo() {
	const [state] = useContextApi()
	const { showHelpModal } = useHelpModal()
	const currentRfqItem = state?.currentRfqItem
	const policyName = getPolicyName(currentRfqItem?.Tag)

	const selectedQuote = state?.currentRfqItem?.selectedQuote || {}
	const rfqData = state?.currentRfqItem || {}
	const customerData = state?.customerInfo || {}

	let totalPremiumInWords,
		coverAmountInWords,
		coverDuration = '-'
	const totalPremium = calculateTotalPremium(selectedQuote)
	if (totalPremium) {
		totalPremiumInWords = getFormattedAmount(totalPremium)
	}
	if (rfqData?.cover) {
		coverAmountInWords = convertAmountToWords(`₹ ${rfqData?.cover}`)
	}
	if (rfqData?.duration) {
		const durationValue = rfqData?.duration
		if (durationValue === 'M12') {
			coverDuration = '1 Year'
		} else {
			const durationInMonths = coverageValues?.find(coverItem => coverItem.value === durationValue)
			coverDuration = durationInMonths?.name || '-'
		}
	}

	useEffect(() => {
		const chatIcon = document?.getElementById('ymDivBar') || null
		const width = window.innerWidth
		if (chatIcon && width < 768) {
			chatIcon.style.bottom = '60px'
		}

		return () => {
			if (chatIcon) {
				chatIcon.style.bottom = '20px'
			}
		}
	}, [])

	const selectedPolicyData = (
		<>
			<img
				src={InsurerImagesMap?.[selectedQuote?.Insurer] || ''}
				style={{ width: 'min(200px, 100%)', height: 100 }}
				alt="selected insurer"
				className="mb-2"
			/>
			<div className="mb-3" style={{ width: 'min(200px, 100%)' }}>
				<div className="d-flex justify-content-between w-100">
					<Text type="body2" color={theme.colors.gray2}>
						Premium
					</Text>
					<Text type="body2" color={theme.colors.primary} fontWeight="700">
						{totalPremiumInWords}
					</Text>
				</div>
				<div className="d-flex justify-content-between w-100">
					<Text type="body2" color={theme.colors.gray2}>
						Cover
					</Text>
					<Text type="body2" color={theme.colors.primary} fontWeight="700">
						{coverAmountInWords}
					</Text>
				</div>
				<div className="d-flex justify-content-between w-100">
					<Text type="body2" color={theme.colors.gray2}>
						Period
					</Text>
					<Text type="body2" color={theme.colors.primary} fontWeight="700">
						{coverDuration}
					</Text>
				</div>
			</div>
		</>
	)

	const displaySelectedPolicyMobile = (
		<SelectedPolicyContainerMobile>
			<Text type="body2" fontWeight="700" color={theme.colors.gray2}>
				SELECTED POLICY
			</Text>
			<Divider top="1" bottom="1" />
			{selectedPolicyData}
		</SelectedPolicyContainerMobile>
	)

	const navContent = (
		<Text type="body1" color={theme.colors.gray7} fontWeight="700">
			<div className="d-flex align-items-center">
				{policyName}({currentRfqItem?.policyIndex}) &nbsp;
				<PolicyTypeLabel>{currentRfqItem?.policyName || ''}</PolicyTypeLabel>
				<Icon name="rightArrow" color={theme.colors.primary} /> Client details
			</div>
		</Text>
	)

	const mobileLeftNavContent = (
		<div className="d-flex w-100 justify-content-center">
			<Text
				style={{ marginLeft: '2rem' }}
				color={theme.colors.primary}
				fontSize="18px"
				fontWeight="700"
			>
				Client Details
			</Text>
		</div>
	)

	const rightNavContent = (
		<Button
			icon="helpAltIcon"
			iconColor={theme.colors.primary}
			iconSize="18px"
			iconStyles={{ marginRight: '.3rem' }}
			bgColor="white"
			textColor={theme.colors.primary}
			label="Help"
			onClick={() => showHelpModal(true)}
		/>
	)

	return (
		<PageContentAndNavbarWrapper style={{ height: '100%' }}>
			<OnDesktop>
				<SecondaryNavbar
					leftContent={navContent}
					navbarNumber={1}
					rightContent={rightNavContent}
					showGoBack={false}
					headerBg={theme.colors.secondary2}
				/>
			</OnDesktop>
			<OnMobile>
				<SecondaryNavbar
					leftContent={mobileLeftNavContent}
					navbarNumber={1}
					rightContent={rightNavContent}
					showGoBack={true}
					headerBg={theme.colors.white}
				/>
			</OnMobile>
			{displaySelectedPolicyMobile}
			<CustomerInfoForm customerData={customerData} />
		</PageContentAndNavbarWrapper>
	)
}

const CompulsoryStar = () => <span style={{ color: '#eb5757' }}>*</span>

const FlexInputGroup = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: start;
	width: 100%;
	gap: 1rem;
	@media (max-width: 768px) {
		flex-direction: column;
	}
`

const GridInputGroup = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 1rem;
	width: 100%;
	@media (max-width: 768px) {
		grid-template-columns: 1fr;
	}
`

const CustomInput = ({ ...props }) => {
	let errorLabel, successLabel
	if (!!props?.value && props.error && !!props?.errorFieldName) {
		errorLabel = props?.errorString ?? `Please enter a valid ${props?.errorFieldName}`
	} else if (!!props?.value && !props.error && !!props?.errorFieldName) {
		successLabel = !props.isAPIField ? 'Successfully entered' : '' // TODO: show 'Verified' for API verified fields when API verification (for GST and PAN) is available
	}

	return (
		<div className="w-100 d-flex flex-column justify-content-start">
			<CustomInputWrapper errorLabel={errorLabel} successLabel={successLabel} {...props} />
		</div>
	)
}

const CustomInputWrapper = styled(Input)`
	width: 100%;
	flex: 1;
`

const CustomSelect = styled(Select)`
	min-height: 48px;
`
const SalutationSelect = styled(CustomSelect)`
	width: 140px;
	@media (max-width: 768px) {
		width: 100%;
	}
`
const InputLabel = styled(Text)`
	font-size: 14px;
	line-height: 18px;
	letter-spacing: 0.03em;
	color: ${theme.colors.gray2};
	font-weight: 500;
	margin-bottom: 0.5rem;
`
const SearchableSelectWrapper = styled.div`
	.select-search {
		width: 100%;
	}
`

const SelectedPolicyContainerMobile = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 1rem 0;
	padding: 1rem;
	background: #fff;
	border-radius: 0.5rem;
	@media (min-width: 768px) {
		display: none;
	}
`
