import styled from 'styled-components'

import { generateTag } from '../utilities/generateTag'

import { Text, theme, Button, Icon } from 'verak-ui'

export const MainWrapper = styled.div`
	margin: 2.25rem 4.5rem;
	padding: 1rem;
	background: #ffffff;
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
	border-radius: 8px;
	text-align: center;
	width: 90%;
	@media (max-width: 768px) {
		width: 100%;
		height: 100%;
		margin: 0rem;
		padding: 1rem;
		box-shadow: none;
	}
`

export const OnDesktop = styled.div`
	width: 100%;
	@media (max-width: 768px) {
		display: none;
		width: 0;
	}
`

export const OnMobile = styled.div`
	width: 100%;
	@media (min-width: 768px) {
		display: none;
	}
`

export const StyledImg = styled.img`
	@media (max-width: 768px) {
		width: 142px;
		height: 142px;
	}
`
export const StyledPrimaryBtn = styled(Button)`
	width: 240px;
	height: 48px;
	margin: 0 auto;
	@media (max-width: 768px) {
		width: 200px;
		height: 44px;
		font-size: 14px;
		letter-spacing: -0.02em;
		margin-bottom: 1rem;
	}
`
export const StyledText = styled(Text)`
	font-size: 24px !important;
`

// navbar for statuses
export const navContent = (rfqItem, status = 'Awaiting Quotes') => (
	<div className="flex-container" style={{ width: '100%' }}>
		<Text color={theme.colors.gray7} style={{ ...navTextStyle, opacity: 0.6 }}>
			{generateTag(rfqItem?.Tag)} &nbsp;
		</Text>
		<PolicyTypeLabel>{rfqItem?.policyName}</PolicyTypeLabel>
		<Icon name="rightArrow" color={theme.colors.gray7} />
		<Text color={theme.colors.gray7} style={navTextStyle}>
			&nbsp; {status}
		</Text>
	</div>
)
const navTextStyle = {
	fontSize: '18px',
	letterSpacing: '-0.03em',
	fontWeight: 700,
}
export const mobileLeftNav1 = text => (
	<div className="d-flex justify-content-center w-100">
		<Text color={theme.colors.primary} fontSize="18px" fontWeight="700">
			{text}
		</Text>
	</div>
)

export const PolicyTypeLabel = ({ children }) => {
	return (
		<Text
			fontWeight="700"
			color="#fff"
			style={{ padding: '6px 12px', background: theme.colors.secondary, borderRadius: '17px' }}
		>
			{children}
		</Text>
	)
}
