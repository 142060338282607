import {
	PageContentAndNavbarWrapper,
	SecondaryNavbar,
	theme,
	Text,
	Button,
	Icon,
	Switch,
	Divider,
	Space,
} from 'verak-ui'
import styled from 'styled-components'
import { MainWrapper, PolicyTypeLabel } from '../../components/SharedComponents'
import { OnDesktop, OnMobile } from '../../components/HideViewports'
import { useContextApi } from '../../context/Provider'
import { getPolicyName } from '../../utilities/getPolicyName'
import { useHistory } from 'react-router'
import { useState } from 'react'
import { useFetch } from '../../api/apihooks'
import SignatureIcon from '../../assets/images/signatureIcon.svg'
import OTPInput from '../../components/OTPInput'
import { useHelpModal } from '../../context/HelpModalProvider'
import { getRMPhoneNumber } from '../../utilities/getRMPhoneNumber'

const capitalize = string => string.charAt(0).toUpperCase() + string.slice(1)

const getFormattedPhone = phone => {
	if (!phone.startsWith('+91')) {
		return `+91 - ${phone}`
	} else {
		return phone.replace('+91', '+91 - ')
	}
}

const parseTime = seconds => {
	let minutes = Math.floor(seconds / 60)
	let secondsLeft = seconds % 60
	secondsLeft = secondsLeft < 10 ? `0${secondsLeft}` : secondsLeft
	return `${minutes}:${secondsLeft}`
}

const getDeclarationText = (policyDetails, isIndividual, pronouns) => {
	if (policyDetails.Tag === 'FIRE' || policyDetails.Tag === 'FIRE_ALL_RISK') {
		let policyTypeDescription
		if (policyDetails?.PolicyType === 'BSUS') {
			policyTypeDescription = 'less than ₹5 Crore'
		} else if (policyDetails?.PolicyType === 'BLUS') {
			policyTypeDescription = 'more than ₹5 Crore but less than ₹50 Crore'
		} else {
			policyTypeDescription = 'more than ₹50 Crore'
		}

		let selfDeclarationText1 = `
    ${capitalize(
			pronouns.I_WE
		)} hereby declare that the value of insurable assets is ${policyTypeDescription} and the statements made by ${
			pronouns.ME_US
		} in this Proposal Form ${pronouns.IS_ARE} true to the best of ${
			pronouns.MY_OUR
		} knowledge and belief and ${
			pronouns.I_WE
		} hereby agree that this declaration shall form the basis of the contract between ${
			pronouns.ME_US
		} and the ${policyDetails?.Insurer}
    `
		let selfDeclarationText2 = `If any additions or alterations are carried out in the risk proposed after the submission of this proposal form then the same should be conveyed to the insurers immediately.`

		return (
			<>
				<Text color={theme.colors.gray7} fontSize="18px" className="my-1">
					{selfDeclarationText1}
				</Text>
				<Text color={theme.colors.gray7} fontSize="18px" className="my-1">
					{selfDeclarationText2}
				</Text>
				<div className="d-flex w-100">
					<TermsText color={theme.colors.gray7} fontSize="20px" fontWeight="400">
						General Terms & Conditions can be found{' '}
						<CustomLink
							target="_blank"
							href="https://www.verak.in/fire-insurance-tcs/"
							rel="noopener noreferrer"
						>
							here.
						</CustomLink>
					</TermsText>
				</div>
			</>
		)
	}
}

const defaultOtpValues = ['', '', '', '', '', '']

export default function SelfDeclarationFlow() {
	const [state] = useContextApi()
	const { showHelpModal } = useHelpModal()

	const currentRfqItem = state?.currentRfqItem
	const currentUser = state?.user
	const customerInfo = state?.customerInfo || {}

	const { triggerSelfDeclarationOTP, confirmSelfDeclarationOTP, purchaseJourneyPing } = useFetch()
	const history = useHistory()
	const policyName = getPolicyName(currentRfqItem?.Tag)

	// Screen names
	// declaration-screen
	// otp-screen
	const [activeScreen, setActiveScreen] = useState('declaration-screen')
	const [acceptDeclaration, setAcceptDeclaration] = useState(false)

	const [timeLeft, setTimeLeft] = useState(120)
	const [error, setError] = useState('')
	const [intervalHandler, setIntervalHandler] = useState(null)
	// const [otp, setOtp] = useState('')

	const [otpValues, setOtpValues] = useState(defaultOtpValues)

	const handleOtpValueChangeAtIndex = (index, value) => {
		let newValues = [...otpValues]
		newValues[index] = value
		setOtpValues(newValues)
	}

	const handleAllOtpValuesChange = values => {
		setOtpValues(values)
	}

	const otp = otpValues?.join('')?.trim()

	const isIndividual = customerInfo?.Type === 'INDIVIDUAL'
	const policyDetails = {
		// TODO: get from state
		PolicyType: currentRfqItem?.policyName,
		Insurer: currentRfqItem?.selectedQuote?.Insurer,
		Tag: currentRfqItem?.Tag,
	}

	const userPhoneNumber = getFormattedPhone(state?.user?.data?.phone)

	const pronouns = {
		I_WE: isIndividual ? 'I' : 'we',
		ME_US: isIndividual ? 'me' : 'us',
		IS_ARE: isIndividual ? 'is' : 'are',
		MY_OUR: isIndividual ? 'my' : 'our',
	}

	const declarationText = getDeclarationText(policyDetails, isIndividual, pronouns)

	const navContent = (
		<Text type="body1" color={theme.colors.gray7} fontWeight="700">
			<div className="d-flex align-items-center">
				{policyName}({currentRfqItem?.policyIndex}) &nbsp;
				<PolicyTypeLabel>{currentRfqItem?.policyName}</PolicyTypeLabel>
				<Icon name="rightArrow" color={theme.colors.primary} /> Self Declaration
			</div>
		</Text>
	)

	const rightNavContent = (
		<Button
			icon="helpAltIcon"
			iconColor={theme.colors.primary}
			iconSize="18px"
			iconStyles={{ marginRight: '.3rem' }}
			bgColor="white"
			textColor={theme.colors.primary}
			label="Help"
			onClick={() => showHelpModal(true)}
		/>
	)

	const disableResend = timeLeft !== 0

	const resendOTP = async () => {
		// resend otp only after time (2 min) is finished
		if (timeLeft === 0) {
			setError(false)
			await new Promise((resolve, reject) => {
				setOtpValues(defaultOtpValues)
				setTimeLeft(120) // reset time to resend OTP
				clearInterval(intervalHandler)
				setIntervalHandler(null)
				resolve()
			})
			sendOtp()
		}
	}

	const sendOtp = async () => {
		const result = await triggerSelfDeclarationOTP(currentUser?.data?.contactId, currentRfqItem?.id)
		if (result.error) {
			setError(result.error)
		} else {
			const handler = setInterval(() => {
				setTimeLeft(value => {
					return value ? value - 1 : 0
				})
			}, 1000)
			setIntervalHandler(handler)
			setActiveScreen('otp-screen')
		}
	}

	const verifyOtp = async () => {
		const result = await confirmSelfDeclarationOTP(currentUser?.data?.contactId, otp)
		if (result.error) {
			setError(result.error)
		} else {
			await purchaseJourneyPing(currentRfqItem.id, 'SELF_DEC')
			history.push('/purchase-policy/proceed-to-payment')
		}
	}

	const goBack = () => {
		if (activeScreen === 'otp-screen') {
			setActiveScreen('declaration-screen')
		} else {
			history.goBack()
		}
	}

	let activeSection
	if (activeScreen === 'declaration-screen') {
		activeSection = (
			<CustomWrapper className="p-4 d-flex flex-column align-items-start">
				<OnDesktop>
					<Text color={theme.colors.primary} fontSize="24px" fontWeight="700">
						Please read the self declaration carefully
					</Text>
				</OnDesktop>
				<NoticeSection className="my-3 d-flex flex-column align-items-start">
					<img height="48" width="48" src={SignatureIcon} alt="declaration" />
					{declarationText}
				</NoticeSection>
				<SignDeclarationSection className="w-100 p-4 d-flex align-items-center justify-content-between">
					<TNCText color={theme.colors.gray7} fontSize="18px" fontWeight="700">
						{capitalize(pronouns.I_WE)} have read the Terms & Conditions as provided above and
						accept the same
					</TNCText>
					<Switch value={acceptDeclaration} onChange={val => setAcceptDeclaration(val)} />
				</SignDeclarationSection>
				<OnMobile className="w-100">
					<TNCText
						className="text-left mt-4"
						color={theme.colors.primary}
						fontSize="14px"
						fontWeight="700"
					>
						An OTP will be sent to{' '}
						<span style={{ color: theme.colors.secondary }}>{userPhoneNumber}</span> to complete the
						self-declaration
					</TNCText>
				</OnMobile>
				<Divider className="my-4" />
				<div className="w-100 d-flex justify-content-between">
					<Button
						onClick={goBack}
						icon="leftArrow"
						bgColor={theme.colors.secondary2}
						textColor={theme.colors.primary}
						style={{ height: '40px' }}
						label="Back"
					/>
					<div className="d-flex align-items-center">
						<OnDesktop>
							<Text color={theme.colors.primary} fontSize="18px" fontWeight="700" className="mx-3">
								An OTP will be sent to {userPhoneNumber} to complete the self-declaration
							</Text>
						</OnDesktop>
						<Button
							onClick={sendOtp}
							icon="rightArrow"
							iconAlign="right"
							disabled={!acceptDeclaration}
							style={{ height: '40px' }}
							label="Continue"
						/>
					</div>
				</div>
			</CustomWrapper>
		)
	} else if (activeScreen === 'otp-screen') {
		let otpHeaderText
		if (error && error === 'invalid otp') {
			otpHeaderText = `Incorrect OTP! Enter OTP sent to (${userPhoneNumber}) to complete the Self Declaration`
		} else {
			otpHeaderText = `Enter OTP sent to (${userPhoneNumber}) to complete the Self Declaration`
		}

		activeSection = (
			<>
				<CustomWrapper className="p-4 pb-5 d-flex flex-column align-items-center">
					<Text
						className="mt-5 mb-4"
						color={error ? theme.colors.red : theme.colors.primary}
						fontSize="24px"
						fontWeight="700"
					>
						{otpHeaderText}
					</Text>
					<Icon
						color={error ? theme.colors.red : theme.colors.secondary4}
						name="chat"
						size="100px"
					/>
					<Space y={2} />
					<OTPSection className="d-flex flex-column">
						<OTPInput
							onValueChangeAtIndex={handleOtpValueChangeAtIndex}
							onAllValuesChange={handleAllOtpValuesChange} // in case of paste event
							values={otpValues}
							inputStyle={{ width: '2.75rem', margin: '0 0.2rem', textAlign: 'center' }}
						/>
						<Space y={1.5} />
						<div className="w-100 d-flex justify-content-between">
							<Text
								style={{ cursor: disableResend ? 'not-allowed' : 'pointer' }}
								disabled={disableResend}
								color={disableResend ? theme.colors.gray3 : theme.colors.primary}
								fontSize="14px"
								fontWeight="bold"
								onClick={resendOTP}
							>
								Resend code
							</Text>
							<Text color={theme.colors.gray3} fontSize="14px" fontWeight="700">
								{parseTime(timeLeft)}
							</Text>
						</div>
						<Space y={1.5} />
						<OnDesktop>
							<Button
								onClick={verifyOtp}
								style={{ height: '48px' }}
								className="w-100"
								disabled={otp?.length !== 6}
								label="Verify OTP & Continue"
							/>
						</OnDesktop>

						<OnMobile className="mt-4">
							<Divider className="my-4" />
							<div className="w-100 d-flex justify-content-between">
								<Button
									onClick={goBack}
									icon="leftArrow"
									bgColor={theme.colors.secondary2}
									textColor={theme.colors.primary}
									style={{ height: '40px' }}
									label="Back"
								/>
								<Button
									onClick={verifyOtp}
									style={{ height: '40px', marginLeft: '1rem' }}
									className="w-100"
									disabled={otp?.length !== 6}
									label="Verify OTP & Continue"
									icon="rightArrow"
									iconAlign="right"
								/>
							</div>
						</OnMobile>
					</OTPSection>
					<Space y={1.5} />
				</CustomWrapper>
			</>
		)
	}

	let mobileNavbar
	let pageHeader = ''
	let secondNavText = ''
	if (activeScreen === 'declaration-screen') {
		pageHeader = 'Self Declaration'
		secondNavText = 'Please read the self declaration carefully'
	} else {
		pageHeader = 'Enter OTP'
		secondNavText = 'Enter OTP to complete self declaration'
	}
	const mobileNav1LeftContent = (
		<div className="w-100">
			<Text
				className="text-center"
				fontSize="18px"
				fontWeight="700"
				style={{ marginLeft: '2rem' }}
				color={theme.colors.primary}
			>
				{pageHeader}
			</Text>
		</div>
	)

	const mobileNav2LeftContent = (
		<div class="w-100">
			<Text fontSize="14px" fontWeight="bold" color={theme.colors.white}>
				{secondNavText}
			</Text>
		</div>
	)
	mobileNavbar = (
		<>
			<SecondaryNavbar
				leftContent={mobileNav1LeftContent}
				navbarNumber={1}
				rightContent={rightNavContent}
				showGoBack={true}
				headerBg={theme.colors.white}
			/>
			<SecondaryNavbar
				leftContent={mobileNav2LeftContent}
				navbarNumber={2}
				showGoBack={false}
				headerBg={theme.colors.primary}
			/>
		</>
	)

	return (
		<PageContentAndNavbarWrapper>
			<OnDesktop>
				<SecondaryNavbar
					leftContent={navContent}
					navbarNumber={1}
					rightContent={rightNavContent}
					showGoBack={false}
					headerBg={theme.colors.secondary2}
				/>
			</OnDesktop>
			<OnMobile>{mobileNavbar}</OnMobile>
			{activeSection}
		</PageContentAndNavbarWrapper>
	)
}

const NoticeSection = styled.div`
	background: rgba(252, 235, 227, 0.5);
	border-radius: 10px;
	padding: 1rem;
	text-align: left;
`

const CustomLink = styled.a`
	font-size: 18px;
	font-weight: 400;
	color: ${theme.colors.secondary};
	@media (max-width: 768px) {
	}
`

const SignDeclarationSection = styled.div`
	border: 1px solid #f3bda1;
	border-radius: 8px;
	@media (max-width: 768px) {
		padding: 10px !important;
	}
`

const TermsText = styled(Text)`
	@media (max-width: 768px) {
		font-size: 18px !important;
	}
`

const OTPSection = styled.div`
	width: 315px;
	@media (max-width: 768px) {
		width: 100%;
	}
`

const TNCText = styled(Text)`
	@media (max-width: 768px) {
		text-align: left;
		font-size: 14px;
		padding-right: 0.5rem;
	}
`

const CustomWrapper = styled(MainWrapper)`
	@media (max-width: 768px) {
		padding: 1.5rem !important;
		width: 100%;
		padding: 1rem;
		margin: 0;
		border-radius: 0;
	}
`
